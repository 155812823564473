.progress-baar-line {
      width: 100%;
}

.user-info-sidebar {
      display: flex;
      justify-content: center;
      align-items: center;
}

.dash-countdown {
      display: flex !important;
      justify-content: center;
      align-items: center;
      max-width: 65px;
      width: 100%;
}

/* meet css for Transactions page */

div.dataTables_wrapper2 div.dataTables_filter2 label:before {
      height: 36px !important;
      width: 30px;
}

.dataTables_filter label {
      padding: 8px 9px 10px !important;
}

.dataTables_filter2 input {
      width: 100% !important;
}

.dataTables_filter2 label {
      border: none !important;
}

div.dataTables_wrapper2 div.dataTables_filter2 input {
      padding: 10px 7px 10px 30px !important;
}

.transaction-title {
      font-size: 24px;
}

.data-table-filter2 a {
      top: 13px !important;
}

.tranx-no,
.tranx-popup,
.tranx-no span {
      font-family: "Poppins", sans-serif;
}

.tranx-token,
.tranx-token span {
      font-family: "Poppins", sans-serif;
}

.tranx-amount,
.tranx-amount span {
      font-family: "Poppins", sans-serif;
}

.tranx-from,
.tranx-from span {
      font-family: "Poppins", sans-serif;
}

.custom-input::placeholder {
      font-family: "Poppins", sans-serif;
}

.modal-close2 {
      background: transparent !important;
}

.modal-content .modal-close2 {
      right: -11px !important;
}

div.dataTables_wrapper2 div.dataTables_paginate2 ul.pagination {
      justify-content: flex-start !important;
}

.dataTables_paginate2 .pagination li a.active,
.pagination li a:hover {
      color: #6783b8 !important;
      background: #cad2f1 !important;
}

.dataTables_paginate2 .page-item.active .page-link {
      color: #6783b8 !important;
      background: #cad2f1 !important;
      border: none;
}

.pagi-num {
      display: flex;
      justify-content: flex-end;
      align-items: center;
}

div.dataTables_wrapper div.dataTables_info {
      padding-top: 20px !important;
}

.page-item:not(:first-child) .page-link {
      margin-left: 2px !important;
}

.pagination li a {
      background: #eff6ff !important;
}

.text-start {
      padding-left: 13px !important;
}

.dataTables_paginate2 ul li {
      font-size: 14px !important;
}

.pagination li a {
      height: 40px !important;
      min-width: 45px !important;
      line-height: 8px !important;
      padding: 15px 12px !important;
      font-family: "Poppins", sans-serif;
      color: #abbbd9 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
      border: none !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
      border: none;
}

.footer-bar {
      font-family: "Poppins", sans-serif;
}

/* meet css for KYC Application page */

.user-panel-title {
      font-size: 24px !important;
}

.user-panel-title2 p {
      margin-top: 13px !important;
      line-height: 25px !important;
      font-family: "Poppins", sans-serif !important;
}

.status-text2 {
      font-family: "Poppins", sans-serif !important;
}

.custom-btn-kyc {
      padding: 12px 30px !important;
      font-size: 14px !important;
      font-family: "Poppins", sans-serif !important;
      background: #2b56f5 !important;
      border-color: #2b56f5 !important;
      box-shadow: 0px 2px 18px 2px rgb(43 86 245 / 25%) !important;
}

.note-plane2 [class*=fa] {
      top: 3px !important;
}

.alert-primary {
      background: #def8ff !important;
      border-color: #bae2ed !important;
}

.alert-txt {
      font-family: "Poppins", sans-serif !important;
}

.resend-btn {
      color: #fff !important;
      background: #1343f4 !important;
      border-color: #1343f4 !important;
      font-family: "Poppins", sans-serif !important;
      padding: 10px 18px !important;
      line-height: 19px !important;
}

.input-bordered {
      cursor: pointer !important;
      border-radius: 4px !important;
      border: 2px solid #d3e0f3 !important;
}

.note-plane [class*=fa] {
      top: 0px !important;
}

.note-plane2 p {
      line-height: 18px !important;
}

.alert-box {
      margin-bottom: 10px !important;
}

.alert-txt2 {
      font-weight: 100 !important;
}

.tab-content .tab-pane {
      font-weight: 100 !important;
}

.notification-list li span {
      color: #6783b8 !important;
}

.tab-pane2 p {
      margin: 0 !important;
}

.notification-list {
      padding-top: 10px !important;
}

.notification-list li {
      font-family: "Poppins", sans-serif !important;
      padding-bottom: 20px !important;
}

.input-switch~label:not(.error):before {
      height: 24px;
      width: 50px;
}

.input-switch~label:not(.error):after {
      height: 18px;
      width: 18px;
}

.input-note,
.swalllet {
      font-family: "Poppins", sans-serif !important;
}

.update .update-btn {
      padding: 12px 15px !important;
      font-size: 14px !important;
}

.input-item-label {
      font-family: "Poppins", sans-serif !important;
}

.input-bordered2 .option-text {
      font-family: "Poppins", sans-serif !important;
      /* font-weight: 100 !important; */
      padding: 10px !important;
}

.tab-pane {
      padding-top: 0 !important;
}

.input-bordered5>value {
      font-family: "Poppins", sans-serif !important;
}

.nav-tabs .nav-link {
      border: none !important;
}

.nav-link:focus,
.nav-link:active {
      color: #304073 !important;
}

.nav-link:hover {
      color: #304073 !important;
}

/* meet css end */

/* start bhavisha css */

/* contribution page  */
.user-panel .user-panel-title3 {
      color: #304073;
      font-size: 22.28px;
      margin-bottom: 12px;
      line-height: 1.68;
}

.user-panel .user-panel-subtitle3 {
      color: #6783b8;
      font-weight: 400;
      margin-bottom: 8px;
      font-size: 14px !important;
      line-height: 1.68;
}

.payment-item-one label {
      margin: 0 !important;
}

.payment-item-one span {
      font-family: 'Poppins', sans-serif !important;
}

.payment-item-one em.arrow-img {
      font-size: 10px !important;
}

.payment-cur3 {
      font-size: 16px !important;
      color: #304073;
      font-family: 'Poppins', sans-serif;
      font-weight: 100 !important;
}

.panel-custom p {

      color: #304073;
      margin-bottom: 12px !important;
      font-family: "Poppins", sans-serif;
      font-weight: 100 !important;
}

.payment-calculator3 label {
      color: #6783b8;
      font-size: 14px !important;
      font-family: "Poppins", sans-serif;
      line-height: 1.68;
}

.payment-bonus3 {
      font-family: "Poppins", sans-serif !important;
}

.payment-bonus-title3 {
      line-height: 1.68 !important;
}

.payment-calculator-note {
      font-size: 12px;
      color: #6783b8;
      font-family: "Poppins", sans-serif;
      font-weight: 100;
}

.payment-summary-info {
      line-height: 1.86 !important;
}

.usd-tcx .payment-cal-cur3 {
      position: absolute;
      top: 4px;
      right: 0;
      text-transform: uppercase;
      color: #6783b8;
      display: inline-block;
      padding: 8.5px 12px;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 100;
}

.payment-bonus-amount3 {
      font-family: 'Poppins', sans-serif !important;
      font-weight: 100;
      color: #9eaecf;
}

.payment-bonus-time-two {
      color: #6783b8 !important;
      font-weight: 100;
}

.payment-summary .payment-summary-amount3 {
      font-family: 'Poppins', sans-serif;
}

.tokenbtn {
      background-image: none !important;
      padding: 10px 30px !important;
      font-size: 14px !important;
      font-weight: 400;
      background: #2b56f5 !important;
      font-family: 'Poppins', sans-serif;
}

.input-bordered {
      font-family: 'Poppins', sans-serif;
}

/* referral page  */

.panel-custom3 h5 {
      font-size: 14px;
      font-family: 'Poppins';
      color: #6783b8;
      line-height: 1.86;
}

.panel-custom3 p strong {
      font-weight: 600 !important;
}

.panel-custom3 p {
      font-size: 14.8px;
      color: #304073;
      line-height: 1.68;
      margin-bottom: 12px !important;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
}

.panel-custom3 h6 {
      font-size: 13px;
      font-family: 'Poppins';
      color: #6783b8;
      line-height: 1.68;
      margin-bottom: 12px;
}

input.refferal-address3 {
      font-size: 14px;
      font-family: 'Poppins';
}

em.ti.ti-files {
      font-size: 14px;
}

.share-with li {
      font-family: "Poppins", sans-serif;
      color: #6783b8;
      font-weight: 300;
      margin: 10px 12px !important;
}

.panel-custom3 h4 {
      font-size: 18px;
      line-height: 1.68;
      color: #6783b8;
      font-family: 'Poppins';
      margin-bottom: 12px;
}

.refferal-statistics {
      margin-bottom: 12px;
}

.refferal-statistics3 span {
      font-family: "Poppins", sans-serif !important;
      line-height: 36.50px !important;
}

.datatable-custom {
      font-family: "Poppins", sans-serif !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination-custom {
      margin: 2px 0;
      white-space: nowrap;
      justify-content: flex-start !important;
}

.pagination-custom li a {
      font-family: "Poppins", sans-serif !important;
      color: #abbbd9 !important;
      font-weight: 100;
}

.datatable-pagination3 .pagination-custom li a.active,
.pagination-custom li a:hover {
      color: #6783b8 !important;
      background: #cad2f1 !important;
}

.page-item.active .pagination-num {
      color: #6783b8 !important;
      background: #cad2f1 !important;
      border: none;
      z-index: 3;
      font-size: 14px;

}

.page-item.disabled .pagination-num {
      color: #6c757d !important;
      pointer-events: none;
      background-color: #fff;
      border-color: #dee2e6;
}

.disabled.previous .page-link,
.disabled.next .pagination-num {
      display: none !important;
}

.previous .pagination-num,
.next .pagination-num {
      background: #eff6ff;
}

.pagination-num {
      position: relative !important;
      display: block !important;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #dee2e6;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.pagi-num {
      display: flex;
      justify-content: flex-end;
      align-items: center;
}

.datatable-pagination3 ul li {
      font-size: 14px !important;
}

.datatable-pagination3 ul li a:active,
.datatable-pagination3 ul li a:focus {
      font-size: 14px !important;
      box-shadow: none;
}

/* security page */

.user-content-two {
      font-family: 'Poppins';
      font-weight: 100;
      font-size: 14px;
}

h2.user-panel-title-two {
      font-size: 23px;
      font-family: 'Poppins', sans-serif;
      line-height: 37px;
}

.user-panel-two p {
      line-height: 24px;
}

.nav-tabs-line-two {
      margin-bottom: 3px !important;
      border-bottom: 2px solid #d3e0f3 !important;
}

.nav-tabs .nav-link {
      margin-bottom: -1px;
      background: 0 0;
      border: none !important;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
}

.nav-link:hover {
      color: #495057 !important;
}

.input-switch~label:not(.error):before {
      height: 24px !important;
      width: 50px !important;
      left: -2px !important;
}

.input-switch:checked~label:after {
      left: calc(100% - 20px) !important;
}

.input-switch~label:not(.error):after {
      height: 17px !important;
      width: 17px !important;
}

#security-opt .user-panel-subtitle-two {
      font-size: 14px !important;
      line-height: 24px !important;
}

.notification-list li span {
      color: #6783b8 !important;
      line-height: 24px !important;
}

.currnt-status span {
      color: #6783b8 !important;
}

span.alert-dsable {
      padding: 5px 12px !important;
}

button.btn.btn-primary.update-btn {
      background-image: none !important;
      padding: 8px 15px !important;
      font-size: 14px !important;
}

/* end bhavisha css */