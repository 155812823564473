.btnConnect{
    background-color: #512da8;
    color:white;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 12px;
    border:none;
    border-radius: 4px;
}
.btnConnect:hover{
    background-color: #1a1f2e;
    transition: 0.5s;
}

.connectBtnImg{
    height: 50%;
    padding-right:8px;
}