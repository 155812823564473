.banner-main {
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-height: 500px;
}

.banner-left {
    margin: 50px;
}

.banner-img {
    width: 500px;
    height: 500px;
}

.bg-primary {
    background-color: #3221ad;
}

.banner-btn {
    background-image: linear-gradient(to right, #2bc9b4 0%, #866bfe 100%) !important;
    border-radius: 25px !important;
    border: 0px;
}

.pre-sale-inner-top {
    /* background-image: linear-gradient(to right, #2b56f5 0%, #46bdf4 100%); */
    border-start-start-radius: 7px;
    border-start-end-radius: 7px;
    padding: 6px;
}

.banner-btn:hover {
    background-color: #3221ad;
}

.banner-main h1 {
    color: #fff;
    text-align: left;
    font-size: 32px;
    margin-bottom: 24px;
}

.banner-main h5 {
    color: #ffffff;
    text-align: left;
}

.banner-main p {
    color: #c4afff;
    text-align: left;
    font-size: 18px;
}

.banner-btn-box {
    display: flex;
}

.mr-10 {
    margin-right: 20px;
}

.icon-about-img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-height: 500px;
}

.ico-about-right {
    position: relative;
}

.pt-72 {
    padding-top: 72px;
}

section.banner-sec {
    background-color: #000;
}

.title-line:before {
    position: absolute;
    left: 0px !important;
    top: 11px;
    height: 2px;
    width: 60px;
    content: '';
    opacity: 1 !important;
    background: #16a3fe;
    ;
}

.about-subtitle-text {
    color: #16a3fe;
    ;
    text-align: left;
    padding-left: 80px;
}

.about-title-text {
    color: #a2b4d2d9;
    text-align: left;
    font-size: 35px;
    margin-bottom: 8px;
}

.ico-about-para p {
    font-size: 14px;
    line-height: 28px;
    font-family: 'Poppins';
    text-align: justify;
    color: #a2b4d2d9;
}

section.ico-about-sec {
    background-color: #0b0c22;
    padding: 50px 0px;
}

.foundars-sec {
    padding: 120px 0px;
    background-color: #08091b;
}

.foundar-box {
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%); */
    padding: 10px 10px 10px 10px;
    /* background-color: #10122d; */
    min-height: 300px;
}

.foundar-img-box {
    /* border: 10px solid #a2b4d2d9; */
    border-radius: 50%;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.foundar-img-box img {
    height: 150px;
    width: 160px;
    object-fit: cover;
    margin: auto;
    border-radius: 50%;
}

.foundar-details {
    padding: 5px;
}

.fondar-title {
    font-size: 20px;
    line-height: 5px;
    color: #fff;
    margin: 20px 0px 0px;
    text-align: center;
}

.foundar-position {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    margin: 10px 0px 0px;
}

.press-text {
    font-size: 40px !important;
    text-transform: capitalize !important;
}

.copyright-center-para {
    display: flex;
    justify-content: center;
    align-items: center;
}

.foundar-para {
    font-size: 15px;
    line-height: 27px;
    color: #a2b4d2d9;
    font-family: 'Poppins';
    text-align: center;
}

.btn-header {
    background-color: transparent !important;
    border: 2px solid #fff !important;
}

.main-title-box h1 {
    color: #fff;
    margin-bottom: 18px;
    text-align: left;
    font-size: 36px;
    line-height: 48px;
    font-weight: 500;
}

.join-date-text {
    color: #fff;
    font-size: 24px;
}

.main-title-box {
    position: relative;
}

.main-subtitle-text::before {
    position: absolute;
    left: 6px !important;
    top: 7px;
    height: 2px;
    width: 69px;
    content: '';
    opacity: 1 !important;
    background: #39a9ff;
}

.main-subtitle-text {
    color: #07bcff !important;
    text-align: left;
    padding-left: 80px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}

.ico-collection-sec {
    background-color: #0b0c22;
    padding: 50px 0px;
}

.primary-text {
    color: #16a3fe !important;
}

.primary-title-line::after {
    position: absolute;
    left: 2px !important;
    top: 7px;
    height: 2px;
    width: 69px;
    content: '';
    opacity: 1 !important;
    border-radius: 3px;
    background: #16a3fe;
}

.btn-read-more {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin-top: 30px;
    max-width: 185px;
}

.token-sale-main {
    background-color: #000;
    padding: 0px 0px 50px;
}

.token-para {
    font-size: 15px;
    text-align: left;
    font-family: 'Poppins';
    padding-bottom: 50px;
    color: #a2b4d2d9;
}

.token-pre-sale-box {
    border-radius: 7px;
    /* border: 1px solid rgb(255 255 255 / 20%); */
}

.pre-sale-text {
    font-size: 22px;
    background-color: #347af4;
    font-family: 'Poppins';
    color: #fff;
    text-transform: uppercase;
    border-start-start-radius: 7px;
    border-start-end-radius: 7px;
    text-align: center;
    padding: 10px;
}

.pre-sale-date {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0px;
    color: #fff;
    font-family: 'Poppins';
    text-align: center;
}

.pre-sale-days {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 0px;
    color: #e4e1ff;
    text-align: center;
    font-family: 'Poppins';

}

.pre-sale-inner-middle {
    padding: 10px;
    /* border-bottom: 1px solid rgb(255 255 255 / 20%); */
    background-color: #000;
}

.pre-sale-inner-bottom {
    padding: 10px;
    background-color: #000;
    border-end-end-radius: 7px;
    border-end-start-radius: 7px;
}

.pre-sale-bonus-text {
    font-size: 18px;
    font-family: 'Poppins';
    line-height: 28px;
    margin-bottom: 0px;
    color: #07bcff;
    text-align: center;
}
.pre-sale-bonus-price{
    font-size: 18px;
    font-family: 'Poppins';
    line-height: 28px;
    margin-bottom: 0px;
    color: #e4e1ff;
    text-align: center;
}
.pre-sale-soft-text {
    font-size: 16px;
    font-family: 'Poppins';
    text-align: center;
    line-height: 28px;
    margin-bottom: 0px;
    color: #fff;
}

.pre-sale-price {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0px;
    font-family: 'Poppins';
    color: #e4e1ff;
    text-align: center;
}

.token-table table {
    border: 1px solid #9e9e9e66;
}

.token-table .table tr {
    border-bottom: 1px solid #9e9e9e66;
}

.token-table .table-bordered td {
    border-bottom: 1px solid #a2b4d2d9 !important;
    font-family: 'Poppins';
    font-size: 15px;
    padding: 10px;
    text-align: center;
    color: #fff;
}

.token-table .table-bordered td {
    border: 1px solid #a2b4d2d9;
}

.bg-light-blue {
    background-color: #07bcff12;
}

.bg-light-grey {
    background-color: #9e9e9e2e;
}

.token-pre-join-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    background-color: #0b3253;
    height: 100px;
    margin-top: 17px;
}

.token-td-even {
    background-color: #0a1832 !important;
}

.taken-td-odd {
    background-color: #262739 !important;
}

.token-join-box {
    width: 100%;
    height: 100px;
    display: flex;
    max-width: 200px;
    justify-content: center;
    align-items: center;
    background-color: #3221ad00;
}

.token-join-box h2 {
    font-size: 21px;
    line-height: 27px;
    font-family: 'Poppins';
    color: #a2b4d2d9;
}

.token-sign-up-btns h2 {
    font-size: 18px;
    font-family: 'Poppins';
    line-height: 26px;
    margin-right: 16px;
    color: #a2b4d2d9;
}

.token-sign-up-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.token-btn {
    /* background-image: linear-gradient(to right, #2b56f5 0%, #46bdf4 100%) !important; */
    background-image: linear-gradient(to right, #46bdf4 0%, #2b56f5 100%) !important;
    border: 0px;
}

.hero-sec-dark {
    background-color: #000;
}

.token-timer-ul {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center;
    align-items: center;
}

.token-timer-ul li {
    margin: 0px 10px 0px 10px;
}

.timer-text-box {
    font-size: 41px;
    line-height: 32px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.timer-span {
    font-size: 12px;
}

.disti-left-img {
    /* height: 500px;
    width: 500px;
    object-fit: cover; */
}

.distibute {
    padding: 50px 0px;
    background-color: #000;
}

.disti-col {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}

.disti-title {
    color: #16a3fe;
}

.market-text {
    color: #a2b4d2d9 !important;
    text-align: left;
}

.market-sec {
    background-color: #0b0c22;
}

.laptop-market-img {
    height: 400px;
    width: 500px;
    object-fit: contain;
}

.main-para {
    text-align: left;
    color: #a2b4d2d9;
}

.roadmap-sec {
    background-color: #0b0c22;
    padding: 100px 0px;
}

.roadmap-title {
    color: #2b56f5 !important;
    transition: all ease 0.5s;
}
.roadmap-title:hover {
    color: #aacafb !important;
}
.roadmap-li:hover{
    color: #2b56f5 !important;
}
.roadmap-li {
    color: #aacafb;
    transition: all ease 0.5s;
}

.footer-sec {
    background-color: #10122d !important;
    background-image: linear-gradient(45deg, #000000d2, #000000d2), url(../images/home/footer5.png);
}

.bloackchain-sec {
    background-color: #000;
    padding: 50px 0px;
}

.mt-50 {
    margin-top: 50px;
}

.contact-text-area {
    position: relative;
}

.contact-text-area::placeholder {
    bottom: 10px;
    color: #ccc;
    position: absolute;
    text-align: center;
    width: 100%;
}

.block-img {
    height: 350px;
    width: 500px;
    object-fit: contain;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-para {
    color: #fff;
    text-align: left;
    font-size: 17px;
    line-height: 20px;
}

.banner-btn {
    background: transparent;
    border: 2px solid #fff;
}

.mt-50 {
    margin-top: 50px !important;
}

.banner {
    background-color: #000;
}

.metaverse-sec {
    background-color: #000;
    padding: 70px 0px 50px;
    background-image: linear-gradient(204deg, rgb(0 0 0 / 26%), rgb(0 0 0 / 26%)),
}

.metavers-img {
    height: 565px;
    width: 100%;
    object-fit: contain;
}

.meta-title {
    color: #fff;
    margin-bottom: 20px;
    font-size: 43px;
    text-align: center;
}

.meta-para {
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    max-width: 730px;
    margin: auto;
}

.meta-content {
    margin: 0px 0px 50px;
    text-align: center;
}

.partner-sec {
    background-color: #10122d;
    padding: 50px 0px 0px;
    position: relative;
}

.partner-inner-title {
    font-size: 20px;
    line-height: 30px;
    color: #a2b4d2d9;
}

.section-bg {
    background-color: #0b0c22;
    padding: 50px 0px;
}

.blog-img {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.blog-content {
    background-color: #0b0c22;
}

.blog-para {
    font-size: 16px;
    line-height: 30px;
    color: #a2b4d2d9;
}

.blog-date {
    font-size: 16px;
    line-height: 30px;
    color: #a2b4d2d9 !important;
}

.blog-name {
    font-size: 16px;
    line-height: 30px;
    color: #a2b4d2d9 !important;
}

.contact-para {
    font-size: 16px;
    line-height: 30px;
    color: #fff;
}

.contact-sec {
    padding: 0px 0px 100px;
    background-color: #0b0c22 !important;
}

.contact-data {
    font-size: 16px;
    line-height: 30px;
    color: #fff;
}

.long-td {
    height: 65px;
    vertical-align: middle;
}

.contact-input::placeholder {
    color: #fff;
}

.blog-overlay {
    position: absolute;
    background: #0000004a;
    height: 180px;
    display: block;
    top: 0;
    left: 0;
    right: 0;
}

.blog-sec {
    padding: 50px 0px;
}

.foundar-inner-title {
    color: #fff;
    font-size: 35px;
    padding: 50px 0px 30px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.round-bg {
    background-color: #0b0c22;
}

.accordion-faq {
    background-color: #171a40 !important;
    border-radius: 22px !important;
}

.faq-para {
    color: #a2b4d2d9;
    font-size: 17px;
    line-height: 29px;
}

.team-color {
    color: #9947ff;
}

.faq-main-para {
    font-size: 17px;
}

.fa-envelope:before {
    content: "\f0e0";
    color: #000;
}

.fa-paper-plane:before {
    content: "\f1d8";
    color: #000;
}

.fa-phone:before {
    content: "\f095";
    color: #000;
}

.partner-content {
    text-align: center;
    color: #fff;
    padding: 0px 0px 50px;
}

.partner-middle-text {
    font-size: 22px;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 10px;
}

.manage-title {
    font-size: 25px;
    letter-spacing: 3px;
    margin-bottom: 20px;
}

.partner-para {
    font-size: 17px;
    color: #fff;
    max-width: 764px;
    margin: auto;
}

.text-uppercase {
    text-transform: uppercase;
}

.team-social-media {
    text-align: center;
    margin-top: 12px;
}

.social-img {
    height: 35px;
    width: 35px;
    object-fit: contain;
}

.team-social-media li {
    padding: 3px;
}

.readmap-para-inner {
    color: #a2b4d2d9;
}

.roadmap-middle-para {
    font-size: 23px;
    color: #fff;
    letter-spacing: 2px;
}

.distibute-title {
    color: #fff;
    font-size: 35px;
}

.faq-sec {
    padding: 150px 0px 150px;
}

.banner-social-media {
    margin: 35px 0px 0px;
}

.banner-right {
    background-image: url(../images/home/banner.png);
    /* background-image: url(../images/home/banner-right.png); */
    height: 431px;
    width: 100%;
    background-repeat: no-repeat;
    position: relative;
    background-size: 100% 100%;
    margin-top: 50px;
    /* opacity:0.4; */
}

.new-banner {
    background: #000000b3;
    height: 500px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
}

.roadmap-para {
    font-size: 15px;
    color: #8284a5;
}

.banner-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 542px;
}

.banner-title {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 20px !important;
}

.banner-para {
    color: #fff !important;
    font-size: 15px !important;
    line-height: 30px !important;
}

.text-left {
    font-size: 14px;
    background-color: #10122d;
    border-radius: 6px;
}

.sec-blog {
    background-color: #08091b !important;
}

.blog-title a {
    font-size: 20px;
    text-align: left;
}

.btn-primary {
    background-image: linear-gradient(to right, #2b56f5 0%, #46bdf4 100%) !important;
}


.partner-col {
    margin: 0px 21px !important;
    padding: 0px;
}

.mt-100 {
    margin-top: 100px !important;
}

.banner-left {
    margin-top: 180px;
}
.team-owl .owl-nav [class*=owl-] {
    line-height: 37px;
    color: #ffffff4d !important;
    font-size: 30px !important;
    margin: 0px 5px 0px 5px !important;
    padding: 0px !important;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    background-color: #ffffff1a !important;
    align-items: center !important;
    justify-content: center !important;
}
.team-owl .owl-dots{
    display: none;
}
.team-owl .owl-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 29%;
}
.team-owl .owl-prev{
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 50%;
}
.team-owl .owl-next{
    position: absolute;
    right: 0;
}
.foundar-row{
    display: flex;
    justify-content: center;
    align-items: center;
}
.owl-row {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: auto;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.page-top-gap{
    margin-top: 67px;
} 
.user-image-box{
    margin-top: 40px;
}
.user-dashboard{
    width: 100%;
}
.close-menu-icon{
    display: none;
}