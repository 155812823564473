
  .login-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .loginform {
    margin: 3rem 0;
    padding: 40px 40px 0px 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    border: 1px solid gray;
  }
  .signupform{
    margin: 3rem 0;
    padding: 40px 40px 0px 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    border: 1px solid gray;
  }
  .form-title{
    text-align: center;
    color: black;
  }
  .input-container {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
  }
  
  .label {
    margin-bottom: 5px;
  }
  
  .input {
    padding: 5px 15px 5px 5px;
    width:300px;
  }
  
  .forgotten-password {
    align-self: flex-end;
    margin-top: 7px;
  }
  
  #login-btn {
    width:100%;
    align-self: center;
    padding: 7px 30px;
    margin-top: 30px;
    border: none;
    color: #fff;
    background-color: rgb(6, 178, 247);
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 20px;
  }
  
  #login-btn:hover {
    background-color: white;
    color:blue;
    border: 1px solid rgb(6, 178, 247);
  }
  
  .signup-label {
    position: relative;
    bottom: -10px;
    color: #fff;
    font-size: 14px;
  }
  
  .link {
    text-decoration: none;
    color:blue;
  }
  .link:hover, .signuplink:hover {
    color:blue;
    text-decoration: underline;
  }
  
  .signuplink{
    margin: 2rem;
  }