
.contribution-dash-main {
    display: flex;
    justify-content: space-between;
}
.contribution-left-main{
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
}
.contribution-top{
    min-height: 106px;
    background: #eff6ff;
    border: 2px solid #dce6f5;
}
.contribution-padding{
    padding: 10px !important;
}
.contribution-inner{
    min-height: 161px;
}
.text-contribution{
    color: black !important;
}
.contribution-dash-main .contribution-left-main:first-child{
    margin-right: 10px;
}
.contribution-bottom{
    margin-top: 5px;
}
.contribution-bottom .contribution-button{
    width: 100%;
    display: flex;
    justify-content: center;
}
@media screen and (min-width: 992px) and (max-width:1199px){}
@media screen and (max-width:991px){}
@media screen and (min-width: 768px) and (max-width: 991px){
    .sidebar-main{
        display: flex;
        align-items: center;
    }
    .banner-main {
        margin: 50px 0px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        min-height: 478px;
    }
    .mt-20{
        margin-top: 20px;
    }
    .earth-box-cover:before {
        width: 522px;
        height: 517px;
    }
    .earth-box-cover {
        position: relative;
        width: 364px;
        height: 459px;
    }
    .hero-md{
        padding-right: 0px;
        overflow: hidden;
    }
    .token-table{
        margin-top: 20px;
    }
    .mdtop-20px{
        margin-top: 20px;
    }
    .mobile-wallet-main .wallet-adapter-button-trigger {
        max-width: max-content;
        width: 100%;
        padding: 10px;
        font-size: 12px;
        height: 30px;
    }
    .mobile-wallet-main {
        margin-right: 10px;
    }
    .mobile-user-main {
        display: flex;
        align-items: center;
    }
    .progress-info li span {
        font-size: 15px;
    }
    .close-menu-icon{
        display: block;
        height: 15px;
        width: 15px;
        margin-left: auto;
      }

}

@media screen and (max-width:767px){
    .close-menu-icon{
        display: block;
        height: 15px;
        width: 15px;
        margin-left: auto;
      }
      .user-nav li a {
        text-align: left;
    }
      .mobilemenu-icon{
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    .hero-main{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .foundar-box{
        margin-bottom: 20px;
    }
    .icon-about-img-box {
        min-height: 291px;
    }
    .btn-read-more {
        margin: 30px auto;
    }
    .foundars-sec {
        padding: 50px 0px 30px;
    }
    .banner-main {
        margin: 0px 0px 50px;
        min-height: 224px;
    }
    .earth-box-cover::before {
        width: 320px;
        height: 320px;
    }
    .earth-box-cover {
        margin: auto;
        display: table;
        width: 320px;
        height: 320px;
    }
    .earth-box, .earth-box span {
        width: 240px;
        height: 240px;
    }
    .banner-left {
        margin: 50px 0px;
    }
    .main-title-box h1 {
        font-size: 30px;
    }
    .about-title-text {
        font-size: 28px;
    }
    .primary-title-line::after {
        width: 35px;
    }
    .about-subtitle-text {
        padding-left: 54px;
    }
    .token-pre-join-main {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
        margin-top: 0px;
    }
    .token-join-box{
        width: 100%;
        max-width: inherit;
    }
    .token-sign-up-btns {
        flex-direction: column;
    }
    .token-pre-sale-box {
        margin-bottom: 20px;
    }
    .mobile-wallet-main .wallet-adapter-button-trigger {
        max-width: max-content;
        width: 100%;
        padding: 10px;
        font-size: 12px;
        height: 30px;
    }
    .mobile-wallet-main {
        margin-right: 10px;
    }
    .mobile-user-main {
        display: flex;
        align-items: center;
    }
    .progress-info li span {
        font-size: 15px;
    }
    .sidebar-main{
        display: flex;
        align-items: center;
    }
    .logo-main{
        height: 50px;
        width: 100%;
    }
} 
