/*!
 * Theme Name: ICOCrypto
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version	 :	2.0.0
 * Updated	 :	03/08/2022
**/
/**
----------------------
## TABLE CONTNETS ##
01. Fonts
02. Utilities
--  02.01 Reset
--  02.02 Spacing
--  02.03 Colors
--  02.04 Misc
03. Elements
--  03.01 Alert
--  03.02 Buttons
--  03.03 Title
--  03.04 List
--  03.05 Form
--  03.06 Table
--  03.07 Progress
--  03.08 CountDown
--  03.09 Toastr
--  03.10 Misc
04. Components
--  04.01 Tab
--  04.02 Accordion
--  04.03 Blocks
--  04.04 Box
--  04.05 Banner
--  04.06 Head
--  04.07 Features
--  04.08 Roadmap
--  04.09 Token
--  04.10 Team
--  04.11 Partners
--  04.12 Contact
--  04.13 WGS
--  04.14 Others
05. Layouts
--  05.01 Header
--  05.02 Menu
--  05.03 Sections
--  05.04 Footer
06. Override
07. Preview Purpose 
---------------------- **/
/** IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file. It will helps you to easy update of the template. */
/** 01. Fonts */
@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Bold.eot"); src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"); font-weight: bold; font-style: normal; }

@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Light.eot"); src: local("Poppins Light"), local("Poppins-Light"), url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }

@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-SemiBold.eot"); src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"); font-weight: 600; font-style: normal; }

@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Medium.eot"); src: local("Poppins Medium"), local("Poppins-Medium"), url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; }

@font-face { font-family: 'Poppins'; src: url("../fonts/Poppins-Regular.eot"); src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Light.eot"); src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"); font-weight: 300; font-style: normal; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Regular.eot"); src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"); font-weight: normal; font-style: normal; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Medium.eot"); src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"); font-weight: 500; font-style: normal; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto-Bold.eot"); src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"); font-weight: bold; font-style: normal; }

/** 02. Utilities */
/** 02.01 Reset */
html, body { font-family: "Roboto", sans-serif; color: #415076; font-size: 14px; line-height: 1.86; font-weight: 300; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; position: relative; }

@media (min-width: 576px) { html, body { font-size: 16px; line-height: 1.86; font-weight: 300; } }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section { display: block; }

*[hidden] { display: none; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote::after, blockquote::before, q::after, q::before { content: none; }

table { border-collapse: collapse; border-spacing: 0; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { color: #5b53a2; font-weight: 500; font-family: "Poppins", sans-serif; line-height: 1.45; margin-bottom: 0.5rem; }

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child { margin-bottom: 0; }

.tc-light h1, .tc-light h2, .tc-light h3, .tc-light h4, .tc-light h5, .tc-light h6, .tc-light .h1, .tc-light .h2, .tc-light .h3, .tc-light .h4, .tc-light .h5, .tc-light .h6 { color: #fff; }

p { font-size: 14px; margin-bottom: 1rem; color: #415076; }

p:last-child { margin-bottom: 0; }

p + [class*=btn] { margin-top: 15px; }

@media (min-width: 576px) { p { font-size: 15px; } }

ul, ol, table { margin: 0; padding: 0; }

strong, b { font-weight: 500; }

a { outline: 0 none; transition: all 0.5s; color: #866bfe; }

a:link, a:visited { text-decoration: none; }

a:hover, a:focus, a:active { outline: 0; color: #2bc9b4; }

img { max-width: 100%; height: auto; }

h1, .h1, .fz-1 { font-size: 1.75rem; }

h2, .h2, .fz-2 { font-size: 1.35rem; }

h3, .h3, .fz-3 { font-size: 1.25rem; }

h4, .h4, .fz-4 { font-size: 1.2rem; }

h5, .h5, .fz-5 { font-size: 1.2rem; }

h6, .h6, .fz-6 { font-size: 0.94rem; }

.fz-7 { font-size: 0.88rem; }

.fz-8 { font-size: 0.75rem; }

.lead, .lead-light { font-size: 1.1rem; font-weight: 300; color: #415076; }

.lead-s2 { font-size: 1.1rem; color: #415076; }

.lead-regular { font-weight: 400; }

@media (min-width: 576px) { h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { line-height: 1.3; }
  h1, .h1, .fz-1 { font-size: 2.75rem; }
  h2, .h2, .fz-2 { font-size: 2.25rem; }
  h3, .h3, .fz-3 { font-size: 1.87rem; }
  h4, .h4, .fz-4 { font-size: 1.6rem; }
  .lead, .lead-light { font-size: 1.1rem; }
  .lead-s2 { font-size: 1.2rem; } }

.nk-df-content, .nk-ovm:before, .nk-ovm:after { content: ""; }

.nk-df-rel, .has-ovm, .has-bg, [class*='bg-'], .has-bg-image, .has-bg-video { position: relative; }

.nk-df-over-up, .has-ovm > [class*=container], .has-ovm > *:not(.nk-ovm):not(.header-main):not(.nk-header):not([class*=ui-mask]), .has-bg-image > [class*=container], .has-bg-video > [class*=container] { position: relative; z-index: 5; }

.nk-df-t, .nk-df-y { top: 0; }

.nk-df-b, .nk-df-y { bottom: 0; }

.nk-df-l, .nk-df-x { left: 0; }

.nk-df-r, .nk-df-x { right: 0; }

.nk-df-around, .nk-ovm, .nk-ovm:before, .nk-ovm:after, .bg-image, .bg-video, .bg-video-cover, .overlay:after, .nk-ovm .nk-svgs, .accordion-icon:before, .accordion-icon:after { position: absolute; bottom: 0; top: 0; left: 0; right: 0; }

.nk-df-center, .abs-center, .nk-block-video-play { position: absolute !important; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.nk-df-bg, .nk-ovm, .nk-ovm:before, .nk-ovm:after, .has-bg, [class*='bg-'], .bg-image, .bg-video, .bg-video-cover { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }

.nk-df-bg-100, .img-block-dot, .app-slide-wrap:after, .rank-item { background-repeat: no-repeat; background-size: 100%; background-position: 50% 50%; }

.text-regular-sm { font-weight: 400; font-size: 0.9rem; }

.block-row:not(:last-child) { margin-bottom: 40px !important; }

@media (min-width: 992px) { .block-row:not(:last-child) { margin-bottom: 50px !important; } }

/** 02.02 Spacing */
.gap { height: 30px; }

.gap-s { height: 10px; }

.gap-m { height: 20px; }

.gap-l { height: 40px; }

.gap-x { height: 60px; }

.gap-1x { height: 10px; }

.gap-2x { height: 20px; }

.gap-3x { height: 30px; }

.gap-4x { height: 40px; }

.gap-5x { height: 50px; }

.gap-6x { height: 60px; }

.gap-7x { height: 70px; }

.gap-8x { height: 80px; }

.gap-9x { height: 90px; }

.gap-10x { height: 100px; }

.pdt, .pd, .pdy { padding-top: 30px; }

.pdb, .pd, .pdy { padding-bottom: 30px; }

.pdl, .pd, .pdx { padding-left: 30px; }

.pdr, .pd, .pdx { padding-right: 30px; }

.pdt-s, .pd-s, .pdy-s { padding-top: 10px !important; }

.pdt-m, .pd-m, .pdy-m { padding-top: 20px !important; }

.pdt-r, .pd-r, .pdy-r { padding-top: 30px !important; }

.pdt-l, .pd-l, .pdy-l { padding-top: 40px !important; }

.pdt-x, .pd-x, .pdy-x { padding-top: 60px !important; }

.pdb-s, .pd-s, .pdy-s { padding-bottom: 10px !important; }

.pdb-m, .pd-m, .pdy-m { padding-bottom: 20px !important; }

.pdb-r, .pd-r, .pdy-r { padding-bottom: 30px !important; }

.pdb-l, .pd-l, .pdy-l { padding-bottom: 40px !important; }

.pdb-x, .pd-x, .pdy-x { padding-bottom: 60px !important; }

.pdl-s, .pd-s, .pdx-s { padding-left: 10px !important; }

.pdl-m, .pd-m, .pdx-m { padding-left: 20px !important; }

.pdl-r, .pd-r, .pdx-r { padding-left: 30px !important; }

.pdl-l, .pd-l, .pdx-l { padding-left: 40px !important; }

.pdl-x, .pd-x, .pdx-x { padding-left: 60px !important; }

.pdr-s, .pd-s, .pdx-s { padding-right: 10px !important; }

.pdr-m, .pd-m, .pdx-m { padding-right: 20px !important; }

.pdr-r, .pd-r, .pdx-r { padding-right: 30px !important; }

.pdr-l, .pd-l, .pdx-l { padding-right: 40px !important; }

.pdr-x, .pd-x, .pdx-x { padding-right: 60px !important; }

.mgt, .mg, .mgy { margin-top: 30px !important; }

.mgb, .mg, .mgy { margin-bottom: 30px !important; }

.mgl, .mg, .mgx { margin-left: 30px !important; }

.mgr, .mg, .mgx { margin-right: 30px !important; }

.mg-fix { margin-bottom: -20px; }

.mg-fix-lg { margin-bottom: -45px; }

.mg-fix-md { margin-bottom: -30px; }

.mg-fix-sm { margin-bottom: -15px; }

.mg-fix-xs { margin-bottom: -10px; }

.mgt-s, .mg-s, .mgy-s { margin-top: 10px !important; }

.mgt-m, .mg-m, .mgy-m { margin-top: 20px !important; }

.mgt-r, .mg-r, .mgy-r { margin-top: 30px !important; }

.mgt-l, .mg-l, .mgy-l { margin-top: 40px !important; }

.mgt-x, .mg-x, .mgy-x { margin-top: 60px !important; }

.mgb-s, .mg-s, .mgy-s { margin-bottom: 10px !important; }

.mgb-m, .mg-m, .mgy-m { margin-bottom: 20px !important; }

.mgb-r, .mg-r, .mgy-r { margin-bottom: 30px !important; }

.mgb-l, .mg-l, .mgy-l { margin-bottom: 40px !important; }

.mgb-x, .mg-x, .mgy-x { margin-bottom: 60px !important; }

.mgl-s, .mg-s, .mgx-s { margin-left: 10px !important; }

.mgl-m, .mg-m, .mgx-m { margin-left: 20px !important; }

.mgl-r, .mg-r, .mgx-r { margin-left: 30px !important; }

.mgl-l, .mg-l, .mgx-l { margin-left: 40px !important; }

.mgl-x, .mg-x, .mgx-x { margin-left: 60px !important; }

.mgr-s, .mg-s, .mgx-s { margin-right: 10px !important; }

.mgr-m, .mg-m, .mgx-m { margin-right: 20px !important; }

.mgr-r, .mg-r, .mgx-r { margin-right: 30px !important; }

.mgr-l, .mg-l, .mgx-l { margin-right: 40px !important; }

.mgr-x, .mg-x, .mgx-x { margin-right: 60px !important; }

@media (min-width: 576px) { .pdt-l, .pd-l, .pdy-l { padding-top: 60px !important; }
  .pdt-x, .pd-x, .pdy-x { padding-top: 90px !important; }
  .pdb-l, .pd-l, .pdy-l { padding-bottom: 60px !important; }
  .pdb-x, .pd-x, .pdy-x { padding-bottom: 90px !important; }
  .pdl-l, .pd-l, .pdx-l { padding-left: 60px !important; }
  .pdl-x, .pd-x, .pdx-x { padding-left: 90px !important; }
  .pdr-l, .pd-l, .pdx-l { padding-right: 60px !important; }
  .pdr-x, .pd-x, .pdx-x { padding-right: 90px !important; }
  .mgt-l, .mg-l, .mgy-l { margin-top: 60px !important; }
  .mgt-x, .mg-x, .mgy-x { margin-top: 90px !important; }
  .mgb-l, .mg-l, .mgy-l { margin-bottom: 60px !important; }
  .mgb-x, .mg-x, .mgy-x { margin-bottom: 90px !important; }
  .mgl-l, .mg-l, .mgx-l { margin-left: 60px !important; }
  .mgl-x, .mg-x, .mgx-x { margin-left: 90px !important; }
  .mgr-l, .mg-l, .mgx-l { margin-right: 60px !important; }
  .mgr-x, .mg-x, .mgx-x { margin-right: 90px !important; } }

.mgb-m10 { margin-bottom: -10px !important; }

.mgb-m20 { margin-bottom: -20px !important; }

.mgb-m30 { margin-bottom: -30px !important; }

.mgb-m40 { margin-bottom: -40px !important; }

.mgb-m50 { margin-bottom: -50px !important; }

.mgt-m10 { margin-top: -10px !important; }

.mgt-m20 { margin-top: -20px !important; }

.mgt-m30 { margin-top: -30px !important; }

.mgt-m40 { margin-top: -40px !important; }

.mgt-m50 { margin-top: -50px !important; }

.mgt-m70 { margin-top: -70px !important; }

.no-gutters { --bs-gutter-x: 0; --bs-gutter-y: 0; }

.gutter-5px { margin-left: -2.5px !important; margin-right: -2.5px !important; }

.gutter-5px > li, .gutter-5px > div { padding-left: 2.5px !important; padding-right: 2.5px !important; }

.gutter-15px { margin-left: -7.5px !important; margin-right: -7.5px !important; }

.gutter-15px > li, .gutter-15px > div { padding-left: 7.5px !important; padding-right: 7.5px !important; }

.gutter-25px { margin-left: -12.5px !important; margin-right: -12.5px !important; }

.gutter-25px > li, .gutter-25px > div { padding-left: 12.5px !important; padding-right: 12.5px !important; }

.gutter-10px { margin-left: -5px !important; margin-right: -5px !important; }

.gutter-10px > li, .gutter-10px > div { padding-left: 5px !important; padding-right: 5px !important; }

.gutter-20px { margin-left: -10px !important; margin-right: -10px !important; }

.gutter-20px > li, .gutter-20px > div { padding-left: 10px !important; padding-right: 10px !important; }

.gutter-30px { margin-left: -15px !important; margin-right: -15px !important; }

.gutter-30px > li, .gutter-30px > div { padding-left: 15px !important; padding-right: 15px !important; }

.gutter-40px { margin-left: -20px !important; margin-right: -20px !important; }

.gutter-40px > li, .gutter-40px > div { padding-left: 20px !important; padding-right: 20px !important; }

.gutter-50px { margin-left: -25px !important; margin-right: -25px !important; }

.gutter-50px > li, .gutter-50px > div { padding-left: 25px !important; padding-right: 25px !important; }

.gutter-60px { margin-left: -30px !important; margin-right: -30px !important; }

.gutter-60px > li, .gutter-60px > div { padding-left: 30px !important; padding-right: 30px !important; }

.gutter-70px { margin-left: -35px !important; margin-right: -35px !important; }

.gutter-70px > li, .gutter-70px > div { padding-left: 35px !important; padding-right: 35px !important; }

.gutter-80px { margin-left: -40px !important; margin-right: -40px !important; }

.gutter-80px > li, .gutter-80px > div { padding-left: 40px !important; padding-right: 40px !important; }

.gutter-90px { margin-left: -45px !important; margin-right: -45px !important; }

.gutter-90px > li, .gutter-90px > div { padding-left: 45px !important; padding-right: 45px !important; }

.gutter-100px { margin-left: -50px !important; margin-right: -50px !important; }

.gutter-100px > li, .gutter-100px > div { padding-left: 50px !important; padding-right: 50px !important; }

.gutter-vr-5px { margin-top: -2.5px !important; margin-bottom: -2.5px !important; }

.gutter-vr-5px > li, .gutter-vr-5px > div { padding-top: 2.5px !important; padding-bottom: 2.5px !important; }

.gutter-vr-15px { margin-top: -7.5px !important; margin-bottom: -7.5px !important; }

.gutter-vr-15px > li, .gutter-vr-15px > div { padding-top: 7.5px !important; padding-bottom: 7.5px !important; }

.gutter-vr-25px { margin-top: -12.5px !important; margin-bottom: -12.5px !important; }

.gutter-vr-25px > li, .gutter-vr-25px > div { padding-top: 12.5px !important; padding-bottom: 12.5px !important; }

.gutter-vr-10px { margin-top: -5px !important; margin-bottom: -5px !important; }

.gutter-vr-10px > li, .gutter-vr-10px > div { padding-top: 5px !important; padding-bottom: 5px !important; }

.gutter-vr-20px { margin-top: -10px !important; margin-bottom: -10px !important; }

.gutter-vr-20px > li, .gutter-vr-20px > div { padding-top: 10px !important; padding-bottom: 10px !important; }

.gutter-vr-30px { margin-top: -15px !important; margin-bottom: -15px !important; }

.gutter-vr-30px > li, .gutter-vr-30px > div { padding-top: 15px !important; padding-bottom: 15px !important; }

.gutter-vr-40px { margin-top: -20px !important; margin-bottom: -20px !important; }

.gutter-vr-40px > li, .gutter-vr-40px > div { padding-top: 20px !important; padding-bottom: 20px !important; }

.gutter-vr-50px { margin-top: -25px !important; margin-bottom: -25px !important; }

.gutter-vr-50px > li, .gutter-vr-50px > div { padding-top: 25px !important; padding-bottom: 25px !important; }

.gutter-vr-60px { margin-top: -30px !important; margin-bottom: -30px !important; }

.gutter-vr-60px > li, .gutter-vr-60px > div { padding-top: 30px !important; padding-bottom: 30px !important; }

.gutter-vr-70px { margin-top: -35px !important; margin-bottom: -35px !important; }

.gutter-vr-70px > li, .gutter-vr-70px > div { padding-top: 35px !important; padding-bottom: 35px !important; }

.gutter-vr-80px { margin-top: -40px !important; margin-bottom: -40px !important; }

.gutter-vr-80px > li, .gutter-vr-80px > div { padding-top: 40px !important; padding-bottom: 40px !important; }

.gutter-vr-90px { margin-top: -45px !important; margin-bottom: -45px !important; }

.gutter-vr-90px > li, .gutter-vr-90px > div { padding-top: 45px !important; padding-bottom: 45px !important; }

.gutter-vr-100px { margin-top: -50px !important; margin-bottom: -50px !important; }

.gutter-vr-100px > li, .gutter-vr-100px > div { padding-top: 50px !important; padding-bottom: 50px !important; }

.gutter-100px { margin-left: -15px !important; margin-right: -15px !important; }

.gutter-100px > li, .gutter-100px > div { padding-left: 15px !important; padding-right: 15px !important; }

@media (min-width: 992px) { .gutter-100px { margin-left: -25px !important; margin-right: -25px !important; }
  .gutter-100px > li, .gutter-100px > div { padding-left: 25px !important; padding-right: 25px !important; } }

@media (min-width: 1200px) { .gutter-100px { margin-left: -50px !important; margin-right: -50px !important; }
  .gutter-100px > li, .gutter-100px > div { padding-left: 50px !important; padding-right: 50px !important; } }

/** 02.03 Colors */
.tc-base-white, .tc-light, .tc-light a:not(.btn):not([class*="link-"]) { color: #f7f8ff; }

.tc-base-white-hover, .tc-light a:not(.btn):not([class*="link-"]):hover { color: #866bfe; }

.tc-default, .tc-default a { color: #415076 !important; }

.tc-alternet, .tc-alternet a { color: #415076 !important; }

.tc-primary, .tc-primary a { color: #866bfe !important; }

.tc-primary a:hover { color: #2bc9b4 !important; }

.tc-secondary, .tc-secondary a { color: #2bc9b4 !important; }

.tc-secondary a:hover { color: #866bfe !important; }

.tc-info, .tc-info a { color: #026bfb !important; }

.tc-warning, .tc-warning a { color: #f6be26 !important; }

.tc-success, .tc-success a { color: #39d884 !important; }

.tc-danger, .tc-danger a { color: #e6604a !important; }

.tc-light p, .tc-light .list { color: #a2b4d2d9;  font-weight: initial;     }

.tc-light .lead, .tc-light .lead-light, .tc-light .lead-s2 { color: #af9cff; }

.tc-light-alt a:not(.btn):not([class*="link-"]) { color: #af9cff !important; }

.tc-light-alt a:not(.btn):not([class*="link-"]):hover { color: #866bfe !important; }

.tc-light-alt li { color: #af9cff; }

.tc-lighter { color: rgba(255, 255, 255, 0.6) !important; }

.tc-dark, .tc-dark a { color: #0b0c10 !important; }

.tc-theme, .tc-theme a { color: #866bfe !important; }

.tc-theme-alt, .tc-theme-alt a { color: #3c1c94 !important; }

.tc-white { color: #fff !important; }

.bg-default { background-color: #415076 !important; }

.bg-alternet { background-color: #3c1c94 !important; }

.bg-primary { background-color: #866bfe !important; }

.bg-secondary { background-color: #2bc9b4 !important; }

.bg-light { background-color: #10122d!important; }

.bg-light.current { background-color: #171a40 !important; }

.bg-light-alt { background-color: #eaeff6 !important; }

.bg-light-alt.current { background-color: rgba(234, 239, 246, 0.7) !important; }

.bg-light-grad { background-image: linear-gradient(to bottom, #fff 0%, #f7f8ff 100%); }

.bg-dark { background-color: #0b0c10 !important; }

.bg-dark.current { background-color: rgba(11, 12, 16, 0.7) !important; }

.bg-dark-alt { background-color: rgba(11, 12, 16, 0.7) !important; }

.bg-dark-alt.current { background-color: rgba(23, 26, 34, 0.7) !important; }

.bg-dark-opacity { background: rgba(0, 0, 0, 0.15) !important; }

.bg-trans { background-color: transparent !important; }

.bg-grad { background-image: linear-gradient(90deg, #866bfe 0%, #2bc9b4 100%) !important; }

.bg-grad-alt { background-image: linear-gradient(90deg, #2bc9b4 0%, #866bfe 100%) !important; }

.bg-grad-special { background-image: linear-gradient(140deg, #725bd6 0%, #725bd6 20%, transparent 50%, transparent 100%) !important; }

.bg-grad-special-alt { background-image: linear-gradient(to top, #6e19c2 0%, #6e19c2 5%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%); }

.bg-grad-vr-theme-to-alt { background-image: linear-gradient(to bottom, #3c1c94 0%, #6e19c2 100%) !important; }

.bg-grad-vr-theme-dark-to-dark-alt { background-image: linear-gradient(to bottom, #180747 0%, #2e186a 100%) !important; }

.bg-grad-vr-alt-to-theme { background-image: linear-gradient(to top, #3c1c94 0%, #6e19c2 100%) !important; }

.bg-grad-vr-dark-alt-to-theme-dark-to-theme { background-image: linear-gradient(to top, #180747 0%, #2e186a 100%) !important; }

.bg-info { background-color: #026bfb !important; }

.bg-warning { background-color: #f6be26 !important; }

.bg-success { background-color: #39d884 !important; }

.bg-danger { background-color: #e6604a !important; }

.bg-black { background-color: #000; }

.bg-black-2, .bonus-info { background-color: rgba(0, 0, 0, 0.02); }

.bg-black-5 { background-color: rgba(0, 0, 0, 0.05); }

.bg-black-7 { background-color: rgba(0, 0, 0, 0.07); }

.bg-black-10 { background-color: rgba(0, 0, 0, 0.1); }

.bg-black-14 { background-color: rgba(0, 0, 0, 0.14); }

.bg-black-22 { background-color: rgba(0, 0, 0, 0.22); }

.bg-white { background-color: #fff; }

.bg-white-2 { background-color: rgba(255, 255, 255, 0.02); }

.bg-white-5 { background-color: rgba(255, 255, 255, 0.05); }

.bg-white-7 { background-color: rgba(255, 255, 255, 0.07); }

.bg-white-10 { background-color: rgba(255, 255, 255, 0.1); }

.bg-white-14 { background-color: rgba(255, 255, 255, 0.14); }

.bg-white-22 { background-color: rgba(255, 255, 255, 0.22); }

.bg-theme { background-color: #3c1c94 !important; }
.bg-theme { background-color: #0b0c22!important; }

.bg-theme.current { background-color: rgba(60, 28, 148, 0.7) !important; }

.bg-theme-alt { background-color: #6e19c2 !important; }

.bg-theme-alt.current { background-color: rgba(110, 25, 194, 0.7) !important; }

.bg-theme-grad { background-image: linear-gradient(to right, #3c1c94 0%, #6e19c2 100%) !important; }

.bg-theme-grad-alt { background-image: linear-gradient(to right, #6e19c2 0%, #3c1c94 100%) !important; }

.bg-theme-grad-s2 { background-image: linear-gradient(45deg, #3c1c94 0%, #6e19c2 100%) !important; }

.bg-theme-grad-s2-alt { background-image: linear-gradient(45deg, #6e19c2 0%, #3c1c94 100%) !important; }

.bg-theme-grad-alternet { background-image: linear-gradient(to right, #2bc9b4 0%, #725bd6 100%) !important; }

.bg-theme-dark { background-color: #0b0c22 !important; }

.bg-theme-dark.current { background-color: rgba(24, 7, 71, 0.7) !important; }

.bg-theme-dark-alt { background-color: #2e186a !important; }

.bg-theme-dark-alt.current { background-color: rgba(46, 24, 106, 0.7) !important; }

.bg-theme-light { background-color: #f7f8ff !important; }

.bg-theme-light.current { background-color: rgba(247, 248, 255, 0.7) !important; }

.bg-theme-light-alt { background-color: #eaeff6 !important; }

.bg-theme-light-alt.current { background-color: rgba(234, 239, 246, 0.7) !important; }

.bg-theme-accent { background-color: #866bfe !important; }

.bg-theme-accent.current { background-color: rgba(134, 107, 254, 0.7) !important; }

.bg-theme-accent-alt { background-color: #2bc9b4 !important; }

.bg-theme-accent-alt.current { background-color: rgba(43, 201, 180, 0.7) !important; }

.bg-blend:before { background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #3c1c94); }

.bg-blend-alt:before { background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #6e19c2); }

.bg-blend-light { background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #f7f8ff); }

.bg-blend-light-alt { background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #eaeff6); }

.bg-rblend-light { background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #f7f8ff); }

.bg-rblend-light-alt { background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #eaeff6); }

.theme-dark { background: #180747 !important; }

.text-primary { color: #3c1c94 !important; }

.text-primary-alt { color: #6e19c2 !important; }

.text-theme-alt { color: #725bd6; }

.text-base { color: #5b53a2; }

/** 02.04 Misc */
.ttc { text-transform: capitalize !important; }

.ttu { text-transform: uppercase !important; }

.ttl { text-transform: lowercase !important; }

.ttn { text-transform: none !important; }

.fw-7 { font-weight: 700 !important; }

.fw-6 { font-weight: 600 !important; }

.fw-5 { font-weight: 500 !important; }

.fw-4 { font-weight: 400 !important; }

.fw-3 { font-weight: 300 !important; }

.fw-rg { font-weight: 400 !important; }

.fw-bold { font-weight: 700 !important; }

.fw-md { font-weight: 500 !important; }

.fw-lt { font-weight: 300 !important; }

.lh { line-height: 1; }

.lh-11 { line-height: 1.1; }

.lh-12 { line-height: 1.2; }

.lh-13 { line-height: 1.3; }

.lh-14 { line-height: 1.4; }

.lh-15 { line-height: 1.5; }

.lh-16 { line-height: 1.6; }

.lh-17 { line-height: 1.7; }

.lh-18 { line-height: 1.8; }

.lh-19 { line-height: 1.9; }

.lh-2 { line-height: 2; }

.ov-h, .nk-block-video, .nk-banner, .banner-wrap, .feature-group, .feature-img { overflow: hidden; }

.ov-xh { overflow-x: hidden; }

.ov-yh { overflow-y: hidden; }

.ov-s { overflow: scroll; }

.ov-xs { overflow-x: scroll; }

.ov-ys { overflow-y: scroll; }

.ov-v { overflow: visible !important; }

.ov-xv { overflow-x: visible !important; }

.ov-yv { overflow-y: visible !important; }

.bd { border: 1px solid #d1e0f6; }

.bd-2 { border-width: 2px; }

.bd-3 { border-width: 3px; }

.bdt { border-top: 1px solid #d1e0f6; }

.bdt-2 { border-top-width: 2px; }

.bdt-3 { border-top-width: 3px; }

.bdb { border-bottom: 1px solid #d1e0f6; }

.bdb-2 { border-bottom-width: 2px; }

.bdb-3 { border-bottom-width: 3px; }

.bdl { border-left: 1px solid #d1e0f6; }

.bdl-2 { border-left-width: 2px; }

.bdl-3 { border-left-width: 3px; }

.bdr { border-right: 1px solid #d1e0f6; }

.bdr-2 { border-right-width: 2px; }

.bdr-3 { border-right-width: 3px; }

.bd-dashed { border-style: dashed; }

.bd-dotted { border-style: dotted; }

.bd-double { border-style: double; }

.bd-light { border-color: #dbe4f7; }

.bd-lighter { border-color: #dbe4f7; }

.bd-lightest { border-color: #f4f7fd; }

.bd-dark { border-color: #394995; }

.bd-darker { border-color: #394995; }

.bd-darkest { border-color: #394995; }

.bd-primary { border-color: #866bfe; }

.bd-alternet { border-color: #a2b3cd; }

.bd-secondary { border-color: #3c1c94; }

.bd-info { border-color: #9ac4fd; }

.bd-warning { border-color: #fbe5a8; }

.bd-success { border-color: #b0efce; }

.bd-danger { border-color: #f5bfb7; }

.round, .token-stage { border-radius: 6px !important; }

.round-sm { border-radius: 4px !important; }

.round-md { border-radius: 10px !important; }

.round-lg { border-radius: 15px !important; }

.round-xl { border-radius: 44px !important; }

.round-full, .circle { border-radius: 50% !important; }

.round-full:before, .circle:before, .round-full:after, .circle:after { border-radius: 50% !important; }

.bdrs-1 { border-radius: 1px; }

.bdrs-2 { border-radius: 2px; }

.bdrs-3 { border-radius: 3px; }

.bdrs-4 { border-radius: 4px; }

.bdrs-5 { border-radius: 5px; }

.bdrs-6 { border-radius: 6px; }

.bdrs-7 { border-radius: 7px; }

.bdrs-8 { border-radius: 8px; }

.bdrs-9 { border-radius: 9px; }

.bdrs-1x { border-radius: 10px; }

.bdrs-2x { border-radius: 20px; }

.bdrs-3x { border-radius: 30px; }

.bdrs-4x { border-radius: 40px; }

.bdrs-5x { border-radius: 50px; }

.bdrs-15x { border-radius: 15px; }

.bdrs-25x { border-radius: 25px; }

.bdrs-35x { border-radius: 35px; }

.bdrs-45x { border-radius: 45px; }

.shadow { box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.08) !important; }

.shadow-alt { box-shadow: 0 8px 16px 0 rgba(91, 83, 162, 0.15); }

.shadow-soft {
  box-shadow: 0 5px 45px 0 rgb(91 83 162 / 8%);
  background: #ffffff1a;
}

.shadow-soft-sm { box-shadow: 0 2px 12px 0 rgba(91, 83, 162, 0.08); }

.shadow-dark { box-shadow: 0 5px 40px rgba(91, 83, 162, 0.15); }

.shadow-dark-sm { box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.15); }

.shadow-heavy { box-shadow: 0 5px 50px rgba(91, 83, 162, 0.25); }

.shadow-heavy-sm { box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.25); }

.nk-df-mask-a, .mask-a:before, .mask-a-bg { background-image: url(../images/mask-a.svg); }

.nk-df-mask-a-md, .mask-a-md:before, .mask-a-md-bg { background-image: url(../images/mask-a-md.svg); }

.nk-df-mask-a-dark, .mask-a-dark:before, .mask-a-dark-bg { background-image: url(../images/mask-a-dark.svg); }

.nk-df-mask-b, .mask-b:before, .mask-b-bg { background-image: url(../images/mask-b.svg); }

.nk-df-mask-c, .mask-c:before, .mask-c-bg { background-image: url(../images/mask-c.svg); }

.nk-df-mask-c-light, .mask-c-light:before, .mask-c-light-bg { background-image: url(../images/mask-c-light.svg); }

.nk-df-mask-c-dark, .mask-c-dark:before, .mask-c-dark-bg { background-image: url(../images/mask-c-dark.svg); }

.nk-df-mask-d, .mask-d:before, .mask-d-bg { background-image: url(../images/mask-d.svg); }

.nk-df-mask-d2, .mask-d2:before, .mask-d2-bg { background-image: url(../images/mask-d2.svg); }

.nk-df-mask-e, .mask-e:before, .mask-e-bg { background-image: url(../images/mask-e.svg); }

.nk-df-mask-f, .mask-f:before, .mask-f-bg { background-image: url(../images/mask-f.svg); }

.nk-df-mask-f-footer, .mask-f-footer:before, .mask-f-footer-bg { background-image: url(../images/mask-f-footer.svg); }

.mask-a:before, .mask-a-md:before, .mask-a-dark:before { background-position: bottom center !important; }

.mask-contain-bottom:before { background-size: contain; background-position: 50% 100%; }

.mask-c-blend { background-image: linear-gradient(180deg, #0d1b61 0%, rgba(0, 0, 0, 0) 100%) !important; }

.mask-c-blend-dark { background-image: linear-gradient(180deg, #0C0E24 0%, rgba(0, 0, 0, 0) 100%) !important; }

.mask-c-blend-light { background-image: linear-gradient(180deg, #f0f6ff 0%, rgba(0, 0, 0, 0) 100%) !important; }

.mask-f:before { left: 0 !important; }

.mask-f-footer:before { background-position: 50% -1px; }

.before-bottom:before { top: auto; }

.before-h10:before { height: 10%; }

.before-h20:before { height: 20%; }

.before-h30:before { height: 30%; }

.before-h40:before { height: 40%; }

.before-h50:before { height: 50%; }

.before-h60:before { height: 60%; }

.before-h70:before { height: 70%; }

.before-h80:before { height: 80%; }

.before-h90:before { height: 90%; }

.nk-df-shape-a, .shape-a:after, .shape-a-bg, .bg-shape-a { background-image: url(../images/shape-a.png); }

.nk-df-shape-a-sm, .shape-a-sm:after, .shape-a-sm-bg { background-image: url(../images/shape-a-sm.png); }

.nk-df-shape-b, .shape-b:after, .shape-b-bg, .bg-shape-b { background-image: url(../images/shape-b.png); }

.nk-df-shape-b-sm, .shape-b-sm:after, .shape-b-sm-bg { background-image: url(../images/shape-b-sm.png); }

.nk-df-shape-c, .shape-c:after, .shape-c-bg { background-image: url(../images/shape-c.png); }

.nk-df-shape-c-sm, .shape-c-sm:after, .shape-c-sm-bg { background-image: url(../images/shape-c-sm.png); }

.nk-df-shape-d, .shape-d:after, .shape-d-bg { background-image: url(../images/shape-d.png); }

.nk-df-shape-d-sm, .shape-d-sm:after, .shape-d-sm-bg { background-image: url(../images/shape-d-sm.png); }

.nk-df-shape-e, .shape-e:after, .shape-e-bg { background-image: url(../images/shape-e.png); }

.nk-df-shape-e-sm, .shape-e-sm:after, .shape-e-sm-bg { background-image: url(../images/shape-e-sm.png); }

.nk-df-shape-f, .shape-f:after, .shape-f-bg { background-image: url(../images/shape-f.png); }

.nk-df-shape-f-sm, .shape-f-sm:after, .shape-f-sm-bg { background-image: url(../images/shape-f-sm.png); }

.nk-df-shape-g, .shape-g:after, .shape-g-bg { background-image: url(../images/shape-g.png); }

.nk-df-shape-g-sm, .shape-g-sm:after, .shape-g-sm-bg { background-image: url(../images/shape-g-sm.png); }

.nk-df-shape-h, .shape-h:after, .shape-h-bg { background-image: url(../images/shape-h.png); }

.nk-df-shape-h-sm, .shape-h-sm:after, .shape-h-sm-bg { background-image: url(../images/shape-h-sm.png); }

.nk-df-shape-i, .shape-i:after, .shape-i-bg { background-image: url(../images/shape-i.png); }

.nk-df-shape-i-sm, .shape-i-sm:after, .shape-i-sm-bg { background-image: url(../images/shape-i-sm.png); }

.nk-df-shape-m, .shape-m:after, .shape-m-bg { background-image: url(../images/shape-m.png); }

.nk-df-shape-n, .shape-n:after, .shape-n-bg { background-image: url(../images/shape-n.png); }

.nk-df-shape-o, .shape-o:after, .shape-o-bg { background-image: url(../images/shape-o.png); }

/* .nk-df-shape-p, .shape-p:after, .shape-p-bg { background-image: url(../images/shape-p.png); } */
/* .nk-df-shape-p, .shape-p:after, .shape-p-bg { background-image: url(../../images/home/footer.png); }  */


.nk-df-shape-q, .shape-q:after, .shape-q-bg { background-image: url(../images/shape-q.png); }

.nk-df-shape-r, .shape-r:after, .shape-r-bg { background-image: url(../images/shape-r.png); }

.nk-df-shape-s, .shape-s:after, .shape-s-bg { background-image: url(../images/shape-s.png); }

.nk-df-shape-s-sm, .shape-s-sm:after, .shape-s-sm-bg { background-image: url(../images/shape-s-sm.png); }

.nk-df-shape-t, .shape-t:after, .shape-t-bg { background-image: url(../images/shape-t.png); }

.nk-df-shape-u, .shape-u:after, .shape-u-bg { background-image: url(../images/shape-u.png); }

.nk-df-shape-v, .shape-v:after, .shape-v-bg { background-image: url(../images/shape-v.png); }

.nk-df-shape-w, .shape-w:after, .shape-w-bg { background-image: url(../images/shape-w.png); }

.nk-df-shape-x, .shape-x:after, .shape-x-bg { background-image: url(../images/shape-x.png); }

.nk-df-shape-x-2, .shape-x-2:after, .shape-x-2-bg { background-image: url(../images/shape-x-2.png); }

.nk-df-shape-x-3, .shape-x-3:after, .shape-x-3-bg { background-image: url(../images/shape-x-3.png); }

.nk-df-shape-y, .shape-y:after, .shape-y-bg { background-image: url(../images/shape-y.png); }

.nk-df-shape-z, .shape-z:after, .shape-z-bg { background-image: url(../images/shape-z.png); }

.nk-df-shape-z2, .shape-z2:after, .shape-z2-bg { background-image: url(../images/shape-z2.png); }

.nk-df-shape-a2, .shape-a2:after, .shape-a2-bg { background-image: url(../images/shape-a2.png); }

.nk-df-shape-z3, .shape-z3:after, .shape-z3-bg { background-image: url(../images/shape-z3.png); }

.nk-df-shape-z4, .shape-z4:after, .shape-z4-bg { background-image: url(../images/shape-z4.png); }

.nk-df-shape-z6, .shape-z6:after, .shape-z6-bg { background-image: url(../images/shape-z6.png); }

.nk-df-shape-z6-sm, .shape-z6-sm:after, .shape-z6-sm-bg { background-image: url(../images/shape-z6-sm.png); }

.nk-df-shape-z7, .shape-z7:after, .shape-z7-bg { background-image: url(../images/shape-z7.png); }

.shape-contain:after { background-size: contain; }

.shape-center-top:after { background-position: 50% 0; }

.shape-center-bottom:after { background-position: 50% 100%; }

.shape-left-top:after { background-position: 0 0; }

.shape-q:after { background-size: contain; background-repeat: repeat; background-position: 0 50%; }

.shape-lg-60:after { background-size: 60%; }

.shape-z3:after { background-size: 100%; background-position: bottom center; }

.shape-x.nk-ovm:after, .shape-x.nk-ovm:before { left: 0 !important; }

.shape-a2:before { background-size: 100%; }

.nk-ovm { z-index: 1; }

.nk-ovm:before, .nk-ovm:after { bottom: -1px; left: -1px; }

.nk-ovm:before { z-index: 1; }

.nk-ovm-repeat:after { background-repeat: repeat; background-size: contain; }

.nk-ovm-fixed:after { background-attachment: fixed; }

.ovm-top { bottom: auto; }

.ovm-bottom { top: auto; }

.ovm-left { right: auto; }

.ovm-right { left: auto; }

.ovm-h-10 { height: 10%; }

.ovm-h-20 { height: 20%; }

.ovm-h-30 { height: 30%; }

.ovm-h-40 { height: 40%; }

.ovm-h-50 { height: 50%; }

.ovm-h-60 { height: 60%; }

.ovm-h-70 { height: 70%; }

.ovm-h-80 { height: 80%; }

.ovm-h-90 { height: 90%; }

.ovm-w-10 { width: 10%; }

.ovm-w-20 { width: 20%; }

.ovm-w-30 { width: 30%; }

.ovm-w-40 { width: 40%; }

.ovm-w-50 { width: 50%; }

.ovm-w-60 { width: 60%; }

.ovm-w-70 { width: 70%; }

.ovm-w-80 { width: 80%; }

.ovm-w-90 { width: 90%; }

.has-bg-image > div, .has-bg-video > div { position: relative; z-index: 1; }

.bg-fixed { background-attachment: fixed; }

.bg-image { position: absolute !important; z-index: 0 !important; opacity: 0; transition: opacity .4s; }

.bg-image.bg-image-loaded { opacity: 1; }

.bg-image img { display: none !important; }

.bg-pattern-dots-color { background-image: url(../images/dot-a.png); background-size: contain; }

.bg-pattern-dots { background-image: url(../images/dot-b.png); background-size: contain; background-repeat: repeat-y; }

.bg-contain { background-size: contain; }

.bg-bottom-center { background-position: 50% 100%; }

.bg-shape-b { background-position: 50% 100%; }

.bg-video { position: absolute !important; z-index: 0 !important; overflow: hidden; }

.bg-video-cover { opacity: 0; }

.cover-enabled .bg-video-cover { opacity: 1; }

.bg-video .ytplayer-container { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.bg-video .ytplayer-container.cover-enabled .bg-video .ytplayer-container { display: none; }

.cover-enabled .ytplayer-container { display: none; }

.bg-image.bp-l0, .has-bg.bp-l0 { background-position: 0%; }

.bg-image.bp-r0, .has-bg.bp-r0 { background-position: 0%; }

.bg-image.bp-l10, .has-bg.bp-l10 { background-position: 10%; }

.bg-image.bp-r10, .has-bg.bp-r10 { background-position: 40%; }

.bg-image.bp-l20, .has-bg.bp-l20 { background-position: 20%; }

.bg-image.bp-r20, .has-bg.bp-r20 { background-position: 30%; }

.bg-image.bp-l25, .has-bg.bp-l25 { background-position: 25%; }

.bg-image.bp-r25, .has-bg.bp-r25 { background-position: 25%; }

.bg-image.bp-l30, .has-bg.bp-l30 { background-position: 30%; }

.bg-image.bp-r30, .has-bg.bp-r30 { background-position: 20%; }

.bg-image.bp-l40, .has-bg.bp-l40 { background-position: 40%; }

.bg-image.bp-r40, .has-bg.bp-r40 { background-position: 10%; }

.overlay:after { content: ''; }

.overlay-light:after { background: #fff; }

.overlay-dark:after { background: #000; }

.overlay-theme:after { background: #3c1c94; }

.overlay-theme-dark:after { background: #180747; }

.overlay-opacity-10:after { opacity: 0.1; }

.overlay-opacity-15:after { opacity: 0.15; }

.overlay-opacity-20:after { opacity: 0.2; }

.overlay-opacity-25:after { opacity: 0.25; }

.overlay-opacity-30:after { opacity: 0.3; }

.overlay-opacity-35:after { opacity: 0.35; }

.overlay-opacity-40:after { opacity: 0.4; }

.overlay-opacity-45:after { opacity: 0.45; }

.overlay-opacity-50:after { opacity: 0.5; }

.overlay-opacity-55:after { opacity: 0.55; }

.overlay-opacity-60:after { opacity: 0.6; }

.overlay-opacity-65:after { opacity: 0.65; }

.overlay-opacity-70:after { opacity: 0.7; }

.overlay-opacity-75:after { opacity: 0.75; }

.overlay-opacity-80:after { opacity: 0.8; }

.overlay-opacity-85:after { opacity: 0.85; }

.overlay-opacity-90:after { opacity: 0.9; }

.overlay-opacity-95:after { opacity: 0.95; }

.overlay-opacity-100:after { opacity: 1; }

.op-10 { opacity: 0.1; }

.op-20 { opacity: 0.2; }

.op-30 { opacity: 0.3; }

.op-40 { opacity: 0.4; }

.op-50 { opacity: 0.5; }

.op-60 { opacity: 0.6; }

.op-70 { opacity: 0.7; }

.op-80 { opacity: 0.8; }

.op-90 { opacity: 0.9; }

.op-100 { opacity: 1; }

.op-15 { opacity: 0.15; }

.op-25 { opacity: 0.25; }

.op-35 { opacity: 0.35; }

.op-45 { opacity: 0.45; }

.op-55 { opacity: 0.55; }

.op-65 { opacity: 0.65; }

.op-75 { opacity: 0.75; }

.op-85 { opacity: 0.85; }

.op-95 { opacity: 0.95; }

.alignright, .alignleft, .aligncenter, .alignnone { display: block; padding: 0; margin: 15px auto; }

@media (min-width: 576px) { .alignright, .alignleft, .aligncenter, .alignnone { margin: 0 0 15px; display: inline; }
  .alignright { margin-left: 20px; float: right; }
  .alignleft { margin-right: 20px; float: left; }
  .alignnone { margin-right: 20px; }
  .aligncenter { display: block; margin: 15px auto; } }

.wide, .wide-rg, .wide-auto, .wide-auto-rg { max-width: 750px; }

.wide-auto, .wide-auto-rg, .wide-auto-xl, .wide-auto-lg, .wide-auto-md, .wide-auto-sm, .wide-auto-xs { margin: 0 auto; }

.wide-xl, .wide-auto-xl { max-width: 1560px; }

.wide-lg, .wide-auto-lg { max-width: 1140px; }

.wide-md, .wide-auto-md { max-width: 945px; }

.wide-sm, .wide-auto-sm { max-width: 555px; }

.wide-xs, .wide-auto-xs { max-width: 360px; }

.wide-none, .wide-auto-none { max-width: inherit; }

.text-center [class*="wide"], .text-center[class*="wide"] { margin-left: auto; margin-right: auto; }

.over-up, [class*="over-up-"] { z-index: 9; }

.over-up { margin-top: -175px; }

.over-up-sm { margin-top: -45px; }

.over-up-md { margin-top: -75px; }

.over-up-lg { margin-top: -165px; }

.over-up-xl { margin-top: -275px; }

.over-up-s2 { margin-top: -70px; }

.over-lg-up { margin-top: 0; }

.over-up-xl-s2 { margin-top: 0; }

@media (min-width: 768px) { .over-up { margin-top: -190px; }
  .over-up-sm { margin-top: -75px; }
  .over-up-md { margin-top: -125px; }
  .over-up-lg { margin-top: -275px; }
  .over-up-xl { margin-top: -350px; }
  .over-up-s2 { margin-top: -50px; } }

@media (min-width: 992px) { .over-lg-up { margin-top: -80px !important; }
  .over-up-xl-s2 { margin-top: -230px; }
  .nk-ovm-inner { position: absolute; right: 0; top: 0; width: 30%; background: #f7f8ff; height: 100%; z-index: 1; } }

@media (min-width: 1200px) { .over-lg-up { margin-top: -100px !important; }
  .over-up-xl-s2 { margin-top: -230px; } }

@media (min-width: 1600px) { .over-lg-up { margin-top: -160px !important; }
  .over-up-xl-s2 { margin-top: -295px; } }

.nk-ovm .nk-svgs { z-index: 3; }

.nk-svgs svg { height: 100%; width: auto; }

.nk-svgs.svg-loaded svg { display: none; }

hr { position: relative; z-index: 5; }

.hr { border-top: 1px solid #dbe4f7; }

.hr-white { background-color: #fff; }

.hr-white-5 { background-color: rgba(255, 255, 255, 0.05); }

.hr-white-10 { background-color: rgba(255, 255, 255, 0.4); }

.hr-white-15 { background-color: rgba(255, 255, 255, 0.01); }

.hr-black { background-color: #000; }

.hr-black-5 { background-color: rgba(0, 0, 0, 0.2); }

.hr-black-10 { background-color: rgba(0, 0, 0, 0.1); }

.hr-black-15 { background-color: rgba(0, 0, 0, 0.15); }

.h-110 { height: 110% !important; }

.h-115 { height: 115% !important; }

.h-120 { height: 120% !important; }

.h-125 { height: 125% !important; }

.h-130 { height: 130% !important; }

.ui-shape { position: absolute; top: 0; left: 0; height: 100%; width: 100%; left: 50%; overflow: hidden; transform: translateX(-50%); z-index: -1; }

.ui-shape:after, .ui-shape:before { position: absolute; content: ''; height: 210px; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; transition: all .4s; }

.ui-shape:before { top: 15%; left: -4%; width: 100px; background-image: url(../images/ui-shape-a.svg); }

.ui-shape:after { bottom: 10%; right: -4%; width: 90px; background-image: url(../images/ui-shape-b.svg); }

.ui-shape-light:before { background-image: url(../images/ui-shape-light-a.svg); }

.ui-shape-light:after { background-image: url(../images/ui-shape-light-b.svg); }

.ui-shape-s1:after { transform: rotate(60deg); }

.ui-shape-s3:before { top: auto; bottom: 20%; }

.ui-shape-s3:after { top: 10%; bottom: auto; }

@media (min-width: 576px) { .ui-shape:before { width: 140px; }
  .ui-shape:after { width: 120px; } }

@media (min-width: 768px) { .ui-shape-s7:after { bottom: 15%; right: 16%; }
  .ui-shape-s7:before { top: 6%; left: 22%; }
  .ui-shape-s6:after { bottom: 9%; right: 0%; }
  .ui-shape-s6:before { top: 9%; left: 13.3%; }
  .ui-shape-s5:after { right: 4%; bottom: 15%; }
  .ui-shape-s4:before { left: 20%; top: 23.5%; }
  .ui-shape-s3:after { right: 4%; }
  .ui-shape-s2:before { top: 8%; left: 21%; }
  .ui-shape-s1:after { right: 0; }
  .ui-shape-header:before { top: 22%; left: 9%; }
  .ui-shape-header:after { right: 15%; bottom: 32%; }
  .ui-shape-footer:before { top: 2%; }
  .ui-shape-footer:after { bottom: -11%; } }

@media (min-width: 992px) { .ui-shape { width: 960px; overflow: visible; }
  .ui-shape:after { width: 166px; }
  .ui-shape:before { width: 190px; }
  .ui-shape-s5:after { right: -2.5%; }
  .ui-shape-s3:before { top: auto; bottom: 20%; }
  .ui-shape-header:before { left: 5%; }
  .ui-shape-header:after { right: 3%; bottom: 22%; } }

@media (min-width: 1200px) { .ui-shape { width: 1170px; }
  .ui-shape-header:before { left: 2%; }
  .ui-shape-header:after { right: -2.5%; bottom: 33%; } }

.overlay-bars { position: absolute; left: 0; top: 0; height: 100%; width: 100%; overflow: hidden; }

.overlay-bars div { position: absolute; background: rgba(255, 255, 255, 0.03); transform: rotate(-15deg); }

.overlay-bars.reverse { transform: scaleX(-1); }

.overlay-bar-1 { height: 140%; width: 300px; left: calc(50% - 210px); top: -20%; }

.overlay-bar-2 { height: 100%; width: 160px; left: calc(50% - 170px); bottom: calc(50% + 155px); }

.overlay-bar-3 { height: 100%; width: 230px; left: calc(50% - 90px); top: calc(50% + 280px); }

.overlay-bar-4 { height: 140%; width: 300px; left: calc(50% - 860px); top: -20%; transform: rotate(-15deg); }

.overlay-bar-5 { height: 140%; width: 160px; left: calc(50% - 910px); top: -20%; }

.overlay-bar-6 { height: 140%; width: 300px; left: calc(50% + 530px); top: -20%; }

.overlay-bar-7 { height: 140%; width: 300px; left: calc(50% + 670px); top: -20%; }

.overlay-x { position: relative; }

.overlay-x:before, .overlay-x:after { position: absolute; bottom: 0; content: ''; width: 300px; z-index: 1; }

.overlay-x:before { left: 50%; margin-left: -125px; height: 800px; background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 40%, rgba(255, 255, 255, 0.05) 100%); transform: rotate(-60deg); transform-origin: bottom right; }

.overlay-x:after { right: 50%; margin-right: -125px; height: 3600px; background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 10%, rgba(255, 255, 255, 0.05) 100%); transform: rotate(60deg); transform-origin: bottom right; }

.background-shape { background: rgba(0, 52, 86, 0.03); position: absolute; height: 100%; width: 100%; content: ''; top: 0; left: 50%; transform: skewX(33deg); transform-origin: left bottom; }

.tc-light .background-shape { background: rgba(0, 0, 0, 0.03); }

.background-shape:before, .background-shape:after { background: rgba(255, 255, 255, 0.2); position: absolute; height: 100%; width: 100%; content: ''; top: 0; }

.tc-light .background-shape:before, .tc-light .background-shape:after { background: rgba(0, 0, 0, 0.05); }

.background-shape:before { left: 280px; }

.background-shape:after { left: 560px; }

.background-shape.bs-right { left: 70%; }

.background-shape.bs-reverse { right: 70%; left: auto; transform: skewX(-33deg); transform-origin: right bottom; }

.background-shape.bs-reverse:before { left: auto; right: 280px; }

.background-shape.bs-reverse:after { left: auto; right: 560px; }

.background-shade { position: absolute; height: 100%; width: 100%; top: 0; left: 0; }

.background-shade .container { height: 100vh; position: relative; }

.background-shade-left, .background-shade-right { position: absolute; top: 160px; width: 50%; height: calc(100% - 130px); opacity: .15; }

.background-shade-left:after, .background-shade-right:after { position: absolute; top: 40px; width: 100%; height: 100%; content: ''; }

.background-shade-left { left: -80px; border-radius: 30px 0 0 0; background-image: linear-gradient(to bottom right, #000 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 100%); transform: rotate(-12deg); }

.tc-light .background-shade-left { background-image: linear-gradient(to bottom right, #fff 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%); }

.background-shade-left:after { left: 40px; border-radius: 30px 0 0 0; background-image: linear-gradient(to bottom right, #000 0%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%); }

.tc-light .background-shade-left:after { background-image: linear-gradient(to bottom right, #fff 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0) 100%); }

.background-shade-right { right: -80px; border-radius: 0 30px 0 0; background-image: linear-gradient(to bottom left, #000 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 100%); transform: rotate(12deg); }

.tc-light .background-shade-right { background-image: linear-gradient(to bottom left, #fff 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%); }

.background-shade-right:after { right: 40px; border-radius: 0 30px 0 0; background-image: linear-gradient(to bottom left, #000 0%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%); }

.tc-light .background-shade-right:after { background-image: linear-gradient(to bottom left, #fff 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0) 100%); }

.header-bubbles { position: absolute; left: 0; top: -100%; height: 200%; width: 100%; content: ''; z-index: 1; overflow: hidden; }

.header-bubbles-single { position: absolute; top: 0; left: 0; height: 100%; width: 100%; }

.header-bubbles-single div { position: absolute; border-radius: 50%; background: rgba(255, 255, 255, 0.05); }

.header-bubbles-single-1 { height: 735px; width: 735px; right: calc(50% - 65px); top: calc(50% + 10px); }

.header-bubbles-single-2 { height: 680px; width: 680px; right: calc(50% + 360px); top: calc(50% - 145px); }

.header-bubbles-single-3 { height: 350px; width: 350px; right: calc(50% - 95px); top: calc(50% + 540px); }

.header-bubbles-single-4 { height: 650px; width: 650px; left: calc(50% + 435px); top: calc(50% - 240px); }

.header-bubbles-single-5 { height: 350px; width: 350px; left: calc(50% + 490px); top: calc(50% + 240px); }

.header-bubbles-single-6 { height: 440px; width: 440px; left: calc(50% + 685px); top: calc(50% + 240px); }

.banner-rounded-bg { position: absolute; right: 390px; top: -750px; width: 120%; height: 1350px; z-index: 5; border-radius: 0 0 250px 0; transform: rotate(-8deg) skew(10deg); transform-origin: top right; padding: 0 120px 110px 0; overflow: hidden; }

.banner-rounded-bg .particles-container { transform: rotate(8deg) skew(-10deg); transform-origin: top right; }

.banner-shade-1, .banner-shade-2, .banner-shade-3 { display: block; height: 100%; width: 100%; }

.banner-shade-1 { border-radius: 0 0 160px 0; background-image: linear-gradient(to top left, #fff 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%); opacity: .15; padding: 0 100px 90px 0; }

.banner-shade-2 { border-radius: 0 0 110px 0; background-image: linear-gradient(to top left, #fff 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 100%); padding: 0 90px 80px 0; }

.banner-shade-3 { border-radius: 0 0 80px 0; background-image: linear-gradient(to top left, #fff 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 100%); }

@media (max-width: 1400px) { .banner-rounded-bg { right: 330px; } }

.nk-pages-angled .section { z-index: inherit; }

.section-bg-angle { position: absolute !important; left: 0; top: 0; height: 100%; width: 100%; content: ''; transform: skewY(10deg); z-index: 1; }

.section-bg-angle ~ .container, .section-bg-angle ~ .nk-block { position: relative; z-index: 5; }

.section-angle-top:before { position: absolute; left: 0; top: 0; height: 450px; width: 100%; content: ''; background: #eaeff6; z-index: 1; }

.section-angle-top:after { position: absolute; left: 0; bottom: -450px; height: 450px; width: 100%; content: ''; background: #eaeff6; z-index: 1; }

.section-angle-bottom:before { position: absolute; left: 0; top: -450px; height: 450px; width: 100%; content: ''; background: #fff; z-index: 2; }

@media (min-width: 992px) { .section-bg-angle { transform: skewY(17deg); }
  .section-bg-angle-s2 { transform: skewY(-4deg); } }

.ui-mask, [class*=ui-mask] { position: absolute !important; left: 0; top: 0; width: 400px; height: 600px; z-index: 1; overflow: hidden; }

.ui-mask:after, [class*=ui-mask]:after { position: absolute; right: 30%; top: 25%; content: ""; display: block; background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #1c62d8 50%, rgba(110, 25, 194, 0) 80%); border-radius: 50%; height: 400px; width: 400px; -webkit-filter: blur(80px); filter: blur(80px); z-index: 1; }

.ui-mask:before, [class*=ui-mask]:before { display: none; content: ""; position: absolute; left: 0; right: 0; bottom: 0; top: 0; background-repeat: no-repeat; background-size: contain; background-position: 0 50%; z-index: 3; }

.bg-white .ui-mask:after, .bg-theme-light .ui-mask:after, .bg-theme-light-alt .ui-mask:after, .bg-white [class*=ui-mask]:after, .bg-theme-light [class*=ui-mask]:after, .bg-theme-light-alt [class*=ui-mask]:after { display: none; }

.ui-mask-right { left: auto; right: 0; }

.ui-mask-right:after { right: auto; left: 30%; }

.ui-mask-right:before { background-position: 100% 50%; }

.ui-mask-s2 { width: 420px; height: 900px; transform: translateY(-30%); }

.ui-mask-s2:after { background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #28298a 50%, rgba(110, 25, 194, 0) 80%); }

.ui-mask-s2:before { display: block; background-image: url(../images/mask-line-a.png); }

.ui-mask-s2.ui-mask-left:before { transform: rotate(-180deg); }

.ui-mask-s3 { width: 600px; height: 1400px; transform: translateY(0); }

.ui-mask-s3:after { height: 650px; width: 400px; right: 45%; background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #102486 50%, rgba(110, 25, 194, 0) 80%); }

.ui-mask-s3:before { display: block; background-image: url(../images/mask-line-b.png); }

.ui-mask-s3.ui-mask-right:before { transform: rotate(180deg); }

.ui-mask-s4 { width: 420px; height: 820px; transform: translateY(-20%); }

.ui-mask-s4:after { height: 510px; background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #1d5ecf 50%, rgba(110, 25, 194, 0) 80%); }

.ui-mask-s5 { width: 440px; height: 1000px; transform: translateY(12%); }

.ui-mask-s5:after { height: 600px; width: 350px; right: 45%; background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #1b5ed1 50%, rgba(110, 25, 194, 0) 80%); }

.ui-mask-s5:before { display: block; background-image: url(../images/mask-line-c.png); }

.ui-mask-s5.ui-mask-right:before { transform: rotate(180deg); }

.ui-mask-s6 { width: 440px; height: 1000px; transform: translateY(2%); }

.ui-mask-s6:after { height: 410px; width: 500px; left: 30%; background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #142692 50%, rgba(110, 25, 194, 0) 80%); }

.ui-mask-s6:before { display: block; background-image: url(../images/mask-line-d.png); }

.ui-mask-s6.ui-mask-left:before { transform: rotate(180deg); }

.ui-mask-s7 { width: 870px; height: 800px; bottom: -20%; left: 50%; margin-left: -150px; top: auto; transform: translateY(0); }

.ui-mask-s7:after { height: 710px; width: 600px; right: 0; top: 15%; transform: translateX(-25%); background-image: linear-gradient(135deg, rgba(110, 25, 194, 0) 15%, #3762d3 50%, rgba(110, 25, 194, 0) 80%); }

.ui-mask-s7.ui-mask-left:before { transform: rotate(180deg); }

.ui-mask-s8 { width: 350px; height: 392px; }

.ui-mask-s8:before { display: block; background-image: url(../images/polygon-line-a.png); }

.ui-mask-s8:after { display: none; }

.ui-mask-s9 { width: 417px; height: 733px; }

.ui-mask-s9:before { display: block; top: -31px; background-image: url(../images/polygon-line-b.png); }

.ui-mask-s9:after { display: none; }

.ui-mask-s10 { width: 417px; height: 733px; }

.ui-mask-s10:before { display: block; top: -31px; background-image: url(../images/polygon-line-a-fill.png); }

.ui-mask-s10:after { display: none; }

.ui-mask-s11 { width: 384px; height: 733px; }

.ui-mask-s11:before { display: block; top: -31px; background-image: url(../images/polygon-line-c.png); }

.ui-mask-s11:after { display: none; }

.ui-mask-s11.ui-mask-right:before { transform: rotateY(180deg); }

.ui-mask-s12 { width: 80px; height: 270px; }

.ui-mask-s12:before { display: block; top: 50px; background-image: url(../images/polygon-fill.png); }

.ui-mask-s12:after { display: none; }

@media only screen and (min-width: 767px) and (max-width: 1024px) { .ui-mask:before, [class*=ui-mask]:before { background-size: 50%; } }

@media only screen and (max-width: 767px) { .ui-mask, [class*=ui-mask] { transform: scale(0.5); transform-origin: right; }
  .ui-mask-left { transform-origin: left; } }

/** 03. Elements */
/** 03.01 Alert */
.alert { position: relative; font-weight: 400; color: #fff; padding: 0.75rem 1.25rem; border: none; }

.alert:last-child { margin-bottom: 0; }

.alert a, .alert .alert-link { color: inherit; font-weight: inherit; box-shadow: 0 1px 0 currentColor; transition: box-shadow .3s; }

.alert a:hover, .alert .alert-link:hover { box-shadow: 0 0 0; }

.alert .close { color: inherit; transition: all .4s; height: 30px; width: 30px; padding: 0; background: none; position: absolute; top: 50%; border: none; border-radius: 50%; transform: translateY(-50%); right: 10px; text-shadow: none; opacity: .7; }

.alert .close:not(:disabled):not(.disabled):focus, .alert .close:not(:disabled):not(.disabled):hover, .alert .close:hover, .alert .close:focus { color: currentColor; box-shadow: none; outline: none; opacity: 1; }

.alert .close:after { position: absolute; font-family: 'themify'; content: '\e646'; top: 0; left: 0; color: currentColor; font-size: 12px; line-height: 30px; text-align: center; width: 100%; }

.alert .close span { display: none; }

.alert-primary { color: #3306fd; background: white; }

.alert-primary .close { background: #d9d0ff; }

.alert-primary-alt { background: #866bfe; }

.alert-primary-alt .close { background: #481ffd; }

.alert-secondary { color: #197569; background: #aaede4; }

.alert-secondary .close { background: #b7f0e8; }

.alert-secondary-alt { background: #2bc9b4; }

.alert-secondary-alt .close { background: #1e8a7c; }

.alert-success { color: #29cf77; background: #cef5e1; }

.alert-success .close { background: #64e09e; }

.alert-success-alt { background: #39d884; }

.alert-success-alt .close { background: #25b96b; }

.alert-danger { color: #e6604a; background: #f9d6d0; }

.alert-danger .close { background: #f2afa4; }

.alert-danger-alt { background: #e6604a; }

.alert-danger-alt .close { background: #9b2815; }

.alert-info { color: #026bfb; background: #cae0ff; }

.alert-info .close { background: #65a6fe; }

.alert-info-alt { background: #026bfb; }

.alert-info-alt .close { background: #014096; }

.alert-warning { color: #e0a609; background: #fef4db; }

.alert-warning .close { background: #f9d46f; }

.alert-warning-alt { background: #f6be26; }

.alert-warning-alt .close { background: #e0a609; }

/** 03.02 Buttons */
.btn { position: relative; font-size: 12px; line-height: 24px; font-weight: 500; padding: 8px 15px; text-align: center; text-transform: uppercase; color: #fff; min-width: 120px; max-width: 100%;  box-sizing: border-box; transition: all 0.5s; position: relative; border-radius: 4px; z-index: 2; display: inline-flex; align-items: center; justify-content: center; white-space: nowrap; cursor: pointer; }

.btn .icon { color: inherit; }

.btn:hover, .btn:focus { color: #fff !important; }

/* .btn-grad { border: none; background-image: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); } */
.btn-grad{
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
}

.btn-grad:before { position: absolute; left: 0; top: 0; content: ""; width: 100%; height: 100%; z-index: -2; border-radius: 5px; opacity: 0; transition: all .5s; background-image: linear-gradient(to right, #2bc9b4 0%, #866bfe 100%); }

.btn-grad:hover:before, .btn-grad:focus:before { opacity: 1; }

.btn-grad:hover, .btn-grad:focus { color: #fff !important; }

.btn-grad-alt { background-image: linear-gradient(to right, #2bc9b4 0%, #866bfe 100%); }

.btn-grad-alt:before { background-image: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); }

.btn-grad-alternet { background-image: linear-gradient(to right, #725bd6 0%, #866bfe 100%); }

.btn-grad-alternet:before { background-image: linear-gradient(to right, #866bfe 0%, #725bd6 100%); }

.btn-grad-alternet-alt { background-image: linear-gradient(to right, #866bfe 0%, #725bd6 100%); }

.btn-grad-alternet-alt:before { background-image: linear-gradient(to right, #725bd6 0%, #866bfe 100%); }

.btn-grad-theme { background-image: linear-gradient(to right, #3c1c94 0%, #6e19c2 100%); }

.btn-grad-theme:before { background-image: linear-gradient(to right, #6e19c2 0%, #3c1c94 100%); }

.btn-grad-theme-alt { background-image: linear-gradient(to right, #2bc9b4 0%, #866bfe 100%); }

.btn-grad-theme-alt:before { background-image: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); }

.btn:hover, .btn:focus { outline: none !important; box-shadow: none !important; }

.btn span { position: relative; z-index: 999999; display: block; }

.btn span + .icon { margin-left: 10px; }

.btn .icon + span { margin-left: 10px; }

.btn .badge { position: absolute; top: 0; right: 0; transform: translate(50%, -50%); }

.btn-md { font-size: 12px; line-height: 24px; padding: 10px 26px; }

.btn-rg { line-height: 24px; padding: 8px 20px; }

.btn-sm { font-size: 11px; line-height: 23px; padding: 8px 20px; }

.btn-xxs { font-size: 12px; line-height: 21px; padding: 0 10px; border-width: 1px; border-radius: 2px; }

.btn-auto { min-width: 44px !important; }

.btn-auto-lg { min-width: 80px !important; height: 100%; }

.btn-grp { display: inline-flex; flex-wrap: wrap; align-items: center; margin: -7px; }

.btn-grp > div, .btn-grp > li { padding: 7px; }

.btn-grp-break > div, .btn-grp-break > li { width: 100%; text-align: center; }

.text-center .btn-grp { justify-content: center; }

.btn-download { display: inline-block; height: 40px; }

.btn-grp-s2 .btn-download { width: 100px; height: 35px; }

.btn-download img { height: 100%; }

.btn-outline:not(:hover), .btn-outline:not(:focus) { color: #415076; background-color: transparent !important; }

.btn-outline:before { background-image: none; background-color: #fff; opacity: 1; margin: 2px; width: calc(100% - 4px); height: calc(100% - 4px); }

.btn-outline.on-bg-light:before { background-color: #f7f8ff; }

.btn-outline.on-bg-light-alt:before { background-color: #eaeff6; }

.btn-outline.on-bg-theme, .btn-outline.on-bg-theme-alt, .btn-outline.on-bg-theme-dark, .btn-outline.on-bg-theme-dark-alt { color: #fff; }

.btn-outline.on-bg-theme:before { background-color: #3c1c94; }

.btn-outline.on-bg-theme-alt:before { background-color: #6e19c2; }

.btn-outline.on-bg-theme-dark:before { background-color: #180747; }

.btn-outline.on-bg-theme-dark-alt:before { background-color: #2e186a; }

.btn-outline:hover, .btn-outline:focus { color: #fff !important; }

.btn-outline:hover:before, .btn-outline:focus:before { opacity: 0; }

.btn-outline.btn-grad { border: none; }

.btn-outline.btn-grad:hover, .btn-outline.btn-grad:focus { color: #000 !important; }

.btn-icon { min-width: 44px; padding-left: 6px; padding-right: 6px; }

.btn-primary { background-color: #866bfe; border-color: #866bfe; }

.btn-primary:hover, .btn-primary:focus { background-color: #2bc9b4; border-color: #2bc9b4; }

.btn-primary:hover:focus, .btn-primary:focus:focus { color: #fff !important; }

.tc-light .btn-primary:hover, .tc-light .btn-primary:focus { background-color: #2bc9b4; border-color: #2bc9b4; color: #fff !important; }

.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus { background-color: #866bfe !important; border-color: #866bfe; color: #fff !important; }

.btn-secondary { background-color: #2bc9b4; border-color: #2bc9b4; }

.btn-secondary:hover, .btn-secondary:focus { background-color: #866bfe; border-color: #866bfe; }

.btn-secondary.focus:focus { color: #fff !important; }

.btn-secondary.btn-outline:hover, .btn-secondary.btn-outline:focus { background-color: #2bc9b4 !important; border-color: #2bc9b4; }

.btn-info { background-color: #026bfb; border-color: #026bfb; }

.btn-info:hover, .btn-info:focus { background-color: #014baf; border-color: #014baf; }

.btn-info.btn-outline:hover, .btn-info.btn-outline:focus { background-color: #026bfb !important; border-color: #026bfb; }

.btn-warning { background-color: #f6be26; border-color: #f6be26; }

.btn-warning:hover, .btn-warning:focus { background-color: #c79408; border-color: #c79408; }

.btn-warning.btn-outline:hover, .btn-warning.btn-outline:focus { background-color: #f6be26 !important; border-color: #f6be26; }

.btn-success { background-color: #39d884; border-color: #39d884; }

.btn-success:hover, .btn-success:focus { background-color: #20a45f; border-color: #20a45f; }

.btn-success.btn-outline:hover, .btn-success.btn-outline:focus { background-color: #39d884 !important; border-color: #39d884; }

.btn-danger { background-color: #e6604a; border-color: #e6604a; }

.btn-danger:hover, .btn-danger:focus { background-color: #c8341c; border-color: #c8341c; }

.btn-danger.btn-outline:hover, .btn-danger.btn-outline:focus { background-color: #e6604a !important; border-color: #e6604a; }

.btn-dark { background-color: #415076; border-color: #415076; }

.btn-dark:hover, .btn-dark:focus { background-color: #1d2334; border-color: #1d2334; }

.btn-dark.btn-outline:hover, .btn-dark.btn-outline:focus { background-color: #415076 !important; border-color: #415076; }

.btn-theme-dark { background-color: rgba(0, 0, 0, 0.2); border-color: rgba(0, 0, 0, 0); }

.btn-theme-dark:hover, .btn-theme-dark:focus { background-color: #180747; border-color: #180747; }

.btn-light { background-color: #f7f8ff; border-color: #f7f8ff; color: #415076 !important; }

.btn-light:hover, .btn-light:focus { background-color: #c4cbff; border-color: #c4cbff; color: #415076 !important; }

.btn-light.btn-outline { background-color: #f7f8ff; border-color: #f7f8ff; }

.btn-light.btn-outline:hover, .btn-light.btn-outline:focus { background-color: #f7f8ff !important; border-color: #f7f8ff; color: #415076 !important; }

.tc-light .btn-light.btn-outline { color: #fff !important; }

.tc-light .btn-light.btn-outline:hover, .tc-light .btn-light.btn-outline:focus { color: #415076 !important; }

.btn-light.tc-light:not(:hover) { color: #fff !important; }

.btn-facebook { background-color: #3b5998; border-color: #3b5998; }

.btn-facebook:hover, .btn-facebook:focus { background-color: #2d4373; border-color: #2d4373; }

.btn-facebook.btn-outline .icon { color: #3b5998; }

.btn-facebook.btn-outline:hover, .btn-facebook.btn-outline:focus { background-color: #3b5998 !important; border-color: #3b5998; }

.btn-facebook.btn-outline:hover .icon, .btn-facebook.btn-outline:focus .icon { color: #fff; }

.btn-google { background-color: #d85040; border-color: #d85040; }

.btn-google:hover, .btn-google:focus { background-color: #be3727; border-color: #be3727; }

.btn-google.btn-outline .icon { color: #d85040; }

.btn-google.btn-outline:hover, .btn-google.btn-outline:focus { background-color: #d85040 !important; border-color: #d85040; }

.btn-google.btn-outline:hover .icon, .btn-google.btn-outline:focus .icon { color: #fff; }

.btn-play { position: relative; color: #fff; height: 60px; width: 60px; font-size: 18px; text-align: center; line-height: 58px; border-radius: 50%; display: inline-block; border: 1px solid currentColor; }

.btn-play-sm { height: 40px; width: 40px; line-height: 38px; }

.btn-play:after { position: absolute; content: ""; height: calc(100% + 16px); width: calc(100% + 16px); top: -8px; left: -8px; border-radius: 50%; border: 1px solid rgba(65, 80, 118, 0.35); animation: 1s ease 0s normal none infinite running playbutton; }

.tc-light .btn-play:after { border-color: rgba(255, 255, 255, 0.35); }

.btn-play:hover, .btn-play:focus { color: #2bc9b4 !important; }

.btn-play-s2 { background-image: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); border: none; }

.btn-play-s2:before { position: absolute; height: calc(100% - 4px); width: calc(100% - 4px); top: 2px; left: 2px; content: ''; background: #fff; border-radius: 50%; z-index: 0; }

.bg-light .btn-play-s2:before { background: #f7f8ff; }

.bg-light-alt .btn-play-s2:before { background: #eaeff6; }

.bg-theme .btn-play-s2:before { background: #3c1c94; }

.bg-theme-alt .btn-play-s2:before { background: #6e19c2; }

.bg-theme-dark .btn-play-s2:before { background: #180747; }

.bg-theme-dark-alt .btn-play-s2:before { background: #2e186a; }

.bg-white .btn-play-s2:before { background: #fff; }

.btn-play-s3 { background-color: #fff; border: none; }

.btn-play-icon { height: 20px; width: 14px; background: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); display: block; position: absolute; top: 50%; left: 50%; transform: translate(-40%, -50%); }

.btn-play-icon:before, .btn-play-icon:after { position: absolute; content: ''; height: 0; width: 0; right: -1px; border-right: 14px solid #fff; }

.bg-light .btn-play-icon:before, .bg-light .btn-play-icon:after { border-right-color: #f7f8ff; }

.bg-light-alt .btn-play-icon:before, .bg-light-alt .btn-play-icon:after { border-right-color: #eaeff6; }

.bg-theme .btn-play-icon:before, .bg-theme .btn-play-icon:after { border-right-color: #3c1c94; }

.bg-theme-alt .btn-play-icon:before, .bg-theme-alt .btn-play-icon:after { border-right-color: #6e19c2; }

.bg-theme-dark .btn-play-icon:before, .bg-theme-dark .btn-play-icon:after { border-right-color: #180747; }

.bg-theme-dark-alt .btn-play-icon:before, .bg-theme-dark-alt .btn-play-icon:after { border-right-color: #2e186a; }

.bg-white .btn-play-icon:before, .bg-white .btn-play-icon:after { border-right-color: #fff; }

.btn-play-icon:before { top: 0; border-bottom: 10px solid transparent; }

.btn-play-icon:after { bottom: 0; border-top: 10px solid transparent; }

.btn-play-icon-s2 { height: 34px; width: 24px; }

.btn-play-icon-s2 path { fill: #866bfe; }

.btn-play-dark { color: #fff; }

.btn-play-dark:hover, .btn-play-dark:focus { color: #2bc9b4; }

.btn-play-wrap { display: inline-flex; align-items: center; }

.btn-play-wrap-s2 { flex-direction: column; }

.btn-play-wrap-s1:hover { transform: translateX(15px); }

.btn-play-text { margin-left: 20px; }

.btn-play-text span { display: block; color: #415076; }

.tc-light .btn-play-text span { color: #fff; }

.btn-play-text .text-sm { font-size: 21px; }

.btn-play-text .text-xs { font-size: 12px; color: #866bfe !important; }

.btn-play-wrap-s2 .btn-play-text { margin-left: 0; margin-top: 15px; }

.btn-link { color: #866bfe; text-decoration: none; }

.btn-link:hover, .btn-link:focus { color: #866bfe; text-decoration: none; box-shadow: 0 1px 0 currentColor; }

.btn-underline { padding-left: 0 !important; padding-right: 0 !important; color: #866bfe; background: none !important; border-color: transparent !important; text-decoration: none; }

.btn-underline:hover, .btn-underline:focus { color: #fff; text-decoration: none; }

.btn-round { border-radius: 40px; }

.btn-round:before { border-radius: 40px; }

.btn-round.btn-lg { border-radius: 54px; }

.btn-round.btn-lg:before { border-radius: 54px; }

.btn-round.btn-md { border-radius: 44px; }

.btn-round.btn-md:before { border-radius: 44px; }

.btn-round.btn-sm { border-radius: 39px; }

.btn-round.btn-sm:before { border-radius: 39px; }

.btn-round.btn-xxs { border-radius: 21px; }

.btn-round.btn-xxs:before { border-radius: 21px; }

.btn-with-icon { padding: 1px; background-color: #866bfe; }

.btn-with-icon span { display: inline-block; padding: 0 18px 0 28px; flex-grow: 1; }

.btn-with-icon .icon { height: 47px; width: 47px; line-height: 47px; border-radius: 5px; background: #fff; color: #866bfe; transition: all 0.3s; }

.btn-with-icon.btn-md { padding: 1px; }

.btn-with-icon.btn-md .icon { height: 38px; width: 38px; line-height: 38px; }

.btn-with-icon.btn-sm { padding: 1px; }

.btn-with-icon.btn-sm span { padding: 0 13px 0 23px; }

.btn-with-icon.btn-sm .icon { height: 28px; width: 28px; line-height: 28px; }

.btn-with-icon.btn-round .icon { border-radius: 24px; }

.btn-with-icon.btn-round.btn-md .icon { border-radius: 19px; }

.btn-with-icon.btn-round.btn-sm .icon { border-radius: 14px; }

.btn-with-icon.btn-light { color: #3c1c94 !important; border-color: #fff; background: #fff; }

.btn-with-icon.btn-light .icon { color: #fff; background: #6e19c2; }

.btn-with-icon.btn-light:hover, .btn-with-icon.btn-light:focus { color: #6e19c2 !important; }

.btn-with-icon.btn-light:hover .icon, .btn-with-icon.btn-light:focus .icon { background: #3c1c94; }

.btn-with-icon.btn-light:not(:disabled):not(.disabled).active { border-color: #fff !important; background: #fff !important; color: #415076 !important; }

.btn-with-icon.btn-alt .icon { color: currentColor; background: transparent !important; font-size: 20px; width: auto; margin-right: 30px; transition: none; }

.btn-grp-after li { font-size: 20px; position: relative; }

.btn-grp-after li a { color: rgba(255, 255, 255, 0.8) !important; }

.btn-grp-after li:not(:last-child):after { content: ""; position: absolute; left: 100%; top: 50%; transform: translateY(-50%); height: 14px; width: 1px; background: rgba(255, 255, 255, 0.3); }

.btn-transparent { background: transparent !important; border: transparent; color: #fff !important; font-size: 0.9rem !important; padding: 12px 0; }

.as-mobile .btn-transparent, .has-fixed .btn-transparent { color: #415076 !important; }

@keyframes playbutton { 0% { transform: scale(0.75);
    opacity: 1; }
  100% { transform: scale(1.3);
    opacity: 0; } }

@media (min-width: 576px) { .btn { font-size: 13px; line-height: 25px; padding: 12px 30px; letter-spacing: 0.1em; min-width: 120px; }
  .btn.btn-grad { padding: 14px 30px; }
  .btn-lg { font-size: 14px; font-weight: 700; line-height: 24px; padding: 15px 40px; min-width: 190px; }
  .btn-md { font-size: 12px; line-height: 24px; padding: 8px 20px; min-width: 160px; }
  .btn-md.btn-grad { padding: 7px 30px; }
  .btn-rg { font-size: 12px; line-height: 24px; padding: 6px 20px; }
  .btn-rg.btn-grad { padding: 8px 25px; }
  .btn-sm { font-size: 12px; line-height: 23px; padding: 3px 20px; }
  .btn-sm.btn-grad { padding: 5px 30px; }
  .btn-xxs { font-size: 13px; line-height: 21px; padding: 0 10px; }
  .btn-play { height: 80px; width: 80px; font-size: 18px; line-height: 78px; }
  .btn-play-sm { height: 60px; width: 60px; line-height: 58px; }
  .btn-icon { padding-left: 6px; padding-right: 6px; }
  .btn-grp { margin: -15px; }
  .btn-grp > div, .btn-grp > li { padding: 15px; }
  .text-sm-left .btn-grp { justify-content: center; }
  .btn-grp-s2 { margin: -10px; position: relative; }
  .btn-grp-s2 > div, .btn-grp-s2 > li { padding: 10px; }
  .btn-grp-s2 .btn-download { width: auto; height: 40px; }
  .btn-transparent { padding: 12px 6px; } }

@media (min-width: 768px) { .text-md-left .btn-grp { justify-content: flex-start; } }

@media (min-width: 992px) { .text-lg-left .btn-grp { justify-content: flex-start; }
  .btn-grp .btn-lg { padding: 15px 35px; } }

@media (min-width: 1200px) { .text-xl-left .btn-grp { justify-content: flex-start; } }

.badge { color: #fff; background-color: #866bfe; display: inline-block; font-weight: 600; text-transform: uppercase; border-radius: 34px; padding: 5px 16px; font-size: 13px; line-height: 24px; border: 1px solid #866bfe; }

.badge:not(.badge-outline) { color: #fff; }

.badge-primary { background-color: #866bfe; border-color: #866bfe; color: #866bfe; }

.badge-secondary { background-color: #2bc9b4; border-color: #2bc9b4; color: #2bc9b4; }

.badge-info { background-color: #026bfb; border-color: #026bfb; color: #026bfb; }

.badge-warning, .badge-hot { background-color: #f6be26; border-color: #f6be26; color: #f6be26; }

.badge-success, .badge-new { background-color: #39d884; border-color: #39d884; color: #39d884; }

.badge-danger { background-color: #e6604a; border-color: #e6604a; color: #e6604a; }

.badge-dark { background-color: #0b0c10; border-color: #0b0c10; color: #0b0c10; }

.badge-light { background-color: #c4cbff; border-color: #c4cbff; color: #415076 !important; }

.badge-light.badge-outline { color: #c4cbff; }

.badge-outline { background-color: transparent; }

.badge-lg { font-size: 16px; }

.badge-md { border-radius: 34px; padding: 5px 16px; font-size: 12px; line-height: 18px; }

.badge-sm { border-radius: 34px; padding: 2px 12px; font-size: 12px; line-height: 18px; }

.badge-xs { border-radius: 34px; padding: 0 8px; font-size: 9px; line-height: 16px; letter-spacing: 0.05em; }

.badge-cap { text-transform: capitalize; }

.badge-regular { font-weight: 400; }

.badge-dot { width: 5px; height: 5px; border-radius: 50%; background: #866bfe; border-color: #866bfe; display: inline-block !important; vertical-align: middle; padding: 0; }

.link { display: inline-flex; align-items: center; font-size: 12px; color: #6e19c2; font-weight: 500; letter-spacing: 0.005rem; white-space: nowrap; }

.link:hover, .link:focus { color: #866bfe; }

.link-uc { text-transform: uppercase; letter-spacing: 0.2em; }

.link-icon { margin-right: 5px; font-size: 14px; transition: transform .3s; }

.link-icon:only-child { margin-right: 0 !important; }

.link-icon-sm { font-size: 12px; }

.link-xs .link-icon { font-size: 12px; margin-right: 12px; }

.link-animate:hover .link-icon { transform: translate(-5px, 0); }

.link span + .link-icon { margin-left: 5px; margin-right: 0; }

.link-animate:hover span + .link-icon { transform: translate(5px, 0); }

.link .icon-circle { font-size: inherit; margin-right: 12px; }

.link .icon-circle.link-icon { transition: all .4s; }

.link-break { flex-direction: column; }

.link-break .icon-circle { margin-left: 10px; margin-bottom: 10px; }

.link-primary { color: #46bdf4 !important; }

.link-primary .link-icon.icon-circle { background: #866bfe; color: #fff !important; }

.link-primary:hover, .link-primary:focus { color: #2bc9b4 !important; }

.link-primary:hover .link-icon.icon-circle, .link-primary:focus .link-icon.icon-circle { background: #2bc9b4; }

.link-light { color: #415076 !important; }

.tc-light .link-light { color: #fff !important; }

.link-light .link-icon { color: #866bfe; }

.link-light .link-icon.icon-circle { background: #415076; color: #fff !important; }

.link-light:hover, .link-light:focus { color: #866bfe !important; }

.link-light:hover .link-icon.icon-circle, .link-light:focus .link-icon.icon-circle { background: #866bfe; }

.link-thin { font-weight: 300; }

.link-underline { text-decoration: underline !important; }

@media (min-width: 420px) { .btn-grp-break > div, .btn-grp-break > li { width: auto; text-align: left; }
  .link { font-size: 14px; }
  .link-xs { font-size: 12px; }
  .link-break { flex-direction: row; }
  .link-break .icon-circle { margin-left: 0; margin-bottom: 0; } }

/** 03.03 Title */
.title { position: relative; font-size: 1.75rem; }

.title:not(:last-child) { margin-bottom: 1.25rem; }

.title small { font-size: 50%; margin-top: 0.75rem; }

.title-thin { font-weight: 300; }

.title-regular { font-weight: 400; }

.title-xxs { font-size: 12px; letter-spacing: 0.1em; }

.title-xxs:not(:last-child) { margin-bottom: 0.5rem; }

.title-xs { text-transform: uppercase; font-size: 0.88rem; letter-spacing: 0.2em; }

.title-xs-alt { font-size: 0.94rem; }

.title-xs-s1 { font-size: 1.1rem; letter-spacing: 0.4em; }

.title-xs-s2 { font-size: 1.1rem; letter-spacing: 0; }

.title-xs small { font-size: 70%; }

.title-sm { font-size: 1.125rem; }

.title-sm:not(:last-child) { margin-bottom: 0.85rem; }

.title-sm small { font-size: 70%; }

.title-md { font-size: 1.375rem; }

.feature-text-wd .title-md { font-size: 1.125rem; }

.title-md:not(:last-child) { margin-bottom: 1rem; }

.title-lg { font-size: 1.75rem; }

.title-xl { font-size: 1.75rem; }

.title-xl-s2 { font-size: 1.75rem; line-height: 1.3; }

.title-light { color: rgba(91, 83, 162, 0.6); }

.title-dark { color: #5b53a2; }

.title-base { color: #415076; }

.title a { color: #5b53a2; }

.title a:hover { color: #866bfe; }

a:hover .title { color: #866bfe; }

a .title { transition: all .4s; }

.title-mid { font-weight: 500; }

.title-semibold { font-weight: 600; }

.title-bold { font-weight: 700; }

.title-s1 { padding-left: 75px; display: inline-block; font-weight: 600; }

.title-s1:before { position: absolute; left: 0 !important; top: 50%; margin-top: -1px; height: 2px; width: 60px; content: ''; transform: translate(0, -50%) !important; opacity: 1 !important; background: #5b53a2; }

.tc-light .title-s1:before { background: #fff; }

.title-s1.tc-primary:before, .tc-primary a.title-s1:before { background: #866bfe; }

.title-s2 { padding-bottom: 26px; }

.title-s2:after { position: absolute; left: 50%; bottom: 0; content: ''; margin-left: -40px; width: 80px; height: 6px; border-radius: 3px; background: #866bfe; }

.tc-light .title-s2:after { background: #866bfe; }

.title-s2-alt { padding-bottom: 0; padding-top: 26px; }

.title-s2-alt:after { top: 0; bottom: auto; }

.title-s3 { font-size: 2.2rem !important; line-height: 1.3 !important; }

.title-s4 { font-size: 2.3rem; opacity: 0.3; padding-bottom: 10px; text-transform: uppercase; }

.title-s5 { padding-bottom: 24px; margin-bottom: 20px; }

.title-s5.title-sm { font-size: 0.9rem; }

.title-s5.title-sm span:first-child { font-size: 1.25rem; }

.title-s5 span:first-child { display: block; padding-bottom: 13px; color: #866bfe; font-weight: 500; }

.tc-light .title-s5 span:first-child { color: #fff; }

.title-s5 span:last-child { opacity: .7; }

.title-s5:after { position: absolute; left: 50%; bottom: 0; transform: translate(-50%, 0); content: ''; width: 50px; height: 3px; border-radius: 2px; background: #866bfe; }

.title-s6 { position: relative; }

.title-s6:before { position: absolute; top: 8px; left: -15px; height: 5px; width: 5px; content: ''; border-radius: 50%; background: #866bfe; }

.title-s7 span:first-child { display: block; padding-bottom: 13px; color: #6e19c2; font-size: 1.35rem; }

.tc-light .title-s7 span:first-child { color: #fff; }

.title-s7 span:last-child { font-size: 0.9rem; color: #415076; }

.title-xxl-grad { font-size: 70px; line-height: 1; font-weight: 700; margin-bottom: 0; background: -webkit-linear-gradient(#5d38fe, #229f8e); -webkit-background-clip: text; -webkit-text-fill-color: transparent; opacity: .4; }

.title-ele-head { background: linear-gradient(180deg, #415076 0%, #fff 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }

.title-sub { color: #d87032; }

@media (min-width: 576px) { .title { font-size: 2.25rem; }
  .title:not(:last-child) { margin-bottom: 1.25rem; }
  .title small { font-size: 50%; margin-top: 0.75rem; }
  .title-xxs { font-size: 12px; letter-spacing: 0.1em; }
  .title-xxs:not(:last-child) { margin-bottom: 0.5rem; }
  .title-xs { font-size: 0.88rem; }
  .title-xs-alt { font-size: 0.94rem; }
  .title-xs-s1 { font-size: 1.1rem; }
  .title-xs-s2 { font-size: 1.1rem; }
  .title-xs small { font-size: 70%; }
  .title-sm { font-size: 1.125rem; }
  .title-sm:not(:last-child) { margin-bottom: 0.85rem; }
  .title-md { font-size: 1.375rem; }
  .title-md:not(:last-child) { margin-bottom: 1rem; }
  .title-md-s2 { font-size: 1.675rem; line-height: 1.6; }
  .title-lg { font-size: 1.75rem; }
  .title-xl { font-size: 3.5rem; }
  .title-xl-s2 { font-size: 2.2rem; }
  .title-s3 { font-size: 2.2rem !important; }
  .title-s4 { font-size: 2.3rem; }
  .title-s5.title-sm { font-size: 0.9rem; }
  .title-s5.title-sm span:first-child { font-size: 1.25rem; }
  .title-xl-2 { font-size: 1.8rem !important; }
  .title-xxl-grad { font-size: 140px; } }

@media (min-width: 768px) { .title-xl-2 { font-size: 2.2rem !important; }
  .title-xxl-grad { font-size: 200px; } }

@media (min-width: 992px) { .title-xxl-grad { font-size: 250px; }
  .feature-text-wd .title-md { font-size: 1.375rem; } }

@media (min-width: 1200px) { .title-xl-s2 { font-size: 2.8rem !important; } }

@media (min-width: 1600px) { .title-xl-2 { font-size: 2.4rem !important; line-height: 1.3 !important; }
  .banner-s1 .title-xl-2 { font-size: 2.2rem !important; }
  .title-xl-s3 { font-size: 2.75rem !important; } }

/** 03.04 List */
.list li { line-height: 1.674; padding: 5px 0; position: relative; color: #415076; font-size: 14px; }

.tc-light .list li { color: #af9cff; }

.list-check li { padding-left: 24px; }

.list-check li:before { position: absolute; top: 0; left: 0; height: 22px; width: 26px; line-height: 26px; padding: 3px 0; color: #866bfe; font-family: "Font Awesome 5 Free"; font-weight: 700; font-size: 13px; content: '\f00c'; transition: all .4s; }

.tc-light .list-check li:before { color: #866bfe; }

.list-check-s3 li { padding: 0px 0px 0px 55px; margin-bottom: 18px; line-height: 1.8; }

.list-check-s3 li:before { width: 35px; height: 35px; background: #866bfe; border-radius: 50%; color: #fff; text-align: center; line-height: 29px; font-size: 16px; left: 0; top: 5px; }

.tc-light .list-check-s3 li:before { background: #866bfe; color: #fff; }

.list-check-s3-sm li { padding: 0px 0px 0px 40px; margin-bottom: 30px; }

.list-check-s3-sm li .title-md { margin-bottom: 0.5rem; }

.list-check-s3-sm li:before { width: 25px; height: 25px; line-height: 20px; font-size: 11px; top: 2px; }

.list-check-s4 li { margin: 0; padding-left: 35px; position: relative; }

.list-check-s4 li:before { left: 15px; top: 14px; }

.list-number { counter-reset: item; }

.list-number li { line-height: 1.86; margin-bottom: 10px; padding-left: 65px; }

.list-number li:before { position: absolute; top: 10px; left: 25px; counter-increment: item; content: counter(item) "."; font-size: 30px; font-weight: 700; line-height: 1; color: #866bfe; font-family: 'Poppins', sans-serif !important; }

.tc-light .list-number li:before { color: #866bfe; }

.list-number-s2 li { padding-left: 60px; }

.has-rtl .list-number-s2 li { padding-right: 60px; padding-left: 0; }

.list-number-s2 li:not(:last-child) { margin-bottom: 16px; }

.list-number-s2 li span { width: 30px; height: 30px; position: absolute; left: 0; top: 5px; line-height: 30px; text-align: center; background: rgba(134, 107, 254, 0.2); border-radius: 50%; color: #725bd6; font-weight: 400; }

.has-rtl .list-number-s2 li span { left: auto; right: 0; }

.list-dot li { position: relative; padding-left: 22px; line-height: 1.62; }

.list-dot li:before { position: absolute; top: 14px; left: 0; height: 5px; width: 5px; content: ''; border-radius: 50%; background: #5b53a2; }

.tc-light .list-dot li:before { background: #2bc9b4; }

.list-dot.list-sm { margin-top: -5px; margin-bottom: -5px; }

.list-dot.list-sm li { padding-left: 16px; font-size: 0.9rem; padding-top: 10px; padding-bottom: 10px; }

.list-dot.list-sm li:before { top: 19px; }

.list-dot-bar { padding: 20px 0 65px 38px; }

.list-dot-bar li { margin-bottom: 10px; }

.list-dot-bar li span { width: 20px; height: 20px; content: ""; background: transparent; position: absolute; border-radius: 50%; border: 2px solid #2bc9b4; left: -38px; top: 7px; }

.list-dot-bar li span:after, .list-dot-bar li span:before { position: absolute; content: ""; top: 50%; left: 50%; background: #2bc9b4; }

.list-dot-bar li span:after { width: 7px; height: 7px; border-radius: 50%; transform: translate(-50%, -50%); }

.list-dot-bar li span:before { width: 18px; height: 2px; transform: translateY(-50%); }

.list-btn li { display: inline-block; padding-right: 15px; }

.list-btn li:not(:first-child) a { padding-right: 10px; }

.list-btn-dropdown { margin: -22px -30px; }

.list-btn-dropdown > li { display: inline-block; margin: 18px 30px; padding: 5px 0; }

.list-btn-dropdown > li a { min-width: 200px; }

.list-btn-dropdown > li a i { position: absolute; font-size: 20px; top: 0; right: 30px; line-height: 42px; }

.list-inline { display: inline-flex; }

@media (min-width: 576px) { .list li { font-size: 15px; } }

/** 03.05 Form */
input ~ .error, textarea ~ .error, select ~ .error { position: absolute; right: -24px; top: -15px; background: #e6604a; color: #fff; font-size: 11px; line-height: 18px; padding: 2px 10px; border-radius: 2px; z-index: 9; white-space: nowrap; }

input ~ .error:after, textarea ~ .error:after, select ~ .error:after { position: absolute; content: ''; height: 0; width: 0; border-left: 5px solid transparent; border-right: 5px solid #e6604a; border-bottom: 5px solid transparent; bottom: -5px; left: 3px; }

.tc-light input ~ .error, .tc-light textarea ~ .error, .tc-light select ~ .error { background: #e6604a; color: #fff; box-shadow: none; }

.tc-light input ~ .error:after, .tc-light textarea ~ .error:after, .tc-light select ~ .error:after { border-right-color: #e6604a; }

@media (min-width: 360px) { input ~ .error, textarea ~ .error, select ~ .error { right: -10px; } }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light .bg-light input::-webkit-input-placeholder, .tc-light .bg-light-alt input::-webkit-input-placeholder, .tc-light .bg-white input::-webkit-input-placeholder, .tc-light .bg-light textarea::-webkit-input-placeholder, .tc-light .bg-light-alt textarea::-webkit-input-placeholder, .tc-light .bg-white textarea::-webkit-input-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light input::-webkit-input-placeholder, .tc-light textarea::-webkit-input-placeholder { color: rgba(255, 255, 255, 0.7); }

input::-moz-placeholder, textarea::-moz-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light .bg-light input::-moz-placeholder, .tc-light .bg-light-alt input::-moz-placeholder, .tc-light .bg-white input::-moz-placeholder, .tc-light .bg-light textarea::-moz-placeholder, .tc-light .bg-light-alt textarea::-moz-placeholder, .tc-light .bg-white textarea::-moz-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light input::-moz-placeholder, .tc-light textarea::-moz-placeholder { color: rgba(255, 255, 255, 0.7); }

input:-ms-input-placeholder, textarea:-ms-input-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light .bg-light input:-ms-input-placeholder, .tc-light .bg-light-alt input:-ms-input-placeholder, .tc-light .bg-white input:-ms-input-placeholder, .tc-light .bg-light textarea:-ms-input-placeholder, .tc-light .bg-light-alt textarea:-ms-input-placeholder, .tc-light .bg-white textarea:-ms-input-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light input:-ms-input-placeholder, .tc-light textarea:-ms-input-placeholder { color: rgba(255, 255, 255, 0.7); }

input:-moz-placeholder, textarea:-moz-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light .bg-light input:-moz-placeholder, .tc-light .bg-light-alt input:-moz-placeholder, .tc-light .bg-white input:-moz-placeholder, .tc-light .bg-light textarea:-moz-placeholder, .tc-light .bg-light-alt textarea:-moz-placeholder, .tc-light .bg-white textarea:-moz-placeholder { color: rgba(65, 80, 118, 0.6); }

.tc-light input:-moz-placeholder, .tc-light textarea:-moz-placeholder { color: rgba(255, 255, 255, 0.7); }

.field-item { position: relative; padding-bottom: 15px; }

.field-wrap { position: relative; }

.field-wrap + p { margin-top: .75rem; }

.field-wrap .btn + .form-results:not(:empty) { margin-top: 30px; }

.field-wrap .label-inline { padding-left: 25px; line-height: normal; }

.has-rtl .field-wrap .label-inline { padding-left: 0; padding-right: 25px; }

.field-wrap .label-inline .icon { font-size: 20px; color: #adb2be; }

.field-label { font-size: 12px; font-weight: 700; letter-spacing: 0.2em; }

.field-label-s2 { font-size: 16px; font-weight: 500; letter-spacing: normal; }

.field-label-line { position: absolute; width: 100%; top: 10px; font-size: 0.94rem; font-weight: 400; letter-spacing: normal; left: 0; color: rgba(65, 80, 118, 0.6); z-index: 1; transition: all .5s ease; transform-origin: left; }

.tc-light .field-label-line { color: #fff; }

.field-item-center .field-label-line { transform-origin: center; }

.field-inline { border-radius: 6px; display: flex; }

.field-inline input { border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: none; }

.field-inline input + .btn:not(.btn-round) { border-radius: 0 4px 4px 0; }

.field-inline.field-split .btn:not(.btn-round) { border-top-left-radius: 0; border-bottom-left-radius: 0; height: 100%; }

.wgs-search .field-inline.field-split .btn:not(.btn-round) { height: auto; }

.field-inline .field-wrap { flex-grow: 1; }

.field-inline .submit-wrap .btn { width: 100%; }

.field-inline + .form-results.alert { width: 100%; margin-top: 15px; }

.field-inline-s2 { padding: 10px; display: block; }

.field-inline-s2 .btn { border-top-left-radius: 6px; border-bottom-left-radius: 6px; }

.field-inline-s2-sm { padding: 5px; }

.field-inline-s2 input { text-align: center; }

@media (max-width: 575px) { .field-inline-s2 input { margin-bottom: 5px; } }

.field-inline-s2 .submit-wrap { margin-left: 0; }

.field-inline-s3 .btn-light { color: #866bfe !important; }

.field-inline-s4 { align-items: center; }

.field-inline-s4 .input-solid { background: rgba(255, 255, 255, 0.2); }

.field-inline-s4 .field-wrap { margin-right: -50px; }

.field-inline-round { border-radius: 50px; }

.field-inline-round .input-solid { padding-left: 30px; }

.input-solid { background: none; border: none; width: 100%; padding: 15px 20px; line-height: 23px; border-radius: 4px; }

.input-solid-light { background: #f7f7f7; }

.tc-light .input-solid { color: #fff; }

.tc-light .bg-light .input-solid, .tc-light .bg-light-alt .input-solid, .tc-light .bg-white .input-solid { color: #415076; }

.input-solid-md { padding: 12px 20px; line-height: 22px; }

.input-solid-lg { padding: 20px 20px; }

.input-solid:focus { outline: none; }

.input-line { position: relative; z-index: 2; background: transparent; border: none; width: 100%; font-size: 15px; line-height: 19px; padding: 16px 0 8px; color: #415076; border-bottom: 1px solid #dbe4f761; outline: none; transition: all .4s; }

.tc-light .input-line { color: #fff; border-bottom-color: rgba(219, 228, 247, 0.3); }

.input-line:focus { border-bottom-color: #866bfe; }

.field-item-center .input-line { text-align: center; }

.input-textarea { height: 105px; resize: none; outline: none; }

.input-textarea ~ .field-label-line { top: 73px; }

.input-focused .field-label-line { left: 0; top: -10px; transform: scale(0.7); }

.input-bordered { border-radius: 6px; border: 1px solid #dbe4f7; width: 100%; padding: 10px 15px; line-height: 21px; font-size: .9em; color: #415076; transition: all .4s; background-color: transparent; }

.input-bordered-lg { padding: 13px 20px; }

.input-bordered-lg-s1 { padding: 17px 20px; }

.tc-light .field-inline-s3 .input-bordered { border-color: rgba(255, 255, 255, 0.5); }

.field-inline-s3 .input-bordered { border-width: 2px; border-right-width: 0 !important; }

.field-item-s2 .input-bordered { border: 2px solid #d1e0f6; background: transparent; }

.tc-light .input-bordered { color: #fff; background: #4822b2; border-color: rgba(255, 255, 255, 0.25); }

.tc-light .input-bordered:focus { border-color: rgba(255, 255, 255, 0.45); }

.input-bordered:focus { box-shadow: none; outline: none; border-color: #b7bee3; }

.input-bordered:disabled { background: rgba(219, 228, 247, 0.2); }

.input-switch { position: absolute; height: 1px; width: 1px; opacity: 0; }

.input-switch ~ label:not(.error) { line-height: 20px; padding-top: 5px; padding-bottom: 5px; cursor: pointer; padding-left: 48px; min-height: 30px; min-width: 48px; border-radius: 12px; margin-bottom: 0; display: flex; align-items: center; position: relative; font-size: 14px; color: #415076; }

.input-switch ~ label:not(.error).no-text { padding-left: 56px; }

.input-switch ~ label:not(.error):before, .input-switch ~ label:not(.error):after { position: absolute; content: ''; transition: all .4s; }

.input-switch ~ label:not(.error):before { left: 0; top: 50%; transform: translateY(-50%); height: 20px; width: 40px; border-radius: 15px; background: #d3e0f3; }

.input-switch ~ label:not(.error):after { left: 2px; top: 50%; transform: translateY(-50%); height: 16px; width: 16px; border-radius: 50%; background: #fff; }

.input-switch ~ label:not(.error) span { transition: all .4s; opacity: 1; }

.input-switch ~ label:not(.error) span.over { position: absolute; left: 48px; }

.input-switch ~ label:not(.error) span:last-child { opacity: 0; }

.input-switch:disabled ~ label { opacity: .5; }

.input-switch:checked ~ label:before { background: #866bfe; }

.input-switch:checked ~ label:after { left: 22px; }

.input-switch:checked ~ label span { opacity: 0; }

.input-switch:checked ~ label span:last-child { opacity: 1; }

.input-switch ~ .error { left: -4px; top: -26px; bottom: auto; right: auto; }

.input-checkbox, .input-radio { position: absolute; height: 1px; width: 1px; opacity: 0; }

.input-checkbox ~ label:not(.error), .input-radio ~ label:not(.error) { position: relative; padding-left: 28px; line-height: 20px; font-size: 14px; cursor: pointer; margin-bottom: 0; }

.input-checkbox ~ label:not(.error):before, .input-checkbox ~ label:not(.error):after, .input-radio ~ label:not(.error):before, .input-radio ~ label:not(.error):after { position: absolute; left: 0; top: 0; height: 20px; width: 20px; line-height: 20px; text-align: center; content: ''; background-color: transparent; border-radius: 3px; }

.input-checkbox ~ label:not(.error):before, .input-radio ~ label:not(.error):before { border: 2px solid #dbe4f7; }

.input-checkbox ~ label:not(.error):after, .input-radio ~ label:not(.error):after { font-family: "Font Awesome 5 Free"; content: '\f00c'; font-weight: 700; font-size: 10px; background-color: #866bfe; color: #fff; opacity: 0; transition: all .4s; }

.input-checkbox:checked ~ label:not(.error):after, .input-radio:checked ~ label:not(.error):after { opacity: 1; }

.input-checkbox ~ .error, .input-radio ~ .error { left: -4px; top: -26px; bottom: auto; right: auto; }

.input-radio ~ label:not(.error):before, .input-radio ~ label:not(.error):after { border-radius: 50%; }

@media (min-width: 576px) { .field-item { padding-bottom: 30px; }
  .field-inline .btn-md { line-height: 23px; } }

@media (min-width: 768px) { .field-inline .submit-wrap .btn { width: auto; height: 100%; }
  .field-inline-s2 { display: flex; }
  .field-inline-s2 input { text-align: left; } }

.select2-container.select2 { width: 100% !important; }

.select2-container .select2-selection { position: relative; }

.select2-container .select2-selection:focus { outline: none; }

.select2-container .select2-selection .select2-selection__rendered { color: rgba(65, 80, 118, 0.6); font-size: 0.94rem; }

.select2-container .select2-selection .select2-selection__arrow { position: absolute; top: 0; right: 0; height: 100%; width: 40px; }

.select2-container .select2-selection .select2-selection__arrow:after { position: absolute; top: 0; left: 0; height: 100%; width: 100%; font-family: "themify"; content: '\e64b'; text-align: center; line-height: 20px; padding: 10px 0; font-size: .88rem; }

.select2-container .select2-dropdown { border-color: #dbe4f7 !important; }

.select2-container.select-line .select2-selection { height: 44px; line-height: 20px; padding: 16px 40px 8px 0; border-bottom: 1px solid #dbe4f7; }

.select2-container.select-line .select2-selection .select2-selection__rendered { padding-left: 0; }

.select2-container.select-line .select2-selection .select2-selection__arrow:after { padding: 16px 0 8px; }

.select2-container.select-bordered .select2-selection { height: 42px; line-height: 20px; padding: 10px 40px 10px 0; border: 1px solid #dbe4f7; border-radius: 6px; }

.select2-container.select-bordered .select2-selection .select2-selection__rendered { padding-left: 15px; }

.select2-container.select-bordered.select-lg .select2-selection { height: 50px; border: 2px solid #dbe4f7; padding: 13px 40px 13px 10px; min-width: 146px; }

.select2-container--below.select2-container--open.select-bordered .select2-selection--single { border-radius: 6px 6px 0 0; }

.select2-container--above.select2-container--open.select-bordered .select2-selection--single { border-radius: 0 0 6px 6px; }

.select2-container.select-lg .select2-selection .select2-selection__arrow:after { padding: 13px 0; }

.select2-container.select-lg .select2-selection .select2-selection__rendered { color: #5b53a2; font-size: 1rem; }

.select2-search { display: none; }

.select2-results { padding: 5px 0; }

.select2-results__option { padding: 6px 15px; font-size: .88rem; transition: all .4s; max-height: 180px; }

.select2-results__option[aria-selected="true"], .select2-results__option:hover { background: #dbe4f7; }

.select-line.select2-container--open .select2-dropdown--above { border-bottom: 1px solid #dbe4f7 !important; }

.submit-wrap { margin-left: -3px; }

.submit-wrap-s2 { margin: -4px 0; }

.submit-wrap-s2 .btn { border-radius: 5px !important; }

.btn-auto-lg { font-size: 20px; }

@media (min-width: 992px) { .select2-container.select-bordered.select-lg .select2-selection { min-width: 255px; } }

@media (max-width: 400px) { .field-inline-wrap { flex-wrap: wrap; }
  .field-inline-wrap .submit-wrap { margin-left: auto; margin-right: auto; margin-top: 10px; } }

/**/
/** 03.06 Table */
.table { color: inherit; }

.table td { border-top: none; padding: 18px 0; font-size: 1rem; line-height: 1.5rem; }

.table tr { border-bottom: 1px solid rgba(134, 107, 254, 0.2); }

.table tr:last-child { border-bottom: none; }

.table-s1 tr:last-child td { border-bottom: none; }

.table-head { font-weight: 500; color: #866bfe; }

.table-token { margin-bottom: 5px; }

.table-token .table-head { padding-bottom: 2px; }

.table-token .table-des { padding-top: 2px; }

.table-token-s1 { border: 1px solid rgba(209, 224, 246, 0.7); border-collapse: separate; border-radius: 6px; margin-bottom: 0; }

.tc-light .table-token-s1 { border-color: rgba(255, 255, 255, 0.1); }

.table-token-s1 tr:nth-child(odd) td:first-child { background: #eff8ff; }

.tc-light .table-token-s1 tr:nth-child(odd) td:first-child { background: #3c1c94; }

.table-token-s1 tr:nth-child(odd) td:last-child { background: #fff; }

.tc-light .table-token-s1 tr:nth-child(odd) td:last-child { background: #391a8b; }

.table-token-s1 tr:nth-child(even) td:first-child { background: #e7f3ff; }

.tc-light .table-token-s1 tr:nth-child(even) td:first-child { background: #4520a9; }

.table-token-s1 tr:nth-child(even) td:last-child { background: #f7f9ff; }

.tc-light .table-token-s1 tr:nth-child(even) td:last-child { background: #3f1e9d; }

.table-token-s1 tr:first-child td:first-child { border-top-left-radius: 6px; }

.table-token-s1 tr:first-child td:last-child { border-top-right-radius: 6px; }

.table-token-s1 tr:last-child td:first-child { border-bottom-left-radius: 6px; }

.table-token-s1 tr:last-child td:last-child { border-bottom-right-radius: 6px; }

.table-token-s1 tr td { width: 48%; font-weight: 500; font-size: 0.93rem; line-height: 24px; padding: 12px !important; padding-left: 18px !important; color: #415076; }

.tc-light .table-token-s1 tr td { color: #fff; }

.table-token-s1 tr td:last-child { color: #866bfe; font-weight: 400; font-size: 0.88rem; }

.tc-light .table-token-s1 tr td:last-child { color: rgba(255, 255, 255, 0.6); }

.table-bordered td { padding-left: 20px; padding-right: 20px; border: 1px solid rgba(134, 107, 254, 0.2); }

@media (min-width: 576px) { .table-token { margin-bottom: 1rem; display: table; }
  .table-token tbody { display: table-row-group; }
  .table-token tr { display: table-row; }
  .table-token td { display: table-cell; }
  .table-token .table-head, .table-token .table-des { padding: 18px 0; } }

/** 03.07 Progress */
.progress-list { margin-top: .8rem; }

.progress-wrap { margin-bottom: 1rem; }

.progress-wrap:last-child:not(:only-child) { margin-bottom: 0; }

.progress-wrap-point { margin-bottom: 55px; }

.progress-wrap-point-s1 { margin-bottom: 30px; margin-top: 50px; }

.progress-title { text-transform: uppercase; font-size: 0.75rem; font-weight: 500; display: flex; justify-content: space-between; padding-bottom: .4rem; }

.progress-amount { font-weight: 400; }

.progress-bar { position: relative; width: 100%; height: 20px; border-radius: 4px; background: rgba(134, 107, 254, 0.2); overflow: visible; }

.progress-bar .progress-percent { border-radius: 4px; }

.progress-bar-xs { height: 6px; border-radius: 2px; }

.progress-bar-xs .progress-percent { border-radius: 2px; border: none; }

.progress-bar-sm { height: 15px; border-radius: 2px; }

.progress-bar-md { height: 24px; border-radius: 3px; }

.progress-bar-md .progress-percent { padding: 3px 0; }

.progress-bar-s1 { padding: 6px; width: 100%; background: #fff; border-radius: 16px; height: auto; }

.progress-bar-s2.bar-round { border-radius: 30px; }

.progress-bar-s2.bar-round .progress-percent { border-radius: 30px; }

.progress-bar-s2 .progress-percent { top: 3px; left: 3px; bottom: 3px; right: 3px; border: none; }

.progress-bar-s2 .progress-percent:after { display: none; }

.progress-percent { position: absolute; top: 0; left: 0; bottom: 0; display: flex; align-items: center; justify-content: center; font-size: 12px; background: #866bfe; }

.progress-percent-s2 { z-index: 2; }

.progress-percent-s2:after { position: absolute; content: ''; top: 50%; right: 0; height: 18px; width: 18px; border-radius: 50%; border: 2px solid #fff; background: #866bfe; transform: translate(50%, -50%); }

.progress-percent-s1 { position: relative; max-height: 20px; border: none; min-height: 20px; border-radius: 20px !important; }

.progress-percent-s3 { border: none; border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }

.progress-info { display: block; font-size: 16px; color: #fff; padding: 12px 0 22px; }

.progress-info span { font-weight: 500; font-size: 18px; color: #2bc9b4; }

.progress-info-s2 { font-size: 12px; text-transform: uppercase; padding: 0 0 17px; }

.progress-info-s2 span { font-size: 14px; text-transform: capitalize; color: #fff; }

.progress-point { color: #fff; font-size: 11px; line-height: 14px; font-weight: 500; text-transform: uppercase; position: absolute; top: 30px; transform: translateX(-50%); padding: 3px 0; }

.progress-point:after { position: absolute; bottom: 100%; left: 50%; margin-left: -1px; content: ''; width: 2px; height: 40px; background: rgba(193, 206, 241, 0.5); }

.progress-bar-md .progress-point { top: 40px; font-size: 14px; font-weight: 400; text-transform: capitalize; }

.progress-bar-md .progress-point:after { bottom: calc(100% + 10px); height: 30px; }

.progress-point span { display: block; font-size: 12px; color: #866bfe; }

.progress-point-s1 { top: -35px; }

.progress-point-s1:after { bottom: auto; top: 100%; height: 30px; }

.progress-point-1 { left: 25%; }

.progress-point-2 { left: 85%; }

.progress-points { padding-top: 10px; display: flex; justify-content: space-between; }

ul.progress-info { display: flex; justify-content: space-between; }

@media (min-width: 576px) { .progress-bar-md { height: 36px; }
  .progress-percent { font-size: 14px; }
  .progress-wrap-point { margin-bottom: 78px; }
  .progress-wrap-point-s1 { margin-bottom: 30px; }
  .progress-bar-md .progress-point { top: 60px; }
  .progress-bar-md .progress-point:after { height: 50px; } }

/** 03.08 CountDown */
.countdown { display: flex; justify-content: space-around; margin: 0 -5px; }

.countdown-small { justify-content: flex-start; margin: 0 -20px; }

.countdown-s2 { margin: 0; justify-content: space-between; }

.countdown-s3 { margin: 0 -16px 0 -25px; }

.countdown-s4 { margin: 0; }

.countdown-md { padding-bottom: 35px; }

.countdown-item { padding: 0 5px; }

.countdown-s2 .countdown-item { padding: 0; }

.countdown-s3 .countdown-item { flex-grow: 1; }

.countdown-s3.countdown-small .countdown-item, .countdown-s3-alt .countdown-item { flex-grow: 0; width: auto; }

.countdown-s4 .countdown-item, .countdown-s5 .countdown-item { width: 100%; }

.countdown-wd .countdown-item { flex-grow: 0; width: 100%; }

.countdown-md .countdown-item { width: 80px; }

.countdown-wd { text-align: center; }

.countdown-time { position: relative; height: 54px; width: 54px; border-radius: 50%; border: 2px solid #866bfe; text-align: center; line-height: 50px; display: block; }

.countdown-s2 .countdown-time { border-radius: 6px; background-color: #866bfe; border-color: transparent; font-size: 1.4rem; font-weight: 500; color: #fff; }

.countdown-s3 .countdown-time { border-radius: 0; border: none; font-size: 2.8125rem; line-height: 50px; font-weight: 700; height: 50px; width: 100%; color: #6e19c2; }

.tc-light .countdown-s3 .countdown-time { color: #fff; }

.countdown-s3 .countdown-time:after { position: absolute; top: calc(50% - 3px); right: -6px; transform: translate(50%, -50%); content: ':'; }

.countdown-s3 .countdown-time-last:after { display: none; }

.countdown-s3-alt .countdown-time { font-size: 1.875rem; padding: 0 20px; font-weight: 300; height: 38px; line-height: 38px; margin-bottom: 0.5rem; }

.countdown-s3.countdown-small .countdown-time { font-size: 1rem; line-height: 24px; height: 24px; text-align: left; padding: 0 20px; }

.countdown-s4 .countdown-time { font-size: 2rem; }

.countdown-s4 .countdown-time, .countdown-thin .countdown-time { font-weight: 300; }

.tc-light .countdown-s5 .countdown-time { color: #866bfe; }

.countdown-md .countdown-time { font-size: 2rem; line-height: normal; font-weight: 400; height: auto; }

.countdown-text { display: block; font-weight: 500; font-size: 12px; letter-spacing: 0.01em; padding-top: 8px; text-align: center; }

.tc-light .countdown-text { color: #fff; }

.countdown-s2 .countdown-text { text-transform: uppercase; font-weight: 400; }

.tc-light .countdown-s2 .countdown-text { color: #866bfe; }

.countdown-s3 .countdown-text { text-transform: uppercase; }

.tc-light .countdown-s3 .countdown-text { color: #fff; }

.countdown-s3-alt .countdown-text { text-transform: capitalize; padding-top: 0; font-weight: 400; }

.countdown-s3.countdown-small .countdown-text { text-align: left; padding: 0 22px; }

.countdown-s4 .countdown-text { text-transform: capitalize; padding-top: 0; }

.tc-light .countdown-s5 .countdown-text { color: #866bfe; }

.countdown-md .countdown-text { font-weight: 400; padding-top: 2px; }

.status-countdown { overflow: hidden; }

@media (min-width: 420px) { .countdown-time { height: 64px; width: 64px; line-height: 60px; } }

@media (min-width: 576px) { .status-countdown { min-width: 290px; }
  .countdown-large .countdown-time { font-size: 3.5rem; }
  .countdown-large .countdown-time:after { font-size: 2.5rem; } }

@media (min-width: 768px) { .countdown-wd .countdown .item { width: 100%; }
  .countdown-xxl .countdown-time { font-size: 5.5rem; }
  .countdown-xl .countdown-time { font-size: 4rem; }
  .countdown-xl.countdown-s2 .countdown-time { width: 123px; height: 123px; line-height: 123px; border-radius: 4px; }
  .countdown-xxl .countdown-text { padding-top: 30px; }
  .countdown-s3-lg { margin: 0 -38px; } }

@media (min-width: 992px) { .countdown-s4 .countdown-time { font-size: 2rem; }
  .countdown-large .countdown-time { font-size: 3.5rem; } }

@media (min-width: 1200px) { .countdown-s4 .countdown-time { font-size: 2.9rem; }
  .countdown-large .countdown-time { font-size: 3.6rem; } }

/** 03.09 Toastr */
/* Toastr */
#toast-container { position: fixed; z-index: 999999; margin-top: 22px; margin-bottom: 16px; }

#toast-container .toast { opacity: 1; }

.toast-top-center { top: 0; right: 0; width: 100%; }

.toast-bottom-center { bottom: 0; right: 0; width: 100%; }

.toast-top-full-width { top: 0; right: 0; width: 100%; }

.toast-bottom-full-width { bottom: 0; right: 0; width: 100%; }

.toast-top-left { top: 0; left: 16px; }

.toast-top-right { top: 0; right: 16px; }

.toast-bottom-right { right: 16px; bottom: 0; }

.toast-bottom-left { bottom: 0; left: 16px; }

.toast-top-center > div, .toast-bottom-center > div { width: 650px; max-width: 90%; margin-left: auto; margin-right: auto; }

.toast-top-full-width > div, .toast-bottom-full-width > div { width: 96%; margin-left: auto; margin-right: auto; }

.toast { background: #fff; color: #fff; overflow: hidden; margin: 0 0 8px; padding: 15px 51px 15px 15px; width: 300px; border-radius: 5px; border: none; display: flex; align-items: center; box-shadow: 0px 2px 18px 2px rgba(91, 83, 162, 0.25); background: #0b0c10; position: relative; }

.toast-close-button { cursor: pointer; position: absolute; text-align: left; right: 15px; text-indent: -9999em; overflow: hidden; background: none; border-radius: 50%; border: none; height: 36px; width: 36px; transition: all .3s; }

.toast-close-button:after { position: absolute; content: '\e646'; font-family: 'themify'; top: 0; right: -1px; text-indent: 0; display: block; font-size: 13px; line-height: 38px; height: 36px; width: 36px; text-align: center; color: #fff; transition: all .3s; }

.toast-close-button:hover, .toast-close-button:focus { background: rgba(247, 248, 255, 0.15); box-shadow: none; outline: none; }

.toast-message { position: relative; flex-grow: 1; font-size: 13px; line-height: 17px; display: flex; align-items: center; padding-left: 10px; min-height: 30px; }

.toast-message-icon { display: inline-block; font-size: 14px; margin-right: 15px; margin-left: -10px; text-align: center; height: 30px; width: 30px; line-height: 30px; border-radius: 50%; }

.toast-message-icon.ti-alert:before { position: relative; top: -2px; }

.toast-info .toast-message-icon { background: #026bfb; }

.toast-warning .toast-message-icon { background: #f6be26; }

.toast-success .toast-message-icon { background: #39d884; }

.toast-error .toast-message-icon { background: #e6604a; }

@media (min-width: 576px) { .toast-message { font-size: 14px; line-height: 18px; } }

/** 03.10 Misc */
.img-xs { height: 40px; width: 40px; }

.text-thumb { display: inline-flex; justify-content: center; align-items: center; border-radius: 50%; color: #fff; font-weight: 500; letter-spacing: 0.1em; border: 2px solid #fff; background: #866bfe; }

.text-thumb-xs { height: 40px; width: 40px; }

.icon { display: inline-block; }

.icon-lg { font-size: 50px; }

.icon-round { display: inline-block; text-align: center; border-radius: 50%; background: rgba(134, 107, 254, 0.1); }

.icon-round.icon-lg { height: 90px; width: 90px; line-height: 90px; }

.icon-circle { display: inline-flex; align-items: center; justify-content: center; height: 38px; width: 38px; border-radius: 50%; }

.icon-circle-md { height: 50px; width: 50px; }

.icon-border { border: 1px solid #fff; }

.icon-animation { position: relative; }

.icon-animation:after { position: absolute; content: ""; top: -8px; right: -8px; bottom: -8px; left: -8px; border-radius: 50%; border: 1px solid #fff; animation: 1s ease 0s normal none infinite running playbutton; }

.icon-list li { display: inline-block; margin: 0 5px; }

.icon-currency-list { display: flex; justify-content: center; align-items: center; }

.icon-bg { min-width: 40px; min-height: 40px; background: #8c8c8c; color: #fff; text-align: center; line-height: 40px; font-size: 1.7rem; border-radius: 50%; margin-right: 8px; }

.has-rtl .icon-bg { margin-right: 0; margin-left: 8px; }

.icon-bg-md { min-height: 32px; min-width: 32px; line-height: 32px; font-size: 1.5rem; }

.icon-bg-lg { min-width: 60px; min-height: 60px; line-height: 60px; font-size: 2rem; }

.icon-bg-xl { min-width: 92px; min-height: 92px; line-height: 92px; font-size: 4rem; }

.icon-bg-eth { background: #61688f; }

.icon-bg-btc { background: #f9931a; }

.icon-bg-ltc { background: #838383; }

.icon-bg-dash { background: #3573c0; }

.icon-bg-xrp { background: #1c1c1c; }

.icon-bg-xmr { background: #ff9f17; }

.toggle-wrap { position: relative; }

.toggle-tigger { display: inline-block; }

.toggle-tigger.btn { display: inline-flex; }

.toggle-class { visibility: hidden; opacity: 0; transition: all .4s; }

.toggle-class.active { visibility: visible; opacity: 1; }

.toggle-drop { position: absolute; left: 50%; top: 100%; transform: translateX(-50%); margin-top: -10px; z-index: 9; }

.toggle-drop-left { left: 0; transform: translateX(0); }

.toggle-drop-right { right: 0; transform: translateX(0); }

.toggle-drop-top { bottom: 100%; top: auto; margin-bottom: 10px; }

.toggle-drop.active { margin-top: 0; margin-bottom: 0; }

@media (min-width: 992px) { .toggle-drop-right { left: auto; } }

@media (max-width: 991px) { .toggle-class-lg { position: static; margin-top: 0; display: block; visibility: visible; opacity: 1; }
  .toggle-wrap-nav { width: 100%; display: flex; }
  .toggle-wrap-nav .toggle-class-lg { margin-left: 20px; flex-grow: 1; }
  .toggle-wrap-nav .toggle-class-lg .drop-list { max-width: 100%; } }

.drop-wrap { position: relative; }

.drop-list { min-width: 160px; max-width: 160px; background: #fff; padding: 10px 0; transition: all .4s; border-radius: 4px; box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.08); }

.drop-list a { font-size: 13px; line-height: 15.6px; color: #415076; padding: 7px 20px; display: block; position: relative; }

.drop-list a .badge { margin-left: 5px; position: relative; top: -1px; }

.tc-light:not(.btn) .drop-list a { color: #415076; }

.drop-list a:hover { color: #866bfe; }

.drop-list-xs { min-width: 60px; max-width: 60px; }

.drop-list-xs a { padding: 7px 8px; }

.drop-list-mb { min-width: 80px; max-width: 80px; }

.drop-list-mb a { padding: 7px 14px; }

.drop-list-sm { min-width: 110px; max-width: 110px; }

.drop-list-full { min-width: 100%; max-width: 100%; }

.drop-list-center { text-align: center; }

.breadcumb { margin: 0 -13px; }

.breadcumb li { display: inline-block; padding: 0 15px; font-size: .75rem; line-height: 18px; position: relative; color: #415076; }

.breadcumb li:not(:last-child):after { position: absolute; top: 0; right: -3px; content: '-'; line-height: 18px; color: #866bfe !important; }

.breadcumb li a { color: #866bfe !important; }

.breadcumb li a:hover:not([class]) { color: #2bc9b4 !important; }

.preloader { position: fixed !important; top: 0; left: 0; right: 0; bottom: 0; z-index: 10000; }

.preloader:before, .preloader:after { position: absolute; left: 0; height: 51%; width: 100%; transition: all .6s ease; background: #fff; content: ''; }

.preloader:before { top: 0; }

.page-loaded .preloader:before { transform: translate(0, -100%); }

.preloader:after { bottom: 0; }

.page-loaded .preloader:after { transform: translate(0, 100%); }

.preloader.no-default { background-color: #3c1c94; }

.page-loaded .preloader.no-default:before, .page-loaded .preloader.no-default:after { transform: translate(0, 0); }

.preloader.no-default:before, .preloader.no-default:after { position: absolute; top: 0; left: 0; height: 100%; transition: all .6s; background-color: #3c1c94; }

.preloader-alt:after, .preloader-alt:before { background: #6e19c2; }

.page-loaded .preloader.no-split:before, .page-loaded .preloader.no-split:after { transform: translate(0, 0); }

.spinner { display: block; position: relative; left: 50%; top: 50%; width: 70px; height: 70px; margin: -35px 0 0 -35px; z-index: 1001; }

.spinner-round { border-radius: 50%; border: 2px solid transparent; border-top-color: #866bfe; animation: spin 2s linear infinite; }

.spinner-round:before, .spinner-round:after { content: ""; position: absolute; border-radius: 50%; border: 3px solid transparent; }

.spinner-round:before { top: 5px; left: 5px; right: 5px; bottom: 5px; border-top-color: #2bc9b4; animation: spin 3s linear infinite; }

.spinner-round:after { top: 15px; left: 15px; right: 15px; bottom: 15px; border-top-color: #3c1c94; animation: spin 1.5s linear infinite; }

.spinner-alt { border-radius: 50%; width: 150px; height: 150px; margin: -75px 0 0 -75px; }

.spinner-alt:after, .spinner-alt:before { content: ""; position: absolute; border-radius: 50%; top: 0; left: 0; right: 0; bottom: 0; }

.spinner-alt:before { border: 1px solid rgba(255, 255, 255, 0.1); }

.spinner-alt:after { border: 1px solid; border-top-color: #fff; border-right-color: transparent; border-left-width: 0; border-bottom-width: 0; animation: spin 1.5s linear infinite; }

.spinner-alt.load-done { transition: all .4s; transform: scale(3); opacity: 0; border-color: transparent; }

.spinner-alt.load-done:after, .spinner-alt.load-done:before { opacity: 0; }

.spinner-alt.spinner-light { border: 1px solid rgba(10, 16, 21, 0.1); }

.spinner-alt.spinner-light:after { border-top-color: #0a1015; }

.spinner-brand { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100px; }

@keyframes spin { 0% { -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

.text-zoom { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; text-align: center; text-transform: uppercase; font-weight: 700; height: 100px; width: 100px; }

.text-item { position: absolute; top: 0; bottom: 0; left: 0; right: 0; }

.text-item:nth-child(2) > * { animation-delay: 1s; }

.text-item:nth-child(3) > * { animation-delay: 2s; }

.text-item:nth-child(4) > * { animation-delay: 3s; }

.text-item:nth-child(5) > * { animation-delay: 4s; }

.text-item:nth-child(6) > * { animation-delay: 5s; }

.text-item:nth-child(7) > * { animation-delay: 6s; }

.text-item:nth-child(8) > * { animation-delay: 7s; }

.text-item:nth-child(9) > * { animation-delay: 8s; }

.text-small, .text-large { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); transform-origin: center center; opacity: 0; }

.text-small { color: #97afd5; z-index: 2; font-size: 60px; animation: textzoom9 9s linear infinite; }

.text-large { color: #19243e; z-index: 0; font-size: 120px; animation: textzoom9l 9s linear infinite; }

@keyframes textzoom9 { 0% { transform: translate(-50%, -50%) scale(0);
    opacity: 0; }
  6% { transform: translate(-50%, -50%) scale(1);
    opacity: 1; }
  10%, 100% { transform: translate(-50%, -50%) scale(1.3);
    opacity: 0; } }

@keyframes textzoom9l { 0% { transform: translate(-50%, -50%) scale(0.2);
    opacity: 0; }
  8% { transform: translate(-50%, -50%) scale(1.2);
    opacity: 1; }
  10%, 100% { transform: translate(-50%, -50%) scale(1.7);
    opacity: 0; } }

.modal-dialog { padding: 15px; }

.modal-dialog-bottom { display: flex; align-items: flex-end; min-height: calc(100% - (.5rem * 2)); }

.modal.fade .modal-dialog-bottom { -webkit-transform: translate(0, 25%); transform: translate(0, 25%); }

.modal.show .modal-dialog-bottom { -webkit-transform: translate(0, -30px); transform: translate(0, -30px); }

.modal-content { background: none; border: none; margin-left: auto; margin-right: auto; width: auto; max-width: 100%; }

.modal-body { background: #fff; border-radius: 6px; }

@media (min-width: 575px) { .modal-dialog { padding: 0; } }

.modal-close { position: absolute; cursor: pointer; top: -15px; right: -15px; height: 36px; width: 36px; line-height: 39px; padding: 0; text-align: center; z-index: 9999; border-radius: 50%; color: #415076; opacity: 1; background: #fff; box-shadow: 0px 2px 7px 0px rgba(65, 80, 118, 0.2); }

.mfp-close-btn-in .mfp-close { cursor: pointer; top: -15px; right: -15px; height: 36px; width: 36px; line-height: 37px; padding: 0; text-align: center; z-index: 9999; border-radius: 50%; color: #415076 !important; opacity: 1; background: #fff; box-shadow: 0px 2px 7px 0px rgba(65, 80, 118, 0.2); transition: all .4s; }

.mfp-close-btn-in .mfp-close:hover { text-decoration: none; color: #866bfe !important; }

.tc-light .mfp-close-btn-in .mfp-close:hover { color: #866bfe !important; }

.image-popup .mfp-close { cursor: pointer; top: 22px; right: -18px; }

.owl-nav { margin: 0 -10px 0; padding-top: 40px; }

.carousel-nav-s1 .owl-nav { padding-top: 0px; }

.carousel-nav-center .owl-nav { display: flex; justify-content: center; }

.owl-nav button { border: 1px solid #af9cff !important; border-radius: 50% !important; height: 40px; width: 40px; display: inline-flex; align-items: center; justify-content: center; margin: 0 10px; transition: all .4s; }

.carousel-nav-s1 .owl-nav button { border-width: 0 !important; margin: 0; }

.owl-nav button:focus { box-shadow: none; outline: none; }

.owl-nav button.disabled { opacity: 0.4; }

.owl-nav button:after { font-family: "themify"; font-size: 1rem; }

.carousel-nav-s1 .owl-nav button:after { font-family: "Font Awesome 5 Free"; font-weight: 700; }

.carousel-nav-light .owl-nav { color: #fff; }

/* .owl-prev:after { content: '\e64a'; } */


.owl-rtl .owl-prev:after { content: '\e649'; }

.carousel-nav-s1 .owl-prev:after { content: '\f060'; }

.carousel-nav-s1.owl-rtl .owl-prev:after { content: '\f061'; }

/* .owl-next:after { content: '\e649'; } */

.owl-rtl .owl-next:after { content: '\e64a'; }

.carousel-nav-s1 .owl-next:after { content: '\f061'; }

.carousel-nav-s1.owl-rtl .owl-next:after { content: '\f060'; }

/* .owl-dot { height: 6px; width: 12px; border-radius: 3px; display: inline-block; margin: 0 4px; transition: all .4s ease; background: rgba(134, 107, 254, 0.3) !important; } */

/* .owl-dot.active { background: rgba(134, 107, 254, 0.8) !important; width: 20px; } */

.owl-dot:focus { outline: none; }

.owl-dots { text-align: center; }

.carousel-shadow-fix .owl-stage-outer { margin: 0 -30px; margin-top: -20px; padding-left: 40px; }

.carousel-shadow-fix.feature-carousel-center .owl-stage-outer { padding-left: 30px; }

.carousel-shadow-fix.owl-rtl .owl-stage-outer { padding-left: 0; padding-right: 30px; }

.carousel-shadow-fix .owl-item { padding: 20px 0 40px; }

.carousel-shadow-fix .owl-item > div { transition: all .4s; opacity: 0; }

.carousel-shadow-fix .owl-item.active > div { opacity: 1; }

.carousel-shadow-fix .owl-item:not(.active) [class*='shadow'] { box-shadow: none !important; }

.carousel-shadow-fix .owl-item .feature { margin-bottom: 0; }

.ovm-line { position: relative; width: 64%; max-width: 1280px; height: 100%; margin-left: auto; margin-right: auto; border-left: 1px solid rgba(255, 255, 255, 0.05); border-right: 1px solid rgba(255, 255, 255, 0.05); }

.ovm-line:before, .ovm-line:after { position: absolute; content: ''; height: 100%; top: 0; left: 50%; transform: translateX(-50%); }

.ovm-line:before { width: 1px; background: rgba(255, 255, 255, 0.05); }

@media (min-width: 992px) { .ovm-line:after { width: 50%; border-left: 1px solid rgba(255, 255, 255, 0.05); border-right: 1px solid rgba(255, 255, 255, 0.05); } }

@keyframes rotatecw { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

@keyframes rotateacw { 0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); } }

.nk-circle-animation { height: 300px; width: 300px; z-index: 1; }

.nk-circle-animation + * { position: relative; z-index: 1; }

.nk-circle-animation:before, .nk-circle-animation:after { position: absolute; content: ''; border: 4px solid rgba(175, 156, 255, 0.3); border-radius: 50%; }

.nk-circle-animation:before { top: 0; left: 0; bottom: 0; right: 0; border-top-color: #866bfe; border-right-color: #866bfe; animation: rotatecw 30s linear infinite 0s; }

.nk-circle-animation:after { top: 25px; left: 25px; bottom: 25px; right: 25px; border-top-color: #866bfe; border-bottom-color: #866bfe; animation: rotateacw 24s linear infinite 0s; }

.nk-circle-animation.small:before, .nk-circle-animation.small:after { border-width: 2px; }

.nk-circle-animation.fast:before { animation: rotatecw 15s linear infinite 0s; }

.nk-circle-animation.fast:after { animation: rotateacw 10s linear infinite 0s; }

.nk-circle-animation.white:before { border-top-color: #fff; border-right-color: #fff; }

.nk-circle-animation.white:after { border-top-color: #fff; border-bottom-color: #fff; }

.nk-circle-animation.accent-alt:before { border-top-color: #2bc9b4; border-right-color: #2bc9b4; }

.nk-circle-animation.accent-alt:after { border-top-color: #2bc9b4; border-bottom-color: #2bc9b4; }

.nk-circle-pk { position: relative; height: 565px; width: 640px; }

/* .nk-circle-pk [class*="line"] { position: absolute; top: 0; border-radius: 50%; border: 1px solid #866bfe96; height: 100%; width: calc(100% - 80px); }

.nk-circle-pk .line-1 { left: 0; }

.nk-circle-pk .line-2 { left: 20px; }

.nk-circle-pk .line-3 { left: 45px; }

.nk-circle-pk .line-4 { left: 65px; } */

.token-status .nk-circle-pk { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: none; }

@media (min-width: 576px) { .nk-circle-animation { height: 500px; width: 500px; overflow: hidden; }
  .nk-circle-animation:after { top: 45px; left: 45px; bottom: 45px; right: 45px; }
  .nk-circle-animation.small { height: 400px; width: 400px; }
  .nk-circle-animation.small:after { top: 35px; left: 35px; bottom: 35px; right: 35px; } }

@media (min-width: 992px) { .token-status .nk-circle-pk { display: block; }
  .icon-bg { margin-right: 20px; }
  .has-rtl .icon-bg { margin-right: 0px; margin-left: 20px; } }

.circle-animation { position: absolute; height: 700px; width: 700px; top: -75px; left: 50%; transform: translateX(-50%); }

.circle-animation .ca { position: absolute; border-radius: 50%; top: 50%; left: 50%; transform: translate(-50%, -50%); border: 2px solid rgba(65, 80, 118, 0.15); }

.tc-light .circle-animation .ca { border-color: rgba(255, 255, 255, 0.1); }

.circle-animation .ca-dot { position: absolute; height: 12px; width: 12px; border-radius: 50%; }

.circle-animation .ca-color-1 { background: #86cfff; }

.circle-animation .ca-color-2 { background: #859fff; }

.circle-animation .ca-color-3 { background: #93a6ba; }

.circle-animation-l1 { height: 100%; width: 100%; animation: rotatecw_alt 78s linear infinite 0s; }

.circle-animation-l1-d1 { left: 23%; top: 6.35%; }

.circle-animation-l1-d2 { right: 23%; top: 6.35%; }

.circle-animation-l1-d3 { right: -7px; top: 50%; }

.circle-animation-l1-d4 { right: 23%; bottom: 6.35%; }

.circle-animation-l1-d5 { left: 23%; bottom: 6.35%; }

.circle-animation-l1-d6 { left: -7px; top: 50%; }

.circle-animation-l2 { height: 84.6%; width: 84.6%; border-radius: 50%; animation: rotateacw_alt 66s linear infinite 0s; }

.circle-animation-l2-d1 { right: 4.9%; top: 25%; }

.circle-animation-l2-d2 { left: 42%; top: -.6%; }

.circle-animation-l2-d3 { left: -2px; top: 40%; }

.circle-animation-l2-d4 { left: 24%; bottom: 5.5%; }

.circle-animation-l2-d5 { right: 11.2%; bottom: 16%; }

.circle-animation-l3 { height: 69.2%; width: 69.2%; animation: rotatecw_alt 54s linear infinite 0s; }

.circle-animation-l3-d1 { left: 7.6%; top: 20%; }

.circle-animation-l3-d2 { right: 24%; top: 5.2%; }

.circle-animation-l3-d3 { right: -3px; bottom: 39%; }

.circle-animation-l3-d4 { right: 37%; bottom: 0; }

.circle-animation-l3-d5 { left: 8.4%; bottom: 19%; }

@keyframes rotatecw_alt { 0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes rotateacw_alt { 0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(-360deg); } }

@keyframes rotatecw { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

@keyframes rotateacw { 0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); } }

.social li { text-align: center; display: inline-block; padding: 0 3px; }

.social li:first-child { padding-left: 0; }

.social li a { display: inline-flex; align-items: center; transition: all 0.3s; width: auto; }

.social li a:hover { opacity: 1; transform: translateX(0); }

.social li a:hover .social-icon { background: #2bc9b4; color: #fff; }

.tc-light .social li a:hover .social-icon { background: #866bfe; }

.social li a:hover .social-icon + span { color: #fff; }

.social-icon { width: 32px; height: 32px; line-height: 32px; border-radius: 50%; transition: 0.3s; color: #415076; background: #fff; }

.tc-light .social-icon { color: #fff; background: rgba(255, 255, 255, 0.15); }

.social-icon-transparent { background: transparent; }

.tc-light .social-icon-transparent { background: transparent; }

.social-icon + span { display: none; margin-left: 8px; font-weight: 600; text-transform: uppercase; font-size: 12px; color: #866bfe; transition: all 0.3s; }

.social-bar { display: flex; flex-wrap: wrap; }

.social-bar li { flex-grow: 1; text-align: center; line-height: 30px; }

.social-bar li:not(:last-child) { border-right: 1px solid #f0f5fc; }

.social-bar li span { font-size: 14px; text-transform: uppercase; }

.social-bar li a, .social-bar li span { display: block; padding: 7px 10px; }

.social-bar-title { width: 100%; border-bottom: 1px solid #f0f5fc; }

@media (min-width: 576px) { .social-icon + span { display: inline-block; }
  .social-bar li a, .social-bar li span { padding: 20px; }
  .social-bar-title { width: auto; border-bottom: none; } }

@media (min-width: 992px) { .social-bar li a { font-size: 20px; }
  .social-bar li a, .social-bar li span { padding: 40px 20px; } }

.language-switcher .toggle-tigger { color: #415076; position: relative; }

.is-transparent:not(.has-fixed) .header-logo .language-switcher .toggle-tigger { color: #fff; }

.language-switcher .toggle-tigger:not(.btn) { padding: 8px 15px 8px 10px; }

.language-switcher .toggle-tigger:not(.btn):after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); content: '\e64b'; font-family: 'themify'; font-size: 8px; font-weight: 700; transition: transform .4s; }

.language-switcher-s1 .toggle-tigger { font-size: 13px; font-weight: 500; }

.language-switcher-s2 .toggle-tigger:after { right: 14px; }

.language-switcher-s2 .toggle-tigger:not(.btn) { padding: 3px 26px 3px 16px; }

.language-switcher-s3 { border-left: 1px solid rgba(255, 255, 255, 0.15); text-transform: uppercase; font-size: .88rem; margin-left: 15px; }

.language-switcher-s3 .toggle-tigger:not(.btn) { padding: 2px 20px 2px 15px; }

.language-switcher-s4 { position: relative; }

@media (min-width: 992px) { .language-switcher-s4:after { position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 1px; height: 18px; content: ''; background: rgba(255, 255, 255, 0.15); } }

.language-switcher-s4 .toggle-tigger { font-size: 13px; font-weight: 500; }

.language-switcher-s5 .toggle-tigger { font-size: 12px; font-weight: 500; text-transform: uppercase; }

.language-flag { width: 24px; margin: 3px 8px 3px 0; }

.language-flag-lg { width: 36px; }

.gfx { max-width: 400px; }

.gfx-auto { margin-left: auto; margin-right: auto; }

.gfx-inherit { max-width: 420px; }

.gfx-md { max-width: 380px; }

@media (min-width: 768px) { .gfx { max-width: 500px; }
  .gfx-inherit { max-width: 420px; }
  .gfx-md { max-width: 380px; } }

@media (min-width: 992px) { .gfx-re-s1 { margin: 0 -230px 0 0; } }

@media (min-width: 1600px) { .gfx-re-lgl { margin-left: -188px; }
  .gfx-re-lg { margin: 0 -168px 0 42px; } }

@media (min-width: 1900px) { .gfx-re-lgl { margin-left: -315px; }
  .gfx-re-lgr { margin-right: -315px; }
  .gfx-re-mdl { margin-left: -230px; }
  .gfx-re-lg { margin: 0 -200px 0 42px; } }

.piller-one, .piller-two { position: absolute; z-index: -1; }

.piller-one { width: 250px; top: 20%; right: 100%; margin-right: 65px; }

.piller-two { width: 200px; left: 100%; top: 50%; margin-left: 40px; }

.gfx-round-bg { min-width: 300px; min-height: 300px; background: #f7f8ff; border-radius: 50%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: -1; }

.gfx-round-bg.after:after { position: absolute; content: ""; left: 44px; top: 0; width: 100%; height: 100%; border: 1px solid #2bc9b4; border-radius: 50%; }

@media (min-width: 768px) { .gfx-round-bg { min-width: 400px; min-height: 400px; } }

@media (min-width: 1200px) { .gfx-round-bg { min-width: 480px; min-height: 480px; } }

@media (min-width: 1600px) { .gfx-round-bg { min-width: 600px; min-height: 600px; } }

@media (min-width: 1900px) { .gfx-round-bg { min-width: 660px; min-height: 660px; }
  .gfx-round-bg-md { min-width: 557px; min-height: 557px; } }

/** 04. Components */
/** 04.01 Tab */
.tab-nav li a { display: block; padding: 12px 12px; line-height: 19px; font-weight: 400; color:#fff; border-bottom: 1px solid rgba(219, 228, 247, 0.5); transition: all .4s; }

.tab-nav li a:hover, .tab-nav li a.active { color: #6e19c2; }

.tab-nav li a .fas, .tab-nav li a .far { margin-right: 10px; }

.tab-nav-sap { flex-grow: 1; height: 2px; background: rgba(114, 91, 214, 0.4); align-self: center; margin: 0 -7px; }

.tc-light .tab-nav-sap { background: rgba(114, 91, 214, 0.4); }

.tab-nav-vr { display: block; }

.tab-nav-vr li:not(:last-child) a { border-bottom: 1px solid rgba(219, 228, 247, 0.5); }

.tab-nav-vr li:last-child a { border: none; }

.tab-nav-vr li a { padding: 18px 20px; }

.tc-light .tab-nav-vr li:not(:last-child) a { border-color: rgba(255, 255, 255, 0.07); }

.tab-nav-s2 { margin: 0 -15px 15px; }

.tab-nav-s2 li { padding: 0 15px; }

.tab-nav-s2 li a { font-size: 18px; letter-spacing: 0.01em; text-transform: uppercase; padding: 12px 5px; border-bottom-color: transparent; }

.tc-light .tab-nav-s2 li a { color: rgba(255, 255, 255, 0.5) !important; }

.tab-nav-s2 li a:hover, .tab-nav-s2 li a.active { color: #866bfe; border-bottom-color: #866bfe; }

.tab-nav-s2 li a .fas, .tab-nav-s2 li a .far { margin-right: 10px; }

.tab-nav-s3 li a { border: none !important; border-radius: 3px; cursor: pointer; padding: 28px 30px; }

.tab-nav-s3 li a [class*=title] { color: #5b53a2; transition: all .4s; }

.tc-light .tab-nav-s3 li a [class*=title] { color: #fff; }

.tab-nav-s3 li a p { color: #415076; line-height: 1.6; }

.tc-light .tab-nav-s3 li a p { color: #af9cff; }

.tab-nav-s3 li a.active { box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.08); background: #fff; cursor: default; }

.tab-nav-s3 li a.active [class*=title] { color: #866bfe; }

.tc-light .tab-nav-s3 li a.active { background: #6e19c2; }

.bg-theme-alt .tab-nav-s3 li a.active { background: #3c1c94; }

.tab-nav-s4 { border: none; padding: 5px; border-radius: 30px; display: inline-block; }

.tab-nav-s4 li { display: inline-block; }

.tab-nav-s4 li a { border: none; line-height: 24px; background: transparent; font-weight: 600; color: #fff; font-size: 11px; padding: 10px 22px; border-radius: 25px; font-weight: 600; display: inline-block; }

.tab-nav-s4 li a.active { color: #3c1c94 !important; background: #fff; }

.tc-light .tab-nav-s4 li a.active:hover { color: tab_nav_s4_active_color !important; }

.tc-light .tab-nav-s4 li a:hover { color: #fff !important; }

.tab-nav-center { justify-content: center; }

.tab-nav-bdr { border: 1px solid #dbe4f7; border-radius: 5px; }

.tc-light .tab-nav-bdr { border-color: rgba(255, 255, 255, 0.07); background: rgba(255, 255, 255, 0.02); }

.tab-nav-line { margin-bottom: 20px; border-bottom: 2px solid rgba(219, 228, 247, 0.5); text-align: center; justify-content: center; }

.tab-nav-line li a { border-bottom: none; position: relative; }

.tab-nav-line li a:after { position: absolute; width: 100%; height: 2px; left: 0; bottom: -2px; content: ''; background: #07bcff; opacity: 0; transition: all .4s; }

.tab-nav-line li a:hover, .tab-nav-line li a.active { color: #07bcff; }

.tab-nav-line li a.active:after { opacity: 1; }

.tc-light .tab-nav-line { border-bottom-color: rgba(219, 228, 247, 0.5); }

.tc-light .tab-nav li a:after { background: #866bfe; }

.tc-light .tab-nav li a:hover, .tc-light .tab-nav li a.active { color: #866bfe !important; }

.tab-nav-btn { margin: -7px -7px 8px; }

.tab-nav-btn li:not(.tab-nav-sap) { padding: 7px; }

.tab-nav-btn li a { border-radius: 3px; color: #415076; background: rgba(60, 28, 148, 0.1); border-color: transparent; padding: 10px 18px; }

.tc-light .tab-nav-btn li a { color: #415076; background: rgba(255, 255, 255, 0.1); border-color: transparent; }

.tab-nav-btn li a:hover, .tab-nav-btn li a.active { color: #866bfe !important; background: rgba(60, 28, 148, 0.1); }

.tc-light .tab-nav-btn li a:hover, .tc-light .tab-nav-btn li a.active { color: #866bfe !important; background: rgba(134, 107, 254, 0.1); }

.tab-nav-btn-s2 li a:hover, .tab-nav-btn-s2 li a.active { color: #fff !important; background: #866bfe; }

.tc-light .tab-nav-btn-s2 li a:hover, .tc-light .tab-nav-btn-s2 li a.active { color: #866bfe !important; background: rgba(60, 28, 148, 0.1); }

.tab-nav-btn-bdr { margin: -7px; }

.tab-nav-btn-bdr li:not(.tab-nav-sap) { padding: 7px; }

.tab-nav-btn-bdr li a { border-radius: 3px; color: #415076 !important; background: transparent; border: 1px solid !important; border-color: #415076 !important; }

.tc-light .tab-nav-btn-bdr li a { color: #fff !important; background: transparent; border-color: rgba(114, 91, 214, 0.4) !important; }

.tab-nav-btn-bdr li a:hover, .tab-nav-btn-bdr li a.active { color: #866bfe !important; background: transparent; border-color: #866bfe !important; }

.tc-light .tab-nav-btn-bdr li a:hover, .tc-light .tab-nav-btn-bdr li a.active { color: #866bfe !important; background: transparent; border-color: #866bfe !important; }

.tab-nav-btn-bdr-s2 { margin: -7px; }

.tab-nav-btn-bdr-s2 li:not(.tab-nav-sap) { padding: 7px; }

.tab-nav-btn-bdr-s2 li a { text-transform: uppercase; letter-spacing: 0.2em; font-weight: 700; font-size: .88rem; padding: 14px 22px; border-radius: 6px; color: #415076 !important; background: transparent; border: 2px solid !important; border-color: rgba(114, 91, 214, 0.4) !important; }

.tc-light .tab-nav-btn-bdr-s2 li a { color: #fff !important; background: transparent; border-color: rgba(114, 91, 214, 0.4) !important; }

.tab-nav-btn-bdr-s2 li a:hover, .tab-nav-btn-bdr-s2 li a.active { color: #866bfe !important; background: transparent; border-color: #866bfe !important; }

.tc-light .tab-nav-btn-bdr-s2 li a:hover, .tc-light .tab-nav-btn-bdr-s2 li a.active { color: #866bfe !important; background: transparent; border-color: #866bfe !important; }

.tab-nav-grad li a { position: relative; }

.tc-light .tab-nav-grad li a { background: rgba(255, 255, 255, 0.1); }

.tab-nav-grad li a:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; content: ''; z-index: -1; border-radius: 3px; background: linear-gradient(-45deg, #3c1c94 0%, #6e19c2 100%); opacity: 0; transition: all .4s; }

.tc-light .tab-nav-grad li a:before { background: linear-gradient(-45deg, #866bfe 0%, #2bc9b4 100%) !important; }

.tab-nav-grad li a:hover, .tab-nav-grad li a.active { color: #fff !important; background: transparent; }

.tab-nav-grad li a:hover:before, .tab-nav-grad li a.active:before { opacity: 1; }

.tc-light .tab-nav-grad li a:hover, .tc-light .tab-nav-grad li a.active { color: #fff !important; }

.tab-pane { padding: 10px 0 0; }

@media (min-width: 576px) { .tab-nav-line > li { flex-grow: 1; }
  .tab-nav-s4 li a { font-size: 14px; padding: 13px 25px; } }

/** 04.02 Accordion */
.accordion-s1 .accordion-item { border: none; }

.accordion-s2 .accordion-item { background: transparent; border: none; }

.accordion-item { transition: all .4s; padding-bottom: 8px; }

/* .accordion-item:not(:last-child) { border-bottom: 1px solid #dbe4f7; } */

.tc-light .accordion-item:not(:last-child) { border-color: rgba(255, 255, 255, 0.05); }

.bg-theme .accordion-item:not(:last-child) { border-color: rgba(134, 107, 254, 0.3); }

.accordion-item-s2 { border-width: 2px !important; border: 2px solid #dbe4f7 !important; border-radius: 6px; }

.tc-light .accordion-item-s2 { border-color: rgba(255, 255, 255, 0.05) !important; }

.bg-theme .accordion-item-s2 { border-color: rgba(134, 107, 254, 0.3) !important; }

.accordion-item-s2.current { border-color: rgba(60, 28, 148, 0.5) !important; }

.tc-light .accordion-item-s2.current { border-color: #866bfe !important; }

.accordion-item-s2:not(:last-child) { margin-bottom: 15px; }

.tc-light .accordion-item-s2:not(:last-child) { border-color: rgba(255, 255, 255, 0.05); }

.bg-theme .accordion-item-s2:not(:last-child) { border-color: rgba(134, 107, 254, 0.3); }

.accordion-item-s3 { border: none !important; border-radius: 6px; background: #fff; box-shadow: 0px 4px 6px 0px rgba(60, 28, 148, 0.07); }

.bg-theme .accordion-item-s3, .tc-light .accordion-item-s3 { background: rgba(255, 255, 255, 0.05); box-shadow: none; }

.has-bg-image .accordion-item-s3 { background: rgba(24, 7, 71, 0.7); box-shadow: none; }

.accordion-item-s3:not(:last-child) { margin-bottom: 30px; }

.accordion-item-s4 { background: rgba(60, 28, 148, 0.09); }

.accordion-item-s4.current { background:#0b0c22; }

.accordion-title { position: relative; color:#07bcff; font-weight: 400; cursor: pointer; padding: 22px 54px 14px 0; margin-bottom: 0; }

.accordion-item-s2 .accordion-title { padding: 25px 54px 17px 30px; }

.accordion-item-s3 .accordion-title { padding: 20px 54px 12px 30px; }

.accordion-item-s4 .accordion-title { padding: 16px 54px 8px 30px; }

.tc-light .accordion-title { color: #866bfe; }

.accordion-title-sm { font-size: 16px; }

.accordion-title.collapsed { color: #fff; transition: all .4s; }

.tc-light .accordion-title.collapsed { color: #fff; }

.tc-light .accordion-title.collapsed:hover { color: #866bfe; }

.accordion-title.collapsed:hover { color: #fff; }

.accordion-title.collapsed .accordion-icon:before { opacity: 0; }

.accordion-title.collapsed .accordion-icon:after { opacity: 1; }

.accordion-title.collapsed .accordion-icon-s2:after { transform: rotate(-180deg); }

.accordion-icon { position: absolute; top: calc(50% + 4px); transform: translateY(-50%); right: 0; height: 36px; width: 36px; border-radius: 50%; background: #f7f8ff; }

.current .accordion-icon { background: transparent !important; }

.accordion-icon:before, .accordion-icon:after { line-height: 36px; color: #07bcff; font-family: "Font Awesome 5 Free"; font-weight: 700; font-size: 13px; text-align: center; transition: all .4s;background: transparent; }

.current .accordion-icon:before, .current .accordion-icon:after { color: #07bcff; }

.accordion-icon:before { content: '\f068'; opacity: 1; }

.accordion-icon:after { content: '\f067'; opacity: 0; }

.accordion-icon-s2 { background: transparent !important; }

.accordion-icon-s2:after { font-family: "themify"; content: '\e648'; opacity: 1 !important; }

.accordion-icon-s2:before { display: none; }

.accordion-item-s2 .accordion-icon { right: 12px; }

.accordion-item-s3 .accordion-icon { right: 18px; }

.accordion-item-s4 .accordion-icon { right: 12px; }

.tc-light .accordion-icon { background: rgba(134, 107, 254, 0.1); }

.tc-light .accordion-icon:after, .tc-light .accordion-icon:before { color: #866bfe; }

.accordion-content { padding-bottom: 12px; padding-right: 46px; }

.accordion-content p:last-child { margin-bottom: 0; }

.accordion-item-s2 .accordion-content, .accordion-item-s3 .accordion-content, .accordion-item-s4 .accordion-content { padding-left: 30px; }

.accordion-item-s4 .accordion-content { padding-bottom: 6px; }

.accordion-faq { margin-top: -15px; }

.accordion-header { padding: 20px; }

.accordion-header p { margin-bottom: 0; }

.accordion-header p:nth-child(2), .accordion-header p:nth-child(3) { display: none; }

.accordion-body { padding: 0 20px 30px; }

.accordion-box:not(:last-child) { margin-bottom: 40px; }

.border-b-none { border-bottom: 0px !important; }

@media (min-width: 576px) { .accordion-title-sm { font-size: 18px; }
  .accordion-header { padding: 20px 40px; }
  .accordion-header p { margin-bottom: 0; }
  .accordion-header p:nth-child(2), .accordion-header p:nth-child(3) { display: block; }
  .accordion-body { padding: 0 40px 40px; } }

/** 04.03 Blocks */
.nk-block { display: block; width: 100%; position: relative; z-index: 1; }

.nk-block ~ .nk-block { padding-top: 40px; }

.nk-block ~ .nk-block-team-list { padding-top: 60px; }

.overlay-x .nk-block { z-index: 2; }

.nk-block + .hr { margin-top: 40px; margin-bottom: 40px; }

.section-head + .nk-block, .hr + .nk-block, hr + .nk-block { padding-top: 0; }

.nk-block-lg + .nk-block { padding-top: 60px; }

.nk-block-zero + .nk-block { padding-top: 0 !important; }

.nk-block-text-head { padding-bottom: 35px; }

.nk-block-img { position: relative; }

.nk-block-img-plx { position: absolute; }

.nk-block-img-s1 { position: relative; z-index: 99; margin-top: -71px; padding: 30px 50px; background: #fff; border-radius: 60px 60px 60px 200px; box-shadow: 0 15px 30px rgba(60, 28, 148, 0.1); }

.nk-block-img-s2 img { max-width: 400px; width: 100%; }

.nk-block-img-s3 img { max-width: 300px; width: 100%; }

.nk-block-screen { padding: 70px 50px 100px 0; }

.nk-block-screen img { border-radius: 3px; border: 1px solid #dfeaf8; }

.nk-block-screen-small { position: absolute; right: 0; bottom: 0; max-width: 210px; }

.nk-block-video { position: relative; border-radius: 4px; }

.nk-block-ca { padding: 70px 20px; }

.nk-block-plx { width: 300px; padding: 30px 10px; margin-left: auto; margin-right: auto; }

.nk-block-contact-s1 { padding: 110px 0; position: relative; display: flex; align-items: center; justify-content: center; background: url(../images/dot-c.png) no-repeat; background-size: contain; background-position: 50% 50%; }

.nk-block-m30, .nk-block-team-list, .nk-block-features { margin-bottom: -30px; }

.nk-block-m40 { margin-bottom: -40px; }

.nk-block-m50 { margin-bottom: -50px; }

.nk-block .pagination { margin-top: 10px; }

.nk-block-status + .nk-block-actions { padding-top: 80px; padding-bottom: 40px; }

.nk-block-note { position: relative; background: rgba(0, 0, 0, 0.2); border-radius: 6px; margin-top: 40px; padding: 20px 25px 20px 65px; }

.nk-block-note-s2 { background: rgba(175, 156, 255, 0.25); }

.nk-block-note .icon { position: absolute; left: 15px; top: 50%; font-size: 30px; color: #866bfe; transform: translateY(-50%); }

.nk-block-shade { position: absolute; left: -120px; bottom: 65px; width: 100%; height: calc(100% - 130px); border-radius: 0 0 0 45px; background-image: linear-gradient(to top right, #fff 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%); opacity: .10; transform: skew(9deg); }

.nk-block-shade:after { position: absolute; left: 50px; bottom: 50px; width: 100%; height: 100%; content: ''; border-radius: 0 0 0 45px; background-image: linear-gradient(to top right, #fff 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 100%); }

.nk-block-shape-s1 { position: relative; z-index: 2; padding-top: 80px; padding-bottom: 100px; background: #3c1c94; }

.nk-block-shape-s1:before, .nk-block-shape-s1:after { position: absolute; top: 0; height: 100%; content: ''; z-index: -1; background: #3c1c94; }

.nk-block-shape-s1:before { left: -190px; width: 320px; border-radius: 100px 0 0 100px; transform: skew(9deg); }

.nk-block-shape-s1:after { right: -280px; width: 370px; border-radius: 0 100px 100px 0; transform: skew(-7deg); }

.nk-block-shape-s1.bg-theme-alt { background: #6e19c2; }

.nk-block-shape-s1.bg-theme-alt:before, .nk-block-shape-s1.bg-theme-alt:after { background: #6e19c2; }

.nk-block-shape-s1.bg-theme-dark { background: #180747; }

.nk-block-shape-s1.bg-theme-dark:before, .nk-block-shape-s1.bg-theme-dark:after { background: #180747; }

.nk-block-shape-s1.bg-theme-dark-alt { background: #2e186a; }

.nk-block-shape-s1.bg-theme-dark-alt:before, .nk-block-shape-s1.bg-theme-dark-alt:after { background: #2e186a; }

.nk-block-header { padding-top: 30px; padding-bottom: 30px; }

.plx-screen { width: 80px; bottom: 0; left: 0; }

.plx-circle-s1 { width: 80px; top: 10px; left: -5px; z-index: -1; animation: ani_cicle 4s infinite linear; }

.plx-polygon-s1 { width: 70px; bottom: 15px; left: 40px; transform-origin: center; animation: ani_round 20s infinite linear; }

.plx-triangle-s1 { width: 80px; bottom: 0; right: 0; animation: ani_round 30s infinite reverse linear; }

@keyframes ani_round { 0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(10px, 15px) rotate(90deg); }
  50% { transform: translate(0, 0) rotate(180deg); }
  75% { transform: translate(-10px, -15px) rotate(270deg); }
  100% { transform: translate(0, 0) rotate(360deg); } }

@keyframes ani_cicle { 0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(360deg); } }

.img-block-dot { background-image: url(../images/dot-a.png); background-position: top center; padding: 50px 0; margin-top: -40px; }

@media (min-width: 576px) { .nk-block-screen { padding: 0 50px 100px 0; }
  .nk-block-team-list { margin-bottom: -50px; } }

@media (min-width: 576px) { .nk-block ~ .nk-block { padding-top: 60px; }
  .section-head + .nk-block, .hr + .nk-block, hr + .nk-block { padding-top: 0; }
  .nk-block-xs + .nk-block { padding-top: 30px; }
  .nk-block-lg + .nk-block { padding-top: 80px; }
  .nk-block-ca { padding: 90px 20px; }
  .nk-block-plx { padding: 60px; width: 100%; }
  .nk-block-contact-s1 { padding: 180px 0; }
  .nk-block-img-s1 { padding: 40px 70px; }
  .nk-block-note { margin-top: 40px; padding: 20px 65px 20px 75px; }
  .nk-block-note .icon { left: 20px; }
  .nk-block-shape-s1 { padding-top: 150px; padding-right: 40px; padding-bottom: 150px; }
  .plx-screen { width: 130px; bottom: 0; left: 0; }
  .plx-circle-s1 { width: 140px; top: 10px; left: 10px; }
  .plx-polygon-s1 { width: 100px; bottom: 15px; left: 95px; }
  .plx-triangle-s1 { width: 130px; bottom: 0; right: 20px; } }

@media (min-width: 768px) { .nk-block-img-s2 img { max-width: 100%; }
  .nk-block-ca { padding: 60px; }
  .nk-block-plx { padding: 60px; }
  .plx-screen { width: 160px; bottom: 0; left: 0; }
  .plx-circle-s1 { width: 160px; top: 10px; left: 10px; }
  .plx-polygon-s1 { width: 100px; bottom: 15px; left: 125px; }
  .plx-triangle-s1 { width: 130px; bottom: 0; right: 20px; } }

@media (min-width: 992px) { .nk-block ~ .nk-block { padding-top: 100px; }
  .section-head + .nk-block, .hr + .nk-block, hr + .nk-block { padding-top: 0; }
  .nk-block-status + .nk-block-actions { padding-top: 70px; padding-bottom: 40px; }
  .nk-block-xs + .nk-block { padding-top: 30px; }
  .nk-block-sm + .nk-block { padding-top: 70px; }
  .nk-block-lg + .nk-block { padding-top: 100px; }
  .nk-block-img-s1 { margin-right: -71px; }
  .nk-block-img.edge-r1 { margin-right: -160px; }
  .nk-block-img.edge-l1 { margin-left: -160px; }
  .nk-block-img.edge-r2 { margin-right: -360px; }
  .nk-block-img.edge-l2 { margin-left: -360px; }
  .nk-block-img.edge-r3 { margin-right: -500px; }
  .nk-block-img.edge-l3 { margin-left: -500px; }
  .nk-block .pagination { margin-top: 30px; } }

@media (min-width: 1200px) { .nk-block-lg + .nk-block { padding-top: 130px; } }

.app-slide-wrap { position: relative; width: 230px; margin: 15px auto; }

.app-slide-wrap:after { position: absolute; height: calc(100% + 80px); width: calc(100% + 96px); top: 50%; left: 50%; transform: translate(-50%, -50%); content: ''; background-image: url(../images/light-ix.png); }

.app-slide img { border-radius: 4px; }

/** 04.04 Box */
.card { padding: 20px; background: #fff; border: none; box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.02); }

.card.card-s3 { padding: 30px 20px; }

.card-full { height: calc(100% - 30px); }

.card-full-lg { height: calc(100% - 80px); }

.card.feature-s8 { padding: 35px 25px; }

.card-s2 { padding: 55px 30px 110px; box-shadow: 0px 5px 46px rgba(110, 25, 194, 0.14); }

.card-lg-y { box-shadow: 0px 20px 60px rgba(91, 83, 162, 0.1); }

.card-lg-y2 { padding: 55px 40px 70px; box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.05); }

.card-s3 { box-shadow: 0px 5px 18px rgba(65, 80, 118, 0.2); }

@media (min-width: 576px) { .card { padding: 30px; }
  .card.card-s3 { padding: 30px; }
  .card-md { padding: 40px 30px; }
  .card-lg { padding: 45px 40px; }
  .card.feature-s8 { padding: 45px 30px; }
  .card-s2 { padding: 55px 30px 110px; }
  .card-lg-y { padding: 70px 30px; }
  .card-lg-y2 { padding: 55px 40px 70px; }
  .card-lg-xy { padding: 70px 55px; } }

/** 04.05 Banner */
.nk-banner { display: block; width: 100%; z-index: 5; position: relative; }

.banner { position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; }

.is-transparent .banner { padding-top: 60px; }

.has-mask .banner { padding-bottom: 75px; }

.has-mask .banner-page { padding-bottom: 40px; }

.has-mask .banner-mask-fix { padding-bottom: 40px !important; }

.has-mask .banner-inner { padding-bottom: 125px; }

.header-banner-angled .banner { padding-bottom: 540px; }

.header-banner-angled-s2 .banner { padding-bottom: 498px; }

.banner-screen { position: absolute !important; z-index: 0 !important; left: 50%; right: 0; bottom: -8px; width: 320px; transform: translateX(-50%); }

.banner-wrap { width: 100%; }

.banner-fs .banner-wrap { padding-top: 40px; padding-bottom: 40px; }

.banner-gfx { position: relative; margin: -15px 0; }

.banner-gfx-auto { margin: 0 auto !important; }

.banner-gfx-re-s4 { margin: 0 -95px; }

.banner-gfx-icon { position: absolute; width: 4.2%; top: 32%; }

.banner-gfx-icon-1 { left: 43.75%; animation: jumping 1s linear 0s infinite alternate; }

.banner-gfx-icon-2 { right: 40.25%; animation: jumping 1s linear .5s infinite alternate; }

.banner-gfx-s2 { max-width: 280px; margin: 0 auto; }

.banner-fs { min-height: calc(100vh - 0px); }
/* .banner-fs { min-height: calc(100vh - 90px); } */
.is-transparent .banner-fs { min-height: 100vh; }

.banner-gap-b1 { padding-bottom: 50px; }

.has-mask .banner-gap-b1 { padding-bottom: 75px; }

.banner-gap-b2 { padding-bottom: 75px; }

.has-mask .banner-gap-b2 { padding-bottom: 125px; }

.banner-gap-b3 { padding-bottom: 75px; }

.has-mask .banner-gap-b3 { padding-bottom: 120px; }

.banner-gap-b4 { padding-bottom: 340px; }

.has-mask .banner-gap-b4 { padding-bottom: 360px; }

.banner-page .banner-wrap { padding: 40px 0; }

.is-transparent .banner-page .banner-wrap { padding-top: 20px; }

.banner-page .breadcumb { padding-top: 10px; }

.banner-page-md { min-height: 340px; }

.banner-page-lg { min-height: 460px; }

.banner-caption:not([class*=wide]) { max-width: 720px; margin-left: auto; margin-right: auto; }

.banner > .nk-block { position: relative; z-index: 2; }

.banner-social { background: rgba(255, 255, 255, 0.2); border-radius: 25px; padding: 0px 20px; display: inline-block; }

.banner-social-wrap { margin-top: 30px; }

.banner-social li { padding: 13px 8px; display: inline-block; }

.banner-social li a { color: #fff; text-align: center; line-height: 24px; width: 24px; display: block; }

.banner-social li a:hover { opacity: .5; outline: none; transform: translateY(-3px); }

.banner-with-token-box-s1 .banner-wrap { padding-bottom: 175px; }

.banner-s1-overlay { position: relative; overflow: hidden; }

.banner-s1-overlay:before, .banner-s1-overlay:after { position: absolute; content: ''; width: 875px; height: 300%; bottom: -100%; transform: rotate(-45deg); background: rgba(255, 255, 255, 0.02); z-index: -1; }

.banner-s1-overlay:before { left: 50%; margin-left: -130px; }

.banner-s1-overlay:after { right: 50%; margin-right: -110px; }

.banner-s1 .title-xs { margin-bottom: 1.14rem; }

.banner-inner { min-height: 500px; }

@keyframes jumping { 0% { transform: translateY(5px); }
  100% { transform: translateY(-25px); } }

.cpn { padding-top: 30px; padding-bottom: 30px; }

.cpn-head, .cpn-action, .cpn-text { margin-top: 20px; }

.cpn > *:first-child { margin-top: 0; }

.cpn-head .title { font-size: 1.75rem; line-height: 1.5; }

.cpn-head .title-lg { line-height: 1.3; font-weight: 700; }

.cpn-text { font-weight: 300; font-size: 1.1rem; line-height: 1.8; margin-bottom: 25px; color: #415076; }

.tc-light .cpn-text { color: #af9cff; }

.cpn-text p { color: inherit !important; font-size: inherit !important; }

.cpn-text-center { margin-left: auto; margin-right: auto; }

.cpn-text-s1 { margin-top: 16px; margin-bottom: 16px; }

.cpn-text .lead, .cpn-text .lead-light { font-size: 1.25rem; font-weight: 400; line-height: 1.7; }

.cpn-text .lead-s2 { font-size: 1.1rem; font-weight: 300; line-height: 1.7; }

.cpn-text .lead-s3 { font-size: 1.3rem !important; font-weight: 300; line-height: 1.7; }

.cpn-text-light .lead, .cpn-text-light .lead-light { font-weight: 300; }

.cpn-links { padding-top: 20px; margin-top: -10px; margin-bottom: -10px; margin-left: -15px; margin-right: -15px; }

.cpn-action .cpn-links { padding-top: 0; }

.cpn-links li { display: inline-block; padding: 10px 15px; font-weight: 500; }

.cpn-links-s1 { display: flex; justify-content: space-around; align-items: center; padding: 0 50px; }

.cpn-btns + .cpn-links { padding-top: 20px; }

.cpn-links + .cpn-btns { padding-top: 20px; }

.cpn-boxed { padding: 20px; background: rgba(11, 12, 16, 0.6); }

.cpn-boxed:not([class*='radius']) { border-radius: 6px; }

.cpn-social { margin-top: 30px; }

@media (max-width: 575px) { .cpn-title .title { font-size: 1.75rem; line-height: 1.5; } }

@media (min-width: 576px) { .banner-gap-b3 { padding-bottom: 120px; }
  .has-mask .banner-gap-b3 { padding-bottom: 160px; }
  .banner-screen { bottom: -40px; width: 100%; }
  .banner-screen-right { bottom: -70px; width: 360px; }
  .banner-fs .banner-wrap { padding-top: 70px; padding-bottom: 70px; } }

@media (min-width: 768px) { .banner-gfx-re-s2 { margin: -50px 0 0; }
  .has-mask .banner { padding-bottom: 160px; }
  .has-mask .banner-page { padding-bottom: 80px; }
  .banner-gap-b1 { padding-bottom: 75px; }
  .has-mask .banner-gap-b1 { padding-bottom: 125px; }
  .banner-gap-b2 { padding-bottom: 125px; }
  .has-mask .banner-gap-b2 { padding-bottom: 200px; }
  .banner-gap-b4 { padding-bottom: 340px; }
  .has-mask .banner-gap-b4 { padding-bottom: 360px; }
  .banner-page .banner-wrap { padding: 80px 0; }
  .is-transparent .banner-page .banner-wrap { padding-top: 60px; }
  .banner-page-md { min-height: 400px; }
  .banner-page-lg { min-height: 580px; }
  .banner-social-wrap { margin-top: 0; }
  .banner-social-vertical { right: 60px; top: 50%; transform: translateY(-50%); position: absolute; z-index: 3; padding: 20px 0; margin-top: -30px; }
  .banner-social-vertical li { display: block; padding: 8px 13px; }
  .banner-with-token-box-s1.banner-fs { min-height: 92vh; }
  .banner-with-token-box-s1 .banner-wrap { padding-bottom: 40px; }
  .cpn-text, .cpn-action { margin-top: 20px; }
  .cpn-text { margin-bottom: 15px; }
  .cpn-btns { padding-top: 10px; }
  .cpn-text-s1 { margin-top: 16px; margin-bottom: 16px; } }

@media (min-width: 992px) { .is-transparent .banner { padding-top: 75px; }
  .banner-gfx-re-s1 { margin: 0 -80px 0 -180px; }
  .banner-gfx-re-s2 { margin: -50px -280px 0 -110px; }
  .banner-gfx-re-s3 { margin: 0 -150px 0 -35px; }
  .banner-gfx-re-s5 { margin: 0 -305px 0 0; }
  .has-mask .banner { padding-bottom: 180px; }
  .has-mask .banner-page { padding-bottom: 100px; }
  .banner-gap-b3 { padding-bottom: 200px; }
  .has-mask .banner-gap-b3 { padding-bottom: 220px; }
  .banner-gap-b4 { padding-bottom: 60px; }
  .has-mask .banner-gap-b4 { padding-bottom: 80px; }
  .banner-screen-right { bottom: -45px; width: 100%; }
  .has-mask .banner-pd-lg { padding-bottom: 225px; padding-top: 65px; }
  .banner-inner { min-height: 600px; }
  .cpn { max-width: 90%; }
  .banner-page .cpn { max-width: 100%; }
  .cpn-head, .cpn-action, .cpn-text { margin-top: 30px; }
  .banner-s1 .cpn-head { margin-top: 0; }
  .cpn-text { margin-bottom: 25px; max-width: 90%; }
  .banner-s1 .cpn-text { margin-top: 20px; margin-bottom: 6px; }
  .banner-s2 .cpn-text { margin-top: 20px; }
  .cpn-text-s1 { margin-top: 20px; margin-bottom: 12px; }
  .cpn-text-s2 { max-width: 100% !important; }
  .cpn-text-s3 { margin-top: 10px; }
  .cpn-btns, .cpn-links { padding-top: 20px; }
  .cpn-action .cpn-btns, .cpn-action .cpn-links { padding-top: 0; }
  .cpn-btns + .cpn-links { padding-top: 20px; }
  .cpn-links + .cpn-btns { padding-top: 20px; }
  .cpn-head .title { font-size: 2.125rem; }
  .cpn-head .title-sm { font-size: 1.85rem; }
  .cpn-head .title-md { font-size: 2.6rem; }
  .cpn-head .title-lg { font-size: 4rem; }
  .cpn-head .title-lg-s2 { font-size: 3rem; }
  .cpn-head .title-lg-s3 { font-size: 2rem; }
  .cpn-boxed { padding: 35px; }
  .cpn-social { margin-top: 50px; }
  .cpn-s2 { margin-top: -122px !important; padding-top: 0; } }

@media (min-width: 1200px) { .banner-gfx-re-s1 { margin: 0 -380px 0 -180px; }
  .banner-gfx-re-s2 { margin: 0 -330px 0 -110px; }
  .banner-gfx-re-s5 { margin: 0 -515px 0 0; }
  .banner-gfx-re-s7 { margin: 140px -505px -183px -290px; }
  .banner-gfx-re-s7 + .abs-center { right: 15px; transform: translateY(-50%); left: auto; }
  .banner-gap-b3 { padding-bottom: 290px; }
  .has-mask .banner-gap-b3 { padding-bottom: 320px; }
  .banner-screen-right { bottom: -90px; }
  .banner-fs .banner-wrap { padding-top: 40px; padding-bottom: 40px; } }

@media (min-width: 1600px) { .banner-caption:not([class*=wide]) { max-width: 1080px; }
  .banner-gfx-re-s2 { margin: -100px -480px 0 -110px; }
  .banner-fs-lg { min-height: calc(100vh - 100px); }
  .has-mask .banner-pd-lg { padding-bottom: 295px; padding-top: 65px; }
  .cpn-head .title-lg-s3 { font-size: 3rem; } }

@media (max-width: 991px) { .banner-gfx-ovfix { padding-top: 20px; padding-bottom: 60px; } }

@media (max-width: 991px) and (orientation: landscape) { .banner-gfx { margin-left: auto; margin-right: auto; max-width: 390px; }
  .banner-gfx-ovfix { padding-top: 40px; padding-bottom: 110px; } }

@media (max-width: 767px) and (orientation: landscape) { .banner-gfx { max-width: 320px; } }

@media (min-width: 992px) and (max-width: 1200px) and (orientation: portrait) { .banner-fs { min-height: calc(65vh - 90px); }
  .is-transparent .banner-fs { min-height: 60vh; } }

@media (max-width: 420px) { .cpn-btns .btn:not(.btn-with-icon) { font-size: 11px; padding: 8px 16px; } }

.banner-y-pd { padding-top: 100px !important; padding-bottom: 124px !important; }

.gfx-screen { position: absolute; top: 5px; left: 44px; width: calc(100% - 91px); height: calc(100% - 25px); }

.gfx-screen .owl-stage-outer, .gfx-screen .owl-stage, .gfx-screen .owl-item, .gfx-screen .slides { height: 100%; width: 100%; border-radius: 6px; }

.gfx-screen .flex-control-nav { position: absolute; right: -45px; top: 50%; transform: translateY(-50%); width: auto; height: fit-content; }

.gfx-screen .flex-control-nav li { display: block; }

.gfx-screen .flex-control-nav li a { width: 8px; height: 8px; border-radius: 50%; margin: 10px; box-shadow: none; border-radius: 50%; background: #d1dceb !important; }

.gfx-screen .flex-control-nav li a.flex-active { background: #2bc9b4 !important; }

.gfx-slide, .gfx-slide-item { height: 100%; }

.gfx-slide img { border-top-right-radius: 10px; border-top-left-radius: 10px; }

.gfx-slide-item { width: 100%; }

@media (min-width: 400px) { .gfx-screen { top: 8px; left: 57px; width: calc(100% - 117px); height: calc(100% - 35px); }
  .gfx-screen .flex-control-nav { right: -46px; }
  .banner-gfx-s2 { max-width: 350px; } }

@media (min-width: 576px) { .gfx-screen { left: 63px; width: calc(100% - 130px); }
  .gfx-screen .flex-control-nav { right: -53px; }
  .banner-gfx-s2 { max-width: 100%; } }

@media (min-width: 768px) { .gfx-screen { top: 9px; left: 73px; width: calc(100% - 150px); height: calc(100% - 44px); }
  .gfx-screen .flex-control-nav { right: -65px; } }

@media (min-width: 1200px) { .gfx-screen { top: 18px; left: 106px; width: calc(100% - 220px); height: calc(100% - 66px); }
  .gfx-screen .flex-control-nav { right: -100px; } }

@media (min-width: 1600px) { .gfx-screen { left: 135px; width: calc(100% - 279px); height: calc(100% - 81px); }
  .gfx-screen .flex-control-nav { right: -128px; } }

/** 04.06 Head */
.section-head { position: relative; }

.section-head:not([class*=pdb]) { padding-bottom: 40px; }

.section-head-xs:not([class*=pdb]) { padding-bottom: 25px; }

.section-head-sm:not([class*=pdb]) { padding-bottom: 30px; }

.section-head p { color: #fff; }

.tc-light .section-head p { color: #fff; }

.section-head.tc-light p { color: #fff; }

.section-head .title { font-weight: 500; }

.section-head .title-thin { font-weight: 300; }

.section-head .title:not(:last-child) { margin-bottom: .75rem; }

.section-head .title-xs:not(:last-child) { margin-bottom: .5rem; }

.section-head .title:before { position: absolute; left: 50%; top: 50%; content: attr(title); font-family: "Roboto", sans-serif; line-height: .9; text-transform: uppercase; white-space: nowrap; 
  color: #11152a;; transform: translate(-50%, -50%); opacity: 0.6; z-index: -1!important;}

.tc-light .section-head .title:before { opacity: 0.6; }

.section-head .title-s4 { text-transform: uppercase; padding: 23px 0;color: #fff; margin-bottom: 0; opacity: 1; position: relative; }

.section-head .title-s4.title:before { font-size: 50px !important; font-weight: 700; }

.section-head-s2 .title:before { font-size: 48px; background-image: linear-gradient(180deg, #415076 0%, #fff 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }

.firefox .section-head-s2 .title:before { -webkit-text-fill-color: #000; }

.firefox .tc-light .section-head-s2 .title:before { -webkit-text-fill-color: #fff; }

.section-head-s3 { position: relative; }

.section-head-s3:after, .section-head-s3:before { position: absolute; left: 50%; height: 1px; width: 110px; content: ""; transform: rotate(-45deg); z-index: -1; opacity: .4; }

.section-head-s3:before { top: 60px; margin-left: -80px; background: #2bc9b4; }

.tc-light .section-head-s3:before { background: #2bc9b4; }

.section-head-s3:after { top: 5px; margin-left: -50px; background: #866bfe; }

.tc-light .section-head-s3:after { background: #866bfe; }

.section-head-line { position: absolute; height: 60px; width: 60px; left: 50%; top: 10px; opacity: .2; border-radius: 50%; background: transparent; transform: rotate(45deg) translate(-50%, 0); }

.section-head-line .line-1, .section-head-line .line-2, .section-head-line .line-3, .section-head-line .line-4, .section-head-line .line-5, .section-head-line .line-6, .section-head-line .line-7, .section-head-line .line-8 { position: absolute; content: ""; border-radius: 50%; background: transparent; border-top: 1px solid #866bfe; border-left: 1px solid #866bfe; }

.section-head-line .line-1 { top: -15px; left: -15px; height: 90px; width: 90px; }

.section-head-line .line-2 { top: -30px; left: -30px; height: 120px; width: 120px; }

.section-head-line .line-3 { top: -45px; left: -45px; height: 150px; width: 150px; }

.section-head-line .line-4 { top: -60px; left: -60px; height: 180px; width: 180px; }

.section-head-line .line-5 { top: -75px; left: -75px; height: 210px; width: 210px; }

.section-head-line .line-6 { top: -90px; left: -90px; height: 240px; width: 240px; }

.section-head-line .line-7 { top: -105px; left: -105px; height: 270px; width: 270px; }

.section-head-line .line-8 { top: -120px; left: -120px; height: 300px; width: 300px; }

@media (min-width: 576px) { .section-head:not([class*=pdb]) { padding-bottom: 50px; }
  .section-head-xs:not([class*=pdb]) { padding-bottom: 25px; }
  .section-head-sm:not([class*=pdb]) { padding-bottom: 30px; }
  .section-head .title:not(:last-child) { margin-bottom: 1.25rem; }
  .section-head .title-xs:not(:last-child) { margin-bottom: .75rem; }
  .section-head-s2 .title:before { font-size: 64px; } }

@media (min-width: 992px) { .section-head:not([class*=pdb]) { padding-bottom: 60px; }
  .section-head-sm:not([class*=pdb]) { padding-bottom: 40px; }
  .section-head-xs:not([class*=pdb]) { padding-bottom: 25px; }
  .section-head-s2 .title:before { font-size: 90px; }
  .section-head-s2 .title-xl:before { font-size: 124px; } }

.t-cap { text-transform: capitalize !important; }

/** 04.07 Features */
.feature { margin-bottom: 30px; display: block; }

.feature-download { margin-bottom: 0; }

.feature.card { height: calc(100% - 30px); }

.feature-group .feature { margin-bottom: 0; }

.feature-group { border-radius: 6px; }

.feature-icon { position: relative; width: 60px; margin-bottom: 15px; flex-shrink: 0; margin-right: 12px; }

.feature-icon-md { width: 40px; }

.feature-icon-md-s2 { width: 80px; }

.feature-icon-xl { width: 120px; }

.feature-icon.dot:before { position: absolute; bottom: 0; right: 0; content: ''; height: 30px; width: 30px; border-radius: 50%; background: rgba(134, 107, 254, 0.5); z-index: 4; }

.tc-light .feature-icon .icon { color: #866bfe; }

.feature-icon .icon { position: relative; font-size: 3.75rem; color: #6e19c2; z-index: 5; margin-left: -5px; }

.feature-icon .icon.icon-circle { margin-left: 0; }

.feature-icon .icon-xs { font-size: 1rem; }

.feature-icon .icon-md { font-size: 2.75rem; }

.feature-icon .icon-rg { font-size: 4.35rem; }

.feature-icon .icon-lg { font-size: 5.75rem; }

.feature-icon .icon-lg-s2 { font-size: 5rem; padding-top: 15px; }

.feature-icon .icon-xl { font-size: 9rem; }

.feature-icon .icon-grd { background-image: linear-gradient(-75deg, #a158e9 0%, #6e19c2 60%, #3c1c94 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; }

.feature-icon .icon-circle { position: relative; height: 36px; width: 36px; line-height: 36px; text-align: center; color: #2bc9b4; border-radius: 50%; background-image: linear-gradient(-45deg, #866bfe 0%, #2bc9b4 80%, #2bc9b4 100%); }

.feature-icon .icon-circle:after { position: absolute; top: 2px; left: 2px; bottom: 2px; right: 2px; content: ''; border-radius: 50%; background: #fff; z-index: -1; }

.bg-theme .feature-icon .icon-circle:after { background: #3c1c94; }

.bg-theme-alt .feature-icon .icon-circle:after { background: #6e19c2; }

.bg-theme-dark .feature-icon .icon-circle:after { background: #180747; }

.bg-theme-dark-alt .feature-icon .icon-circle:after { background: #2e186a; }

.feature-icon-s1 { text-align: center; margin-left: 10px; margin-bottom: 40px; }

.feature-icon-s1:after { position: absolute; content: ""; left: 50%; top: 50%; width: 62px; height: 62px; border: 2px solid rgba(110, 25, 194, 0.1); transform: translate(-50%, -50%) rotate(45deg); border-radius: 8px; }

.tc-light .feature-icon-s1:after { border: 2px solid rgba(110, 25, 194, 0.8); }

.feature-icon-s1 .icon { margin-left: 0; line-height: 48px; }

.feature-icon-s5 { width: 60px; height: 60px; text-align: center; line-height: 63px; border-radius: 50%; margin-bottom: 0; }

.feature-icon-s5 .icon { font-size: 1rem; width: 40px; height: 40px; background: #fff; line-height: 40px; border-radius: 50%; text-align: center; border: 1px solid; border-color: currentColor; margin-left: 0; }

.tc-light .feature-icon-s5 .icon { border-width: 0; }

.feature-icon-s5-1 { background: rgba(110, 25, 194, 0.1); }

.tc-light .feature-icon-s5-1 { background: rgba(110, 25, 194, 0.6); }

.feature-icon-s5-1 .icon { color: #6e19c2; background: linear-gradient(-90deg, #fff 0%, #e5d2f9 100%); }

.tc-light .feature-icon-s5-1 .icon { color: #6e19c2; }

.feature-icon-s5-2 { background: rgba(43, 201, 180, 0.1); }

.tc-light .feature-icon-s5-2 { background: rgba(43, 201, 180, 0.4); }

.feature-icon-s5-2 .icon { color: #2bc9b4; background: linear-gradient(-90deg, #fff 0%, #e1f9f5 100%); }

.tc-light .feature-icon-s5-2 .icon { color: #2bc9b4; }

.feature-icon-s5-3 { background: rgba(134, 107, 254, 0.1); }

.tc-light .feature-icon-s5-3 { background: rgba(134, 107, 254, 0.4); }

.feature-icon-s5-3 .icon { color: #866bfe; background: linear-gradient(-90deg, #fff 0%, #eeeaff 100%); }

.tc-light .feature-icon-s5-3 .icon { color: #866bfe; }

.feature-icon-s5-4 { background: rgba(230, 96, 74, 0.1); }

.tc-light .feature-icon-s5-4 { background: rgba(230, 96, 74, 0.4); }

.feature-icon-s5-4 .icon { color: #e97460; background: linear-gradient(-90deg, #fff 0%, #fceeeb 100%); }

.tc-light .feature-icon-s5-4 .icon { color: #e97460; }

.feature-icon-s6 { width: 60px; height: 60px; border-radius: 50%; background: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); text-align: center; line-height: 60px; }

.feature-icon-s6 .icon { font-size: 40px; color: #fff; opacity: 0.8; line-height: 60px; margin-left: 0; }

.tc-light .feature-icon-s6 .icon { color: #fff; }

.feature-icon-s6-bg { width: 60px; height: 60px; border-radius: 50%; background: rgba(134, 107, 254, 0.2); text-align: center; line-height: 60px; }

.feature-icon-s6-bg .icon { font-size: 40px; color: #866bfe; line-height: 60px; margin: 0 auto; }

.feature-icon-s7 { width: 65px; height: 65px; border-radius: 50%; text-align: center; line-height: 65px; background: #866bfe; }

.feature-icon-s7 .icon { font-size: 30px; margin-left: 0; line-height: inherit; color: #fff; }

.tc-light .feature-icon-s7 .icon { color: #fff; }

.feature-icon-s8 { width: 90%; }

.text-center .feature-icon-s8 { margin-left: auto; margin-right: auto; }

.feature-icon-auto { width: auto; }

.feature-icon-lg { width: 160px; margin-top: -15px; margin-bottom: 5px !important; }

.feature-icon-lg-s1 { width: 110px; margin-top: -10px; margin-bottom: 5px !important; }

.feature-icon-lg-s2 { width: 130px; margin-top: -12px; margin-bottom: 5px !important; }

.feature-icon-lg-s3 { width: 95px; margin-top: -8px; margin-bottom: 20px !important; }

.feature-icon-lg + .feature-text-s8 { padding-top: 25px; }

.feature-icon-sm { width: 60px; }

.feature-icon-bg { margin: 0; position: absolute; right: -15px; bottom: -15px; width: 121px; height: 121px; margin: 0; background: rgba(134, 107, 254, 0.15); border-radius: 50%; box-shadow: 0px 0px 0px 10px rgba(134, 107, 254, 0.06); text-align: center; line-height: 104px; }

.feature-icon-bg img { max-width: 60px; }

.feature-icon-bg-grd { background-position: center center; background-size: contain; min-height: 135px; min-width: 230px; text-align: center; display: flex; align-items: center; justify-content: center; }

.feature-icon-bg-grd-s1 { background-image: url(../../images/icons/icon-bg-a.png); }

.feature-icon-bg-grd-s1 .icon-grd { background-image: linear-gradient(to top, #82ffdd 0%, white 70%); }

.feature-icon-bg-grd-s2 { background-image: url(../../images/icons/icon-bg-a2.png); }

.feature-icon-bg-grd-s2 .icon-grd { background-image: linear-gradient(to top, #82ffdd 0%, white 70%); }

.feature-icon-bg-grd-s3 { background-image: url(../../images/icons/icon-bg-a3.png); }

.feature-icon-bg-grd-s3 .icon-grd { background-image: linear-gradient(to top, #82ffdd 0%, white 70%); }

.feature-inline { display: flex; }

.feature-inline.card { flex-direction: row; }

.feature-middle { align-items: center; }

.feature-middle .feature-icon { margin-bottom: 0; margin-right: 15px; display: inline-flex; align-items: center; }

.feature-img { border-radius: 4px; }

.feature-image { padding-bottom: 15px; }

.feature-image img { height: 55px; width: auto !important; }

.feature-center .feature-image img { margin-left: auto; margin-right: auto; }

.feature-text p:last-child { margin-bottom: 0; }

.feature-text-s8 .title-s5 { margin-bottom: 20px; }

.feature-text-s1 .title-xs-alt { color: #5b53a2; line-height: 1.4; display: inline-block; }

.feature-center { text-align: center; }

.feature-center .feature-icon { margin-left: auto; margin-right: auto; }

.feature-center .feature-icon .icon { margin-left: 0; }

.feature-s1 { display: flex; margin-bottom: 0; align-items: center; padding-right: 20px; }

.feature-s1.boxed { height: 100%; border-radius: 4px; }

.feature-s1.bordered { border: 2px solid rgba(110, 25, 194, 0.2); }

.feature-s1-1.bordered { border-color: rgba(110, 25, 194, 0.2); }

.feature-s1-2.bordered { border-color: rgba(43, 201, 180, 0.2); }

.feature-s1-3.bordered { border-color: rgba(134, 107, 254, 0.2); }

.feature-s1-4.bordered { border-color: rgba(230, 96, 74, 0.2); }

.feature-s3 { margin-bottom: 0; }

.feature-s3.card { padding: 30px 30px 40px; margin-bottom: 0; height: 100%; }

.feature-s3-alt { position: relative; padding: 0; padding-bottom: 25px !important; }

.feature-s3-alt .title-md { margin-bottom: 0.6rem; }

.feature-s3-alt:not(:last-child):before { position: absolute; content: ""; left: 30px; top: 20px; width: 1px; background: #866bfe; height: 100%; }

.feature-s4 .title { padding-top: 15px; }

.feature-s6 { margin-bottom: 0; }

.feature-s7 { margin-bottom: 50px; }

.feature-s8.card { height: calc(100% - 40px); }

.feature-s8-alt { padding-top: 50px; margin-top: 50px; }

.feature-s8-alt .feature-icon { position: absolute; left: 50%; top: 0; transform: translate(-50%, -50%); }

.feature-s8-alt.card { height: calc(100% - 90px); }

.feature-s10 { padding: 30px 30px 30px 25px; box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05); }

.feature-s10-left { margin-right: 20px; padding-bottom: 60px; }

.feature-s10-right { margin-left: 20px; margin-top: -35px; }

.feature-s10 .title-sm { margin-bottom: 1.2rem; }

.feature-s11 { flex-wrap: wrap; align-items: flex-start; margin-bottom: 0; }

.feature-s11 .list { margin-left: 20px; }

.feature-s12 { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 0; }

.feature-slider-dots { display: flex; justify-content: center; margin-bottom: 40px; }

.feature-slider-dot { position: relative; height: 46px; width: 46px; flex-grow: 0; flex-shrink: 0; border-radius: 4px; padding: 5px; text-align: center; background: #a992ea; cursor: pointer; color: #6a55ea; box-shadow: 0 5px 25px rgba(93, 70, 232, 0.15); transition: all .4s ease; margin: 0 8px; border: none; }

.tc-light .feature-slider-dot { background: #9e90f1; }

.feature-slider-dot:focus, .feature-slider-dot:active { outline: none; }

.feature-slider-dot.active { background: #6a55ea; }

.feature-slider-dot:after { position: absolute; top: 50%; left: 100%; height: 2px; content: ''; width: 100%; background: url(../images/line.png) repeat 0 0; z-index: -1; animation: slide 1s linear infinite; }

.feature-slider-dot:last-child:after { display: none; }

.feature-slider-dot:nth-child(2) { color: #2bc9b4; background: #def7f3; }

.tc-light .feature-slider-dot:nth-child(2) { background: #8de7db; }

.feature-slider-dot:nth-child(2).active { background: #2bc9b4; }

.feature-slider-dot:nth-child(3) { color: #49adf3; background: #dbeffd; }

.tc-light .feature-slider-dot:nth-child(3) { background: #9fd4f9; }

.feature-slider-dot:nth-child(3).active { background: #49adf3; }

.feature-slider-dot:nth-child(4) { color: #f2801c; background: #fce6d2; }

.tc-light .feature-slider-dot:nth-child(4) { background: #f9c395; }

.feature-slider-dot:nth-child(4).active { background: #f2801c; }

.feature-slider-dot:nth-child(5) { color: #ea64c6; background: #fbe0f4; }

.tc-light .feature-slider-dot:nth-child(5) { background: #f29edb; }

.feature-slider-dot:nth-child(5).active { background: #ea64c6; }

.feature-slider-dot .ikon { font-size: 28px; line-height: 35px; width: 35px; border-radius: 50%; background: #fff; display: block; }

.feature-slider-dot-s1 { border-radius: 50%; padding: 0; }

.feature-slider-dot-s1.active:before { position: absolute; content: ""; height: calc(100% + 16px); width: calc(100% + 16px); top: -8px; left: -8px; border-radius: 50%; border: 1px solid rgba(132, 132, 164, 0.35); animation: 1.5s linear 0s normal none infinite focuse; }

.feature-slider-dot-s1.active .ikon { background: transparent; color: #fff; }

.feature-slider-dot-s1 .ikon { line-height: 46px; width: 46px; }

.feature-document { padding: 0 27px; max-width: 280px; margin-left: auto; margin-right: auto; }

.feature-document-s2 { margin: 0 0 20px 20px; position: relative; border-radius: 6px; min-height: 140px; padding: 25px 20px 20px 95px; box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07); }

.tc-light .feature-document-s2 { background: #6e19c2; }

.feature-document-img { padding-right: 25px; position: relative; z-index: 1; }

.feature-document-img-s2 { position: absolute; top: 20px; left: -20px; width: 100px; }

.feature-document-img-s2 img { border-radius: 4px; }

.feature-document-img img { border: 2px solid #fff; box-shadow: 0px 0px 12px 0px rgba(134, 107, 254, 0.15); position: relative; z-index: 2; border-radius: 5px; }

.tc-light .feature-document-img img { border-width: 0; box-shadow: 0px 0px 12px 0px rgba(24, 7, 71, 0.25); }

.feature-document-img:before { position: absolute; right: 0; content: ''; top: 40px; width: 40px; height: calc(100% - 70px); z-index: 1; background: #fff; box-shadow: 0px 5px 20px 0px rgba(134, 107, 254, 0.07); z-index: -1; }

.tc-light .feature-document-img:before { background: rgba(134, 107, 254, 0.75); box-shadow: 0px 5px 20px 0px rgba(24, 7, 71, 0.15); }

.feature-document-text { justify-content: space-between; display: flex; padding-top: 18px; align-items: center; }

.feature-document-text-s2 { color: #415076; margin-bottom: 14px; font-weight: 600; }

.tc-light .feature-document-text-s2 { color: #fff; }

.feature-document-text .drop-wrap { padding-bottom: 5px; }

.feature-document-info span { display: block; line-height: 1; font-size: 13px; font-weight: 400; opacity: .5; }

.feature-document-link { display: block; margin-top: 6px; height: 40px; width: 40px; text-align: center; line-height: 40px; border-radius: 50%; color: #fff; background: #2bc9b4; }

.feature-document-link:hover, .feature-document-link:active, .feature-document-link:focus { background: #866bfe; color: #fff; }

.tc-light .feature-document-link:hover, .tc-light .feature-document-link:active, .tc-light .feature-document-link:focus { background: #866bfe; color: #fff; }

.feature-document-link-s2 li { display: inline-block; margin: 0 2px 5px; }

.feature-document-link-s2 li a { padding: 0 10px; display: inline-block; line-height: 21px; border: 2px solid #fff; border-radius: 4px; font-size: .86em; font-weight: 500; color: #866bfe; }

.tc-light .feature-document-link-s2 li a { color: #fff !important; border: 2px solid #fff; }

.feature-reverse { flex-direction: row-reverse; text-align: right; }

.feature-serial { font-size: 100px; font-weight: 700; line-height: normal; color: rgba(91, 83, 162, 0.2); }

@keyframes focuse { 0% { transform: scale(0.8);
    opacity: 1; }
  75% { transform: scale(1.2);
    opacity: 0; }
  100% { transform: scale(1.2);
    opacity: 0; } }

@keyframes slide { from { background-position: 0 0; }
  to { background-position: 40px 0; } }

.boxed { padding: 15px 20px; }

.boxed-xs { padding: 18px; }

.boxed-sm { padding: 30px 25px; }

.boxed-lg { padding: 30px 25px; }

.boxed-xl { padding: 30px 25px; }

@media (max-width: 575px) { .boxed.contact-form { padding: 20px; }
  .feature-icon-s8 img { max-width: 220px; } }

@media (min-width: 480px) { .feature-s10 { padding: 40px 90px 50px 35px; }
  .feature-s10-left { margin-right: 45px; padding-bottom: 90px; }
  .feature-s10-right { margin-left: 45px; margin-top: -45px; }
  .feature-s11 { flex-wrap: nowrap; } }

@media (min-width: 576px) { .feature.card { height: calc(100% - 30px); }
  .feature-icon { margin-bottom: 20px; margin-right: 20px; }
  .card-s3 .feature-icon { margin-right: 30px; }
  .feature-sm-reverse .feature-icon { margin-right: 0; margin-left: 20px; }
  .feature-icon-s1 { margin-bottom: 40px; }
  .feature-icon-s5 { margin-bottom: 0; }
  .feature-icon-s7 { margin-right: 30px; }
  .feature-sm-reverse .feature-icon-s7 { margin-left: 30px; }
  .feature-icon-bg { right: -40px; bottom: -40px; }
  .has-rtl .feature-icon-bg { right: auto; left: -40px; }
  .feature-icon-bg-grd { margin-bottom: 35px; }
  .feature-s2 { display: flex; align-items: flex-start; }
  .feature-s3 { margin-bottom: 0; padding: 0 30px; }
  .feature-s3.card { margin-bottom: 0; height: 100%; }
  .feature-s3-alt { padding: 0; }
  .feature-s6 { padding-bottom: 145px; }
  .feature-s6:before, .feature-s6:after { position: absolute; left: 50%; content: ''; transform: translateX(-50%); }
  .feature-s6:before { bottom: 0; width: 2px; height: 95px; background: #dbe4f7; border-radius: 2px; }
  .tc-light .feature-s6:before { background: rgba(255, 255, 255, 0.8); }
  .feature-s6:after { bottom: 94px; width: 16px; height: 16px; border-radius: 50%; border: 2px solid #dbe4f7; background: #866bfe; }
  .tc-light .feature-s6:after { border-color: #fff; }
  .feature-s8.card { height: calc(100% - 40px); }
  .feature-s8-alt.card { height: calc(100% - 80px); }
  .feature-s12 { flex-wrap: nowrap; }
  .feature-slider-dot { height: 76px; width: 76px; padding: 10px; margin: 0 20px; }
  .feature-slider-dot .ikon { font-size: 38px; line-height: 56px; width: 56px; }
  .feature-slider-dot-s1 { padding: 0; }
  .feature-slider-dot-s1 .ikon { font-size: 48px; line-height: 76px; width: 76px; }
  .feature-sm-reverse { flex-direction: row-reverse; text-align: right; }
  .boxed { padding: 40px 50px; }
  .boxed-xs { padding: 18px; }
  .boxed-sm { padding: 35px 25px; }
  .boxed-md { padding: 40px 30px; }
  .boxed-lg { padding: 45px 40px; }
  .feature-icon-lg-s3 { margin-bottom: 5px !important; } }

.link-feature-s1 { position: absolute; bottom: -40px; left: 50%; opacity: 0; transform: translate(-50%, 0); }

.feature:hover .link-feature-s1 { bottom: -25px; opacity: 1; }

@media (min-width: 768px) { .feature-s10 { padding: 40px 45px 50px 35px; }
  .feature-s10-left { margin-right: -22.5px; margin-top: 60px; padding-bottom: 50px; }
  .feature-s10-right { margin-left: -22.5px; margin-bottom: 60px; margin-top: 0; }
  .feature-carousel-s1 .owl-nav { position: absolute; bottom: 35px; right: 15px; }
  .feature-md-reverse .feature-icon { margin-right: 0; margin-left: 20px; }
  .feature-md-reverse .feature-icon-s7 { margin-left: 30px; }
  .feature-md-reverse { flex-direction: row-reverse; text-align: right; } }

@media (min-width: 992px) { .feature-s6-1 { padding-top: 197px; }
  .feature-s6-2 { padding-top: 73px; }
  .feature-s6-3 { padding-top: 0; }
  .feature-s6-4 { padding-top: 184px; }
  .feature-s10 { padding: 40px 240px 50px 50px; }
  .feature-s10-left { margin-right: -95px; margin-top: 80px; }
  .feature-s10-right { margin-left: -95px; }
  .feature-text-s2 { padding: 0 0 0 45px; }
  .feature-text-wd p { width: 72%; }
  .feature-s11 { margin-top: 15px; margin-bottom: 15px; }
  .feature-s12 { align-items: flex-start; }
  .feature-lg-reverse .feature-icon { margin-right: 0; margin-left: 20px; }
  .feature-lg-reverse .feature-icon-s7 { margin-left: 30px; }
  .feature-lg-reverse { flex-direction: row-reverse; text-align: right; }
  .boxed-xs { padding: 18px; }
  .boxed-md { padding: 45px 40px; }
  .boxed-lg { padding: 55px 60px; }
  .boxed-xl { padding: 65px 60px; } }

@media (min-width: 1200px) { .feature-text-s2 { padding: 0 65px 0 45px; }
  .feature-text-wd p { width: 60%; }
  .feature-xl-reverse .feature-icon { margin-right: 0; margin-left: 20px; }
  .feature-xl-reverse .feature-icon-s7 { margin-left: 30px; }
  .feature-xl-reverse { flex-direction: row-reverse; text-align: right; } }

@media (max-width: 991px) { .feature-icon-s1 { margin: 0 auto 40px; } }

.feature-icon-btc .icon { color: #ffba00; }

.feature-icon-eth .icon { color: #6a7aa2; }

.feature-icon-ltc .icon { color: #adadad; }

.feature-icon-xrp .icon { color: #2f3135; }

.feature-icon-dash .icon { color: #1174bc; }

.feature-icon-monero .icon { color: #ff6600; }

.feature-currency .title { color: #415076 !important; }

.feature-pd-lg { padding: 50px 30px; }

.owl-item.active .feature { opacity: 0.8 !important; }

.owl-item.active.center .feature { transition: 0.3s; opacity: 1 !important; }

.feature-img-xl { max-width: 176px; margin: 0 auto; }

@media (min-width: 992px) { .feature.center-lg { transform: scale(1.2); } }

/** 04.08 Roadmap */
.roadmap { position: relative; z-index: 10; padding-bottom: 30px; }

.roadmap-carousel .roadmap { padding-bottom: 0; }

.roadmap-s1:before, .roadmap-s1:after { position: absolute; content: ""; left: -1px; height: 50%; border-left-width: 3px; border-left-style: solid; border-color: #dbe4f7; }

.tc-light .roadmap-s1:before, .tc-light .roadmap-s1:after { border-color: rgba(255, 255, 255, 0.1); }

.roadmap-s1:before { height: 45px; top: 0; }

.flex-row-reverse .roadmap-s1:before { right: auto; }

.roadmap-s1:after { height: calc(100% - 45px); right: auto; top: 45px; }

.roadmap-s1-alt:before, .roadmap-s1-alt:after { left: 1px; border-left-width: 1px; border-left-style: dashed; }

.tc-light .roadmap-s1-alt:before, .tc-light .roadmap-s1-alt:after { border-color: rgba(255, 255, 255, 0.3); }

.flex-row-reverse .roadmap-s1-alt:before, .flex-row-reverse .roadmap-s1-alt:after { right: auto; left: 1px; }

.roadmap-s1-alt.roadmap-done:before, .roadmap-s1-alt.roadmap-current:before { top: 0; }

.roadmap-s1-alt.roadmap-done:after, .roadmap-s1-alt.roadmap-current:after { top: 45px; }

.roadmap-s1-alt.roadmap-done:before, .roadmap-s1-alt.roadmap-done:after { left: 0px; border-left-width: 3px; border-left-style: solid; }

.roadmap-s1-alt.roadmap-current:before { left: 0px; border-left-width: 3px; border-left-style: solid; }

.roadmap-s1.roadmap-done:before, .roadmap-s1.roadmap-done:after { border-color: #866bfe; }

.roadmap-s1.roadmap-current:before { border-color: #866bfe; }

.roadmap-s2 { text-align: center; }

.roadmap-s2:before, .roadmap-s2:after { position: absolute; content: ''; transition: width .3s; }

.roadmap-s2:before { width: 0; height: 4px; background: #866bfe; left: 0; top: 55px; }

.tc-light .roadmap-s2:before { background: #866bfe; }

.roadmap-s2:after { left: 50%; top: 49px; transform: translateX(-50%); height: 16px; width: 16px; border-radius: 50%; border: 3px solid #dbe4f7; background: #fff; }

.tc-light .roadmap-s2:after { border-color: #5b77a3; background: #3c1c94; }

.roadmap-s2.roadmap-finished:after, .roadmap-s2.roadmap-running:after { background: #866bfe; border: 2px solid #fff; }

.tc-light .roadmap-s2.roadmap-finished:after, .tc-light .roadmap-s2.roadmap-running:after { background: #866bfe; border-color: #fff; }

.roadmap-s2.roadmap-finished:before { width: 100%; }

.roadmap-s2.roadmap-running:before { width: 50%; }

.roadmap-s2.roadmap-running:after { box-shadow: 0 0 0 12px rgba(43, 201, 180, 0.1); }

.tc-light .roadmap-s2.roadmap-running:after { box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.1); }

.roadmap-s5 { width: 100%; }

.roadmap-s5:last-child { padding-bottom: 30px; }

.roadmap-s6 { padding-top: 65px; margin-top: 15px; }

.roadmap-s6:before { position: absolute; content: ""; top: 0; left: -6px; width: calc(100% + 30px); height: 1px; z-index: 2; background: #fff; }

.roadmap-s6:after { position: absolute; content: ""; top: 0; right: 50%; width: 19px; height: 19px; z-index: 3; border: 1px solid #fff; border-radius: 50%; transform: translate(50%, -50%); background: #fff; }

.tc-light .roadmap-s6:after { background: #3c1c94; }

.roadmap-s6.roadmap-done:after { font-family: "Font Awesome 5 Free"; content: '\f00c'; font-weight: 700; font-size: 10px; line-height: 17px; text-align: center; color: #fff; background: #39d884; }

.roadmap-s6-1-2:before { background: linear-gradient(90deg, #866bfe 0%, #2bc9b4 100%); }

.roadmap-s6-1-2:after { border-color: #2bc9b4; }

.roadmap-s6-1-2 .roadmap-head-s6:after { background: #2bc9b4; }

.roadmap-s6-2-1:before { background: linear-gradient(90deg, #2bc9b4 0%, #866bfe 100%); }

.roadmap-s6-2-1:after { border-color: #866bfe; }

.roadmap-s6-2-1 .roadmap-head-s6:after { background: #866bfe; }

.roadmap-s6-2-3:before { background: linear-gradient(90deg, #2bc9b4 0%, #6e19c2 100%); }

.roadmap-s6-2-3:after { border-color: #6e19c2; }

.roadmap-s6-2-3 .roadmap-head-s6:after { background: #6e19c2; }

.roadmap-s6-3-2:before { background: linear-gradient(90deg, #6e19c2 0%, #2bc9b4 100%); }

.roadmap-s6-3-2:after { border-color: #2bc9b4; }

.roadmap-s6-3-2 .roadmap-head-s6:after { background: #2bc9b4; }

.roadmap-s6-3-1:before { background: linear-gradient(90deg, #6e19c2 0%, #866bfe 100%); }

.roadmap-s6-3-1:after { border-color: #866bfe; }

.roadmap-s6-3-1 .roadmap-head-s6:after { background: #866bfe; }

.roadmap-s6-1-3:before { background: linear-gradient(90deg, #866bfe 0%, #6e19c2 100%); }

.roadmap-s6-1-3:after { border-color: #6e19c2; }

.roadmap-s6-1-3 .roadmap-head-s6:after { background: #6e19c2; }

.roadmap-slider { position: relative; }

.roadmap-text { max-width: 265px; margin-left: auto; margin-right: auto; padding: 0px 1px; }

.roadmap-wrap { position: relative; padding: 40px 0 10px; margin-bottom: 40px; max-width: 430px; margin-left: auto; margin-right: auto; }

.nk-block-left .roadmap-wrap { margin-left: 0; margin-right: 0; }

.roadmap-wrap:before, .roadmap-wrap:after { position: absolute; content: ''; top: 0; bottom: 0; z-index: 7; }

.roadmap-wrap-ovl-right:after { right: 0; background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 25%, #fff 60%, #fff 100%); }

.bg-light .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(247, 248, 255, 0.8) 25%, #f7f8ff 60%, #f7f8ff 100%); }

.bg-light-alt .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(234, 239, 246, 0.8) 25%, #eaeff6 60%, #eaeff6 100%); }

.bg-white .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.8) 25%, #fff 60%, #fff 100%); }

.bg-theme .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(60, 28, 148, 0.8) 25%, #3c1c94 60%, #3c1c94 100%); }

.bg-theme_alt .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(110, 25, 194, 0.8) 25%, #6e19c2 60%, #6e19c2 100%); }

.bg-theme-dark .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(24, 7, 71, 0.8) 25%, #180747 60%, #180747 100%); }

.bg-theme-dark-alt .roadmap-wrap-ovl-right:after { background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(46, 24, 106, 0.8) 25%, #2e186a 60%, #2e186a 100%); }

.roadmap-wrap-s1 { padding-bottom: 0; padding-top: 0; max-width: 90%; }

.roadmap-wrap-s5 { display: flex; flex-wrap: wrap; padding: 40px 0 0; }

.roadmap-wrap-s5:before { position: absolute; content: ""; left: 0; width: 1px; height: calc(100% + 30px); background: #866bfe; }

.roadmap-line { position: absolute; left: 19px; top: 0; background: #c1cef1; height: 100%; width: 2px; content: ''; z-index: 9; }

.roadmap-line:after, .roadmap-line:before { position: absolute; background: #c1cef1; height: 10px; width: 10px; border-radius: 50%; content: ''; left: -4px; }

.roadmap-line:before { top: 0; }

.roadmap-line:after { bottom: 0; }

.tc-light .roadmap-line { background: rgba(255, 255, 255, 0.1); }

.tc-light .roadmap-line:after, .tc-light .roadmap-line:before { background: #866bfe; }

.bg-theme-dark .roadmap-line { background: #6e19c2; }

.bg-theme-dark .roadmap-line:after, .bg-theme-dark .roadmap-line:before { background: #866bfe; }

.bg-theme .roadmap-line { background: #6e19c2; }

.bg-theme .roadmap-line:after, .bg-theme .roadmap-line:before { background: #866bfe; }

.roadmap-line-s2 { position: absolute; width: 100%; height: 4px; background: #dbe4f7; content: ''; top: 95px; border-radius: 2px; left: 0; }

.tc-light .roadmap-line-s2 { background: #5b77a3; }

.roadmap-line-s3:before, .roadmap-line-s3:after { display: none; }

.roadmap-year { border-radius: 50%; color: #fff; background: #3c1c94; height: 50px; width: 50px; text-align: center; line-height: 50px; font-weight: 700; font-size: 12px; position: relative; left: -5px; }

.tc-light .roadmap-year { background: #866bfe; }

.bg-theme .roadmap-year, .bg-theme-dark .roadmap-year { background: #866bfe; }

.roadmap-year-s2 { padding-bottom: 65px; }

.roadmap-step { border: 2px solid #dbe4f7; border-radius: 5px; position: relative; margin-left: 50px; padding: 20px 20px 15px; position: relative; max-width: 380px; }

.bg-light .roadmap-step { background: #fff; }

.roadmap-step:before, .roadmap-step:after { position: absolute; content: ''; }

.roadmap-step:before { height: 18px; width: 18px; border-radius: 50%; border: 5px solid #fff; background: #3c1c94; left: -41px; top: 34px; z-index: 1; }

.roadmap-step:after { height: 2px; width: 22px; background: #dbe4f7; left: -22px; top: 42px; }

.roadmap-step p:last-child { margin-bottom: 0; }

.roadmap-step-list li:before { background: #415076; }

.tc-light .roadmap-step-list li:before { background: rgba(255, 255, 255, 0.1); }

.roadmap-step-list-s1 li { list-style: none; padding: 8px 0; position: relative; }

.roadmap-step-list-s1 li:after { position: absolute; content: ""; left: 10px; bottom: 0; width: 20px; height: 1px; transform: translateX(-50%); background: currentColor; }

.roadmap-step-list-s1 li:last-child:after { display: none; }

.tc-light .roadmap-step { background: rgba(255, 255, 255, 0.05); border-color: rgba(255, 255, 255, 0.1); }

.tc-light .roadmap-step:before { background: #866bfe; }

.tc-light .roadmap-step:after { background: rgba(255, 255, 255, 0.1); border-color: rgba(255, 255, 255, 0.1); }

.bg-theme .roadmap-step, .bg-theme-dark .roadmap-step { background: #2e186a; border-color: #6e19c2; }

.bg-theme .roadmap-step:before, .bg-theme-dark .roadmap-step:before { background: #866bfe; border-color: #3c1c94; }

.bg-theme .roadmap-step:after, .bg-theme-dark .roadmap-step:after { background: #6e19c2; border-color: #6e19c2; }

.bg-theme-dark .roadmap-step:before { border-color: #180747; }

.bg-theme-alt .roadmap-step:before { border-color: #6e19c2; }

.bg-light .roadmap-step:before { border-color: #f7f8ff; }

.has-bg-image .roadmap-step:before { border-color: transparent; }

.roadmap-step-s1 { margin-left: 0; border: none; padding-top: 0; padding-right: 25px; padding-left: 25px; z-index: 1; max-width: 100%; }

.bg-light .roadmap-step-s1 { background: transparent; }

.bg-theme .roadmap-step-s1, .bg-theme-dark .roadmap-step-s1, .tc-light .roadmap-step-s1 { background: transparent; }

.roadmap-step-s1:before { display: none; }

.roadmap-step-s1:after { width: 38px !important; left: 0 !important; height: 0; top: 49px; z-index: -1; background: none !important; border-top: 1px solid; border-color: #dbe4f7; }

.roadmap-s1-alt .roadmap-step-s1:after { border-top-style: dashed; }

.roadmap-step-s3 { margin-left: 0; border: none; padding: 0px 23px 30px; max-width: none; }

.tc-light .roadmap-step-s3 { background: transparent; }

.roadmap-step-s3:before { display: none; }

.roadmap-step-s3:after { height: 60px; left: 50%; width: 2px; top: 75px; }

.roadmap-step-s5 { margin: 0; background-color: transparent !important; border: none; padding-top: 0; padding-bottom: 0; }

.roadmap-step-s5:before, .roadmap-step-s5:after { display: none; }

.roadmap-head { margin-bottom: 10px; padding-right: 35px; position: relative; }

.roadmap-finished .roadmap-head:after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 32px; height: 32px; line-height: 32px; border-radius: 50%; text-align: center; color: #fff; background: #2bc9b4; font-family: "Font Awesome 5 Free"; font-weight: 700; font-size: .88rem; content: '\f00c'; }

.roadmap-head-s1 { padding-right: 0; margin-bottom: 0; line-height: 1.6; }

.roadmap-head-s3 { padding-bottom: 120px; margin-bottom: 0; padding-right: 0; }

.roadmap-head-s5 { padding: 0; }

.roadmap-head-s5:after { display: none; }

.roadmap-head-s6 { padding-left: 0; padding-right: 0; }

.roadmap-head-s6:after { position: absolute; content: ""; bottom: 100%; right: 50%; width: 1px; height: 65px; transform: translate(50%, 0); background: #fff; }

.roadmap-time { display: block; color: #3c1c94; font-size: 13px; text-transform: uppercase; }

.tc-light .roadmap-time { color: rgba(255, 255, 255, 0.8); }

.roadmap-time-s1 { padding-bottom: 27px; line-height: 26px; width: 100%; display: inline-block; font-weight: 700; font-size: 0.81rem; position: relative; color: #8ca2e5; line-height: 25px; }

.roadmap-time-s1:after, .roadmap-time-s1:before { position: absolute; content: ""; left: -24px; border-radius: 50%; }

.roadmap-time-s1:after { bottom: -2px; width: 11px; height: 11px; margin-left: -5.5px; margin-top: -5.5px; background: #866bfe; }

.tc-light .roadmap-time-s1:after { background: #fff; }

.roadmap-time-s1:before { bottom: -10px; width: 27px; height: 27px; margin-left: -13.5px; margin-top: -13.5px; }

.roadmap-done .roadmap-time-s1:before, .roadmap-current .roadmap-time-s1:before { background: rgba(134, 107, 254, 0.23); }

.tc-light .roadmap-done .roadmap-time-s1:before, .tc-light .roadmap-current .roadmap-time-s1:before { background: rgba(255, 255, 255, 0.1); }

.roadmap-time-s3 { color: #5b53a2; }

.roadmap-time-s3:before { position: absolute; left: 50%; transform: translateX(-50%); content: '\f1da'; border-radius: 50%; font-family: "Font Awesome 5 Free"; font-weight: 600; box-shadow: 0px 2px 7px 0px rgba(60, 28, 148, 0.2); top: 32px; background: linear-gradient(-90deg, #fff 0%, white 100%); border: 1px solid rgba(60, 28, 148, 0.4); height: 32px; line-height: 32px; width: 32px; color: #5b53a2; z-index: 1; font-size: 13px; }

.tc-light .roadmap-time-s3:before { color: rgba(255, 255, 255, 0.8); background: linear-gradient(-90deg, #3c1c94 0%, #4d24bf 100%); border-color: rgba(255, 255, 255, 0.2); box-shadow: 0px 2px 7px 0px rgba(255, 255, 255, 0.1); }

.roadmap-done .roadmap-time-s3:before { content: '\f00c'; }

.roadmap-running .roadmap-time-s3:before { content: '\f021'; }

.roadmap-s3-1 .roadmap-time-s3:before { content: ''; background: rgba(134, 107, 254, 0.5); border: none; }

.roadmap-s3-1.roadmap-done .roadmap-time-s3:before { background: rgba(97, 61, 254, 0.5); }

.roadmap-s3-1 .roadmap-time-s3:after { position: absolute; left: 50%; top: 40px; transform: translateX(-50%); border-radius: 50%; content: ''; background: #866bfe; height: 16px; width: 16px; }

.roadmap-s3-1.roadmap-done .roadmap-time-s3:after { background: #613dfe; }

.roadmap-time-s5 { font-weight: 600; text-transform: capitalize; font-size: 1rem; color: #866bfe; }

.roadmap-time-s5:not(:last-child) { padding-bottom: 10px; }

.roadmap-time-s5:after { position: absolute; height: 19px; width: 19px; border: 1px solid #866bfe; background: #866bfe; top: 2px; left: -29px; border-radius: 50%; content: ''; }

.roadmap-finished .roadmap-time-s5:after { background: #f7f8ff; content: "\f00c"; font-family: "Font Awesome 5 Free"; font-size: 10px; text-align: center; line-height: 18px; color: #2bc9b4; }

.tc-light .roadmap-finished .roadmap-time-s5:after { color: #2bc9b4; background: #3c1c94; }

.roadmap-time-s6 { text-transform: none; }

.roadmap-title { color: #8ca2e5; font-weight: 700; font-size: 16px; }

.tc-light .roadmap-title { color: #fff; }

.roadmap-title-s1 { padding-top: 25px; display: inline-block; font-size: 1rem; font-weight: 500; line-height: 1.6; color: #415076; }

.roadmap-title-s2 { position: relative; font-size: 1rem; font-weight: 500; color: #866bfe; top: -10px; }

.tc-light .roadmap-title-s2 { color:#46bdf4; }

.roadmap-title-s2:before { position: absolute; content: ''; left: -25px; top: 1px; transform: translate(-50%, 0); height: 15px; width: 15px; border-radius: 50%;    background: #2b56f5;
  border: 1px solid #2b56f5 }

.roadmap-done .roadmap-title-s2:before { height: 19px; width: 19px; font-family: "Font Awesome 5 Free"; content: '\f00c'; font-weight: 900; font-size: 9px; line-height: 18px; text-align: center; color: #fff;
  background: #0b0c22; }

.tc-light .roadmap-done .roadmap-title-s2:before { color: #fff; background: #3c1c94; }

.bg-theme .roadmap-done .roadmap-title-s2:before { background: #3c1c94; }

.bg-theme-alt .roadmap-done .roadmap-title-s2:before { background: #6e19c2; }

.bg-theme-dark .roadmap-done .roadmap-title-s2:before { background: #180747; }

.bg-theme-dark-alt .roadmap-done .roadmap-title-s2:before { background: #2e186a; }

.roadmap-carousel { padding: 0 40px; }

.roadmap-carousel:after, .roadmap-carousel:before { position: absolute; height: 12px; width: 12px; border-radius: 50%; content: ''; top: 42px; background: #dbe4f7; }

.tc-light .roadmap-carousel:after, .tc-light .roadmap-carousel:before { background: #6e19c2; }

.roadmap-carousel .owl-nav { position: absolute; left: -30px; top: 23px; font-size: 36px; font-weight: 400; width: calc(100% + 60px); opacity: 0.3; }

.roadmap-carousel .owl-nav .owl-next { right: -30px; }

.roadmap-carousel .owl-nav .owl-prev { left: -30px; }

.roadmap-carousel .owl-nav .owl-next, .roadmap-carousel .owl-nav .owl-prev { top: -30px; position: absolute; z-index: 2; height: 52px; width: 52px; font-size: 36px; line-height: 52px; text-align: center; color: #866bfe; transform: translateY(50%); }

.roadmap-carousel:after { right: 0; }

.roadmap-carousel:before { left: 0; }

.roadmap-carousel-s2:after, .roadmap-carousel-s2:before { height: 1px; width: 85px; border-radius: 0; top: 15px; z-index: 4; }

.roadmap-carousel-s2:before { background: linear-gradient(90deg, #eaeff6 0%, rgba(0, 0, 0, 0) 100%); }

.bg-theme .roadmap-carousel-s2:before { background: linear-gradient(90deg, #3c1c94 0%, rgba(0, 0, 0, 0) 100%); }

.bg-theme-alt .roadmap-carousel-s2:before { background: linear-gradient(90deg, #6e19c2 0%, rgba(0, 0, 0, 0) 100%); }

.bg-theme-dark .roadmap-carousel-s2:before { background: linear-gradient(90deg, #180747 0%, rgba(0, 0, 0, 0) 100%); }

.bg-theme-dark-alt .roadmap-carousel-s2:before { background: linear-gradient(90deg, #2e186a 0%, rgba(0, 0, 0, 0) 100%); }

.roadmap-carousel-s2:after { background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #eaeff6 100%); }

.bg-theme .roadmap-carousel-s2:after { background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #3c1c94 100%); }

.bg-theme-alt .roadmap-carousel-s2:after { background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #6e19c2 100%); }

.bg-theme-dark .roadmap-carousel-s2:after { background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #180747 100%); }

.bg-theme-dark-alt .roadmap-carousel-s2:after { background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #2e186a 100%); }

.roadmap-carousel-s2:after { right: 40px; }

.roadmap-carousel-s2:before { left: 40px; }

.roadmap-carousel-container { position: relative; }

.roadmap-carousel-container:before { position: absolute; content: ""; left: 0; top: 47px; width: 100%; height: 2px; background: #dbe4f7; }

.tc-light .roadmap-carousel-container:before { background: #6e19c2; }

.roadmap-carousel-nav-s2 .owl-nav { width: calc(100% + 80px); top: 26px; opacity: 1; }

.roadmap-carousel-nav-s2 .owl-nav button { border: none !important; }

.roadmap-carousel-nav-s2 .owl-nav button:after { font-size: 36px; line-height: 52px; color: #dbe4f7; }

.roadmap-carousel-nav-s2.roadmap-carousel-s2 .owl-nav { top: -7px; left: 0; width: calc(100% + 20px); }

.roadmap-carousel:not([data-dots="true"]) { margin-bottom: -30px; }

.roadmap-list { position: relative; padding-top: 15px; }

.roadmap-list:after { position: absolute; left: 50%; bottom: 0; transform: translateX(-50%); width: 100vw; height: 1px; content: '';     background: #866bfe; }

.roadmap-innr { padding: 0 25px 30px; }

.roadmap-innr:before { position: absolute; left: 0; height: 100%; width: 1px; content: ''; transform: translateX(-50%);    background-color: #2b56f5; }

.roadmap-item { position: relative; }

.roadmap-item:nth-child(even) .roadmap-innr:before { background-color: #866bfe; }

@media (max-width: 767px) { .roadmap-carousel-container { max-width: calc(100% - 90px); margin-right: auto; margin-left: auto; }
  .roadmap-carousel-nav-s2.roadmap-carousel-s2 .owl-nav { width: calc(100% + 10px); } }

@media (min-width: 576px) { .roadmap-wrap-ovl-right { max-width: none; margin-right: -200px; }
  .roadmap-wrap-ovl-right:after { width: 280px; }
  .roadmap-wrap-ovl-right .owl-nav { margin-right: 190px; }
  .roadmap-text { padding: 0; } }

@media (min-width: 768px) { .roadmap-s5 { display: flex; text-align: right; }
  .roadmap-s5.roadmap-right { justify-content: flex-end; text-align: left; }
  .roadmap-s5:not(:last-child) { padding-bottom: 15px; }
  .roadmap-wrap-s5 { max-width: 100%; }
  .roadmap-wrap-s5:before { top: -30px; left: 50%; margin-left: -0.5px; }
  .roadmap-step-s5 { max-width: 50%; padding-left: 30px; padding-right: 30px; }
  .roadmap-time-s5:after { left: -39px; }
  .roadmap-left .roadmap-time-s5:after { right: -40px; left: auto; } }

@media (min-width: 992px) { .roadmap { display: flex; }
  .roadmap-s1:before, .roadmap-s1:after { top: 41px; height: 0; width: 50%; border-top-width: 3px; border-top-style: solid; border-left-width: 0; }
  .roadmap-s1:before { left: 0; }
  .flex-row-reverse .roadmap-s1:before { right: 0; left: auto; }
  .roadmap-s1:after { left: auto; right: 0; z-index: -1; }
  .flex-row-reverse .roadmap-s1:after { right: auto; left: 0; }
  .roadmap-s1-alt:before, .roadmap-s1-alt:after { top: 42px; border-top-width: 1px; border-top-style: dashed; }
  .roadmap-s1-alt:after { right: 0; }
  .roadmap-s1-alt.roadmap-done:before, .roadmap-s1-alt.roadmap-done:after { top: 41px; border-top-width: 3px; border-top-style: solid; }
  .roadmap-s1-alt.roadmap-done:after { right: 0; left: auto; }
  .roadmap-s1-alt.roadmap-current:before { top: 41px; border-top-width: 3px; border-top-style: solid; }
  .roadmap-s1-alt.roadmap-current:after { top: 42px; }
  .roadmap-s2 { display: block; }
  .roadmap-s6 { display: block; }
  .roadmap-s6:after { right: -14px; }
  .roadmap-head-s6:after { position: absolute; content: ""; bottom: 100%; right: -14px; width: 1px; height: 65px; transform: translate(50%, 0); background: #fff; }
  .roadmap-wrap { padding: 40px 0 10px; margin-bottom: 60px; max-width: 100%; }
  .roadmap-wrap-ovl-left, .roadmap-wrap-ovl-right { max-width: none; }
  .roadmap-wrap-s1 { padding-top: 0; }
  .roadmap-wrap-s1:after { position: absolute; width: 15px; height: calc(100% + 3px); border-width: 3px; border-style: solid; border-color: #dbe4f7; top: 41px; }
  .tc-light .roadmap-wrap-s1:after { border-color: rgba(255, 255, 255, 0.1); }
  .roadmap-wrap-s1-alt:after { height: calc(100% + 1px); border-width: 1px; border-style: dashed; top: 42px; }
  .tc-light .roadmap-wrap-s1-alt:after { border-color: rgba(255, 255, 255, 0.3); }
  .roadmap-wrap-s1-alt.roadmap-wrap-done:after { height: calc(100% + 3px); border-width: 3px; border-style: solid; top: 41px; }
  .roadmap-wrap-s1.roadmap-wrap-done:after { border-color: #866bfe; }
  .roadmap-wrap-s1:nth-child(odd):after { right: -15px; border-radius: 0 20px 20px 0; border-left-width: 0; }
  .roadmap-wrap-s1:nth-child(even):after { left: -15px; border-radius: 20px 0px 0px 20px; border-right-width: 0; }
  .roadmap-wrap-s1:last-child:after { display: none; }
  .roadmap-line { left: 50%; margin-left: -1px; }
  .roadmap-left { justify-content: flex-start; margin-top: -80px; }
  .roadmap-left .roadmap-step { margin-left: 0; margin-right: 80px; }
  .roadmap-left .roadmap-step:before { left: auto; right: -91px; }
  .roadmap-left .roadmap-step:after { left: auto; right: -78px; }
  .roadmap-left.roadmap-s5 { margin-top: 0; }
  .roadmap-right { justify-content: flex-end; }
  .roadmap-right .roadmap-step { margin-left: 80px; }
  .roadmap-year { height: 60px; width: 60px; line-height: 60px; font-size: 16px; position: static; margin-left: auto; margin-right: auto; }
  .roadmap-step { width: 100%; padding: 40px 30px 35px; }
  .roadmap-step:before, .roadmap-step:after { position: absolute; content: ''; }
  .roadmap-step:before { left: -91px; }
  .roadmap-step:after { width: 78px; left: -78px; }
  .roadmap-step-list li:before { top: 12px; }
  .roadmap-step-list-s1 li:after { left: 50%; }
  .roadmap-step-s1 { padding-top: 0; padding-left: 15px; padding-right: 15px; }
  .roadmap-step-s1:after { width: 0 !important; left: 50% !important; margin-left: -0.5px; height: 40px !important; top: 48px; border-top: 0; border-left: 1px solid; border-color: #dbe4f7; }
  .roadmap-s1-alt .roadmap-step-s1:after { border-left-style: dashed; }
  .roadmap-step-s3 { padding-top: 0; background: transparent !important; }
  .roadmap-step-s3:after { left: 50%; width: 2px; }
  .roadmap-step-s5 { padding-top: 0; padding-bottom: 0; }
  .roadmap-head { margin-bottom: 15px; }
  .roadmap-time { font-size: 14px; }
  .roadmap-time-s1:after { left: 50%; bottom: 5px; }
  .roadmap-time-s1:before { bottom: -2px; left: 50%; }
  .roadmap-time-s3 { font-size: .87rem; font-weight: 600; }
  .roadmap-time-s6 { font-size: 18px; }
  .roadmap-title { font-size: 18px; }
  .roadmap-title-s1 { padding-top: 48px; }
  .roadmap-title-s2 { font-size: 1rem; }
  .roadmap-list { display: flex; align-items: flex-end; margin-bottom: 200px; }
  .roadmap-item { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .roadmap-item:nth-child(even) { flex-grow: 0; width: 0; }
  .roadmap-item:nth-child(even) .roadmap-innr { position: absolute; top: 0; left: 0; padding-top: 90px; min-width: 190px; transform: translateX(calc(-50% + 5px)); }
  .roadmap-item:nth-child(even) .roadmap-innr:before { top: 0; height: 90px; }
  .roadmap-item-sm { min-height: 170px; }
  .roadmap-item-lg { min-height: 300px; } }

@media (min-width: 1200px) { .roadmap-left .roadmap-step:before { right: -91px; }
  .roadmap-left .roadmap-step:after { right: -73px; }
  .roadmap-step:before { left: -91px; }
  .roadmap-step:after { width: 73px; left: -73px; }
  .roadmap-step-s1:before { left: 50%; }
  .roadmap-step-s3:after { left: 50%; width: 2px; } }

/** 04.091 Token */
.token-info { border: 1px solid rgba(134, 107, 254, 0.2); border-radius: 8px; padding: 18px 20px 0; margin-bottom: 30px; }

.token-info-s1 { padding: 0 30px; border: none; text-align: left; }

.token-info-item { position: relative; padding-left: 25px; }

.token-info-item:before { position: absolute; left: 0; top: 0; bottom: 0; width: 5px; content: ''; border-radius: 2px; background: #a963ff; }

.token-info-item.color-2:before { background: #f42f54; }

.token-info-item.color-3:before { background: #f5a946; }

.token-info-item.color-4:before { background: #63a5ff; }

.token-info-item.color-5:before { background: #ff8d87; }

.token-info-item-s1 { padding-left: 0; display: flex; align-items: center; justify-content: space-between; margin: 25px 0; }

.token-info-item-s1:first-child { margin-top: 16px; }

.token-info-item-s1:before { display: none; }

.token-info-item-s1 span { font-size: 12px; font-weight: 400; color: #866bfe; text-transform: uppercase; }

.token-info-item-s1 h4 { font-size: 1.5rem; line-height: 1; font-weight: 300; }

.token-info-title { font-size: 0.7rem; text-transform: uppercase; font-weight: 700; letter-spacing: 0.04em; margin-bottom: 4px; }

.token-info-des { font-size: 1.1rem; }

.token-status { border-radius: 8px 8px 0 0; border: 1px solid rgba(134, 107, 254, 0.2); padding: 18px 20px 10px; text-align: center; }

.token-status .countdown-s2 { padding: 0; }

.token-status-s1 { padding: 30px; box-shadow: 0px 15px 20px 0px rgba(60, 28, 148, 0.1); margin-left: 0; }

.token-status-s2 { position: relative; border: none; padding: 0; max-width: 360px; margin: 50px auto 0; }

.token-status-s2 .icon-list { margin-top: 10px; }

.token-status-s3 { padding: 29px 0 0; border: none; }

.token-status-s3 .countdown-s2 { padding-left: 30px; padding-right: 30px; }

.token-status-s4 { border: none; padding: 65px 0 50px; }

.token-status-s4 .icon-list { margin-top: 10px; }

.token-status-s5 { background: #3c1c94; padding-bottom: 25px; }

.token-status-s6 { border: none; padding: 20px 20px 25px; }

.token-countdown { padding: 16px 0 20px; }

.token-min { font-size: 13px; color: #fff; display: block; padding: 12px 0; }

.token-min-s2 { padding: 15px 0 0; text-align: left; }

.token-min-s2 span { display: block; }

.token-min-s2 span:first-child { color: #866bfe; font-size: 12px; line-height: 1; text-transform: uppercase; font-weight: 300; }

.token-min-s2 span:last-child { line-height: normal; padding-top: 4px; }

.token-alocate-item { position: relative; padding: 0 20px 15px; margin-bottom: 30px; margin-left: auto; margin-right: auto; background: #fff; height: calc(100% - 30px); border-radius: 4px; }

.token-alocate-item:not([class*='shadow']) { box-shadow: 0 5px 45px 0 rgba(91, 83, 162, 0.08); }

.token-alocate-item-s1 { padding-left: 0; padding-right: 0; background-color: transparent; }

.token-alocate-graph { padding: 20px 0 15px; position: relative; }

.token-alocate-graph span, .token-alocate-graph img { position: absolute; text-align: center; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 4; }

.token-alocate-graph span { color: #5b53a2; font-size: 2em; font-weight: 400; line-height: 1.3; }

.token-alocate-graph img { width: 150px; }

.token-alocate-graph canvas { position: relative; z-index: 5; }

.token-alocate-graph-s1 canvas { min-height: 230px; }

.token-alocate-list li { color: #415076; display: flex; align-items: center; font-weight: 400; padding: 13px 0; }

.token-alocate-list li .token-alocate-percent { font-weight: 600; color: #3c1c94; }

.token-box-s1 { position: absolute !important; left: 0; bottom: -157px; width: 100%; z-index: 9 !important; }

.token-box-s2 { background: rgba(60, 28, 148, 0.3); padding: 30px 20px 52px; }

.token-box-s2 .title { margin-bottom: 1.2rem; }

.token-box-s3 { padding-bottom: 20px; }

.token-countdown-wrapper { padding: 30px; background: #fff; box-shadow: 0px 15px 20px 0px rgba(60, 28, 148, 0.1); }

.token-countdown-wrapper h6 { color: currentColor; }

.token-action-s1 { margin-top: -22px; }

.token-action-s2 { display: flex; align-items: center; justify-content: space-between; padding: 30px 0 10px; border-top: 1px solid rgba(255, 255, 255, 0.1); }

.token-action + .icon-list { margin-top: 15px; }

.token-sale-box { background: #f7f8ff; border-radius: 4px; max-width: 360px; margin-left: auto; margin-right: auto; text-align: center; padding: 20px 15px 33px; transition: all .4s; }

.tc-light .token-sale-box { background: rgba(134, 107, 254, 0.15); }

.token-sale-info { display: block; font-size: 2.4rem; font-weight: 300; color: #2bc9b4; }

.token-sale-title { font-size: 14px; text-transform: uppercase; font-weight: 400; }

.token-bonus-item { padding: 50px 25px 46px; max-width: 360px; margin-left: auto; margin-right: auto; text-align: center; border-radius: 4px; margin-bottom: 30px; transition: all .4s; height: calc(100% - 30px); background: #5126c7; }

.token-bonus-item span { display: block; }

.token-bonus-item.two { background: #6617b4; }

.token-bonus-item.three { background: #5f33d8; }

.token-bonus-title { display: inline-block !important; line-height: 18px; font-size: 13px; color: #2bc9b4; text-transform: uppercase; padding: 6px 20px; background: #2e1672; border-radius: 15px; font-weight: 400; }

.token-bonus-percent { font-size: 4em; line-height: 1; padding: 22px 0 7px; font-weight: 300; color: #fff; }

.token-bonus-txt { font-size: 14px; line-height: 18px; font-weight: 400; text-transform: uppercase; color: rgba(255, 255, 255, 0.6); }

.tc-light .token-bonus-txt { color: rgba(255, 255, 255, 0.6); }

.token-stage { margin-bottom: 33px; }

.token-stage-title { color: #fff; background: #441afd; text-transform: uppercase; font-weight: 700; line-height: 26px; padding: 10px; border-radius: 6px 6px 0 0; }

.token-stage-pre { background: #6542fe; }

.token-stage-one { background: #542efe; }

.token-stage-date { border: 1px solid rgba(209, 224, 246, 0.7); border-top: none; padding: 12px 0 10px; }

.tc-light .token-stage-date { border-color: rgba(255, 255, 255, 0.1); }

.token-stage-date h6 { font-weight: 700; font-size: 1rem; margin-bottom: 0; }

.token-stage-date span { font-size: 15px; }

.token-stage-info { border: 1px solid rgba(209, 224, 246, 0.7); border-top: none; border-radius: 0 0 6px 6px; padding: 14px 0 16px; }

.tc-light .token-stage-info { border-color: rgba(255, 255, 255, 0.1); }

.token-stage-info span { display: block; font-size: 15px; line-height: 1.86; }

.token-stage-bonus { color: #866bfe; }

.token-action-s2 { padding: 30px; border-top: 1px solid rgba(255, 255, 255, 0.1); }

.token-action-box { display: flex; align-items: center; flex-wrap: wrap; border-radius: 6px; background: rgba(134, 107, 254, 0.1); }

.token-action-box > div { padding: 20px 25px; width: 100%; }

.token-action-box > div:last-child { padding-top: 0; }

.tc-light .token-action-box { background: rgba(134, 107, 254, 0.1); }

.token-action-title { width: 30%; background: #866bfe; border-radius: 6px 6px 0 0; font-weight: 500; font-size: 1.2em; line-height: 1.44; flex-grow: 1; width: 100%; }

.tc-light .token-action-title { background: #866bfe; }

.token-action-box .token-action-title { padding: 25px; }

.token-action-date { width: 40%; }

.token-action-date strong { display: block; }

.token-action-btn { width: 34%; }

.token-action-btn .btn-grad { padding: 14px 0px; width: 100%; }

.token-bar-chart { position: relative; z-index: 9; }

.token-bar-item { position: relative; height: 40px; border-radius: 20px; margin: 10px 0; }

.token-bar-item.tbic1 { background: #002674; }

.token-bar-item.tbic1:before { background: #002674; }

.token-bar-item.tbic2 { background: #004ae2; }

.token-bar-item.tbic2:before { background: #004ae2; }

.token-bar-item.tbic3 { background: #3b7bff; }

.token-bar-item.tbic3:before { background: #3b7bff; }

.token-bar-item.tbic4 { background: #3bb8ff; }

.token-bar-item.tbic4:before { background: #3bb8ff; }

.token-bar-item.tbic5 { background: #5378fb; }

.token-bar-item.tbic5:before { background: #5378fb; }

.token-bar-txt { position: absolute; font-size: 13px; padding-bottom: 0; top: 50%; left: 100%; min-width: 300%; transform: translateX(45px) translateY(-50%); }

.token-bar-txt:after { position: absolute; content: ''; left: 0; top: 50%; height: 1px; width: 25px; margin-left: -35px; background: rgba(22, 161, 255, 0.5); }

.token-bar-txt span { display: block; line-height: 1.5; white-space: nowrap; }

.token-bar-txt span:first-of-type { font-weight: 600; }

.token-bar-item.tbic1 .token-bar-txt span:first-of-type { color: #002674; }

.token-bar-item.tbic2 .token-bar-txt span:first-of-type { color: #004ae2; }

.token-bar-item.tbic3 .token-bar-txt span:first-of-type { color: #3b7bff; }

.token-bar-item.tbic4 .token-bar-txt span:first-of-type { color: #3bb8ff; }

.token-bar-item.tbic5 .token-bar-txt span:first-of-type { color: #5378fb; }

@media (max-width: 375px) { .token-info-item-s1 h4 { font-size: 1.25rem; } }

@media (min-width: 768px) { .token-bar-chart { display: flex; overflow: visible; margin-top: 135px; }
  .token-bar-item { margin: 0; }
  .token-bar-item:before { position: absolute; left: -20px; top: 0; height: 40px; width: 40px; content: ''; z-index: -1; }
  .token-bar-item:first-child:before { display: none; }
  .token-bar-txt { padding-bottom: 10px; top: -110px; left: 50%; min-width: 100%; transform: translateX(-50%); text-align: center; }
  .token-bar-txt:after { left: 50%; top: 100%; height: 65px; width: 1px; margin-left: 0; } }

@media (max-width: 420px) { .token-status-s6 .countdown-time { font-size: 1.8rem; }
  .token-status-s6 .cpn-links, .token-status-s6 .cpn-links li { padding-left: 10px; padding-right: 10px; } }

@media (min-width: 480px) { .token-status .countdown-s2 { padding: 0 30px 20px; }
  .token-info-s1 { padding: 0 30px; }
  .token-min-s2 { padding: 0; } }

@media (min-width: 576px) { .token-info { padding: 24px 40px 0; }
  .token-info .text-block-title { text-align: center; }
  .token-info-list li { display: flex; }
  .token-info-list li > span { width: 50%; }
  .token-info-title { font-size: 0.88rem; letter-spacing: 0.2em; }
  .token-info-des { font-size: 1.375rem; }
  .token-info-s1 { padding: 0 30px; }
  .token-status { padding: 24px 28px 20px; }
  .token-status-md { padding: 36px; }
  .token-status-s2 { padding: 0; }
  .token-status-s3 { padding: 29px 0 0; border: none; }
  .token-status-s4 { padding: 65px 0 50px; }
  .token-status-s5 { padding: 40px 30px 40px; }
  .token-status-s6 { border: none; padding: 35px 50px 45px; }
  .token-alocate-item { padding: 30px 65px 60px; }
  .token-alocate-item-s1 { padding-left: 0; padding-right: 0; }
  .token-alocate-graph { padding: 30px 40px 30px; }
  .token-action-box { flex-wrap: nowrap; }
  .token-action-box > div:last-child { padding-top: 20px; padding-left: 0; }
  .token-action-title { width: auto; border-radius: 6px 0 0 6px; }
  .token-action-s2 { padding: 30px; } }

@media (min-width: 768px) { .token-alocate-item { padding: 20px 30px 40px; }
  .token-alocate-item-s1 { padding-left: 0; padding-right: 0; margin-bottom: 0; }
  .token-alocate-graph { padding: 30px 0 30px; }
  .token-box-s1 { position: absolute !important; left: 0; bottom: -45px; width: 100%; z-index: 9 !important; } }

@media (min-width: 992px) { .token-countdown-wrapper { padding: 30px 60px 30px 66px; }
  .token-status-s1 { padding: 30px 60px 30px 66px; margin-left: 0; }
  .token-status-s2 { margin: 65px 140px 0 0; }
  .token-status-s3 { margin-left: 25px; }
  .token-alocate-item { padding: 30px 65px 60px; }
  .token-alocate-item-s1 { padding: 30px 65px 0px; }
  .token-alocate-item-s1:before { position: absolute; height: 610px; width: 610px; top: 0; left: 50%; content: ''; border-radius: 20px; background: rgba(60, 28, 148, 0.04); transform: rotate(45deg); transform-origin: 0 0; }
  .token-alocate-graph { padding: 30px 0 30px; }
  .token-alocate-graph-s1 { margin-top: 80px; }
  .token-action-box { margin-top: 15px; } }

.tokdis-item { position: relative; padding-left: 0; padding-bottom: 15px; margin-bottom: 15px; padding-top: 8px; display: inline-block; }

.tokdis-item:before { position: absolute; width: 40px; height: 3px; border-radius: 1.5px; content: ''; background: #866bfe; top: 0; left: 50%; transform: translateX(-50%); }

.tokdis-item h5 { font-size: 1.125em; }

.tokdis-list { padding-bottom: 40px; }

@media (min-width: 992px) { .tokdis-item { padding-left: 25px; padding-bottom: 4px; padding-top: 0; }
  .tokdis-item:before { width: 3px; height: 100%; top: 0; left: 0; transform: translateX(0); }
  .tokdis-list { padding-bottom: 80px; } }

.toktmln-item { position: relative; padding: 0 0 20px; display: flex; justify-content: center; }

.toktmln-item:before { position: absolute; top: -3px; left: 50%; transform: translateX(-50%); content: ''; height: 30px; width: 30px; border-radius: 50%; border: 6px solid #dbe4f7; background: #866bfe; }

.tc-light .toktmln-item:before { border-color: #fff; }

.toktmln-item div { padding: 0 30px 5px 0; text-align: right; width: 50%; flex-grow: 1; }

.toktmln-item div:last-child { padding: 0 0 0 30px; text-align: left; }

.toktmln-item div span { display: block; font-weight: 600; }

.toktmln-item div span:last-child { font-size: .775em; padding-bottom: 8px; font-weight: 400; color: #415076; }

.tc-light .toktmln-item div span:last-child { color: #fff; }

.toktmln-list { position: relative; }

.toktmln-list:before { position: absolute; left: 50%; top: -24px; width: 2px; margin-left: -1px; height: 100%; background: #dbe4f7; content: ''; }

.tc-light .toktmln-list:before { background: #fff; }

@media (min-width: 992px) { .toktmln-list { position: relative; }
  .toktmln-list:before { left: 0; top: 14px; width: 100%; height: 2px; }
  .toktmln-item { padding: 64px 0 0; display: block; }
  .toktmln-item:before { top: 0; left: 0; transform: translateX(0); }
  .toktmln-item div { padding-bottom: 5px; text-align: left; width: 100%; padding-left: 0; padding-right: 0; }
  .toktmln-item div:last-child { padding: 0 0 0 0; }
  .toktmln-item div span:last-child { font-size: .875em; } }

.stage-info { padding-left: 15px; }

.stage-info .title { margin-bottom: .5rem; }

/** 04.10 Team */
.team { position: relative; margin-bottom: 30px; max-width: 255px; margin-left: auto; margin-right: auto; text-align: center; }

.nk-block-left .team { max-width: 100%; }

.team:hover .team-show { opacity: 1; }

.team-s2 { max-width: 240px; text-align: center; }

.team-s2 .word-split { justify-content: center; }

.team-s2:hover .team-show { opacity: .25; }

.team-s4 { padding: 30px 30px; }

.team-s5 { text-align: left; max-width: 228px; padding-bottom: 25px; }

.team-s5:before { position: absolute; content: ""; left: 20px; top: 50px; right: 0; bottom: 0; background: #eaeff6; z-index: -1; }

.bg-theme .team-s5:before { background: #6e19c2; }

.has-bg-image .team-s5:before { background: #6e19c2; }

.tc-light .team-s5:before { background: #2e186a; }

.team-s6 { text-align: left; display: flex; max-width: 100%; }

.team-s6 .team-left { width: 135px; flex-shrink: 0; }

.team-s6 .team-photo { max-width: 170px; margin-right: 25px; margin-left: 0; }

.team-sm-s2 { text-align: left; max-width: 145px; }

.team-photo { display: block; position: relative; margin-left: auto; margin-right: auto; transition: all .4s; z-index: 2; }

.team-photo + .list { padding-top: 15px; }

.team-photo-s1 { max-width: 160px; }

.team-photo img { border-radius: 6px; width: 100%; transition: all .4s; }

.team-photo-sm { max-width: 180px; }

.team-photo-s2 { max-width: 90%; }

.team-photo-s2:after { position: absolute; left: 0; top: 0; content: ''; height: 100%; width: 100%; background: rgba(134, 107, 254, 0.4); border-radius: 6px; z-index: 1; }

.team-photo-s3:after { position: absolute; left: 0; top: 0; content: ''; height: 100%; width: 100%; background: linear-gradient(to top left, #866bfe 0%, #2bc9b4 100%); border-radius: 6px; z-index: 1; opacity: .2; }

.team-photo-circle { border-radius: 50%; }

.team-sm-s2 .team-photo { margin-left: 0; max-width: 90%; }

.team-sm-s2:hover .team-photo { transform: translateY(-8px); }

.team-s3 .team-photo { max-width: 160px; }

.team-s5 .team-photo { margin-left: 0; }

.team-s5 .team-photo:before, .team-s5 .team-photo:after { position: absolute; content: ''; }

.team-s5 .team-photo:before { top: 24px; right: -33px; height: 3px; width: 65px; background: linear-gradient(to top left, #866bfe 0%, #2bc9b4 100%); z-index: 1; }

.team-s5 .team-photo:after { top: 0; bottom: 0; right: 0; left: 0; background: rgba(134, 107, 254, 0.4); transition: all .4s; }

.team-s5 .team-photo:hover { transform: translateY(-8px); }

.team-s5 .team-photo:hover:after { opacity: .5; }

.team-s5 .team-photo:hover .team-show { opacity: 0; }

.team-photo-bg:before { position: absolute; content: ""; border-radius: 50%; top: -12px; left: -12px; right: -12px; bottom: -12px; transform: scale(1); z-index: -1; opacity: 1; transition: all .5s ease; background-image: linear-gradient(90deg, #2bc9b4 0%, #866bfe 100%); }

.team-s3-alt .team-photo-bg:before { opacity: 0; transform: scale(0.8); }

.team-photo-bg a { position: absolute; content: ""; border-radius: 50%; top: -12px; left: -12px; right: -12px; bottom: -12px; z-index: 1; }

.team-photo-bg:hover a + img { transform: scale(0.85); }

.team-s3-alt .team-photo-bg:hover a + img { transform: scale(1); }

.team-s3-alt .team-photo-bg:hover:before { opacity: 1; transform: scale(1); }

.team-show { position: absolute; left: 0; top: 0; content: ''; height: 100%; width: 100%; background: rgba(134, 107, 254, 0.4); border-radius: 6px; transition: all .4s; z-index: 20; opacity: 0; }

.team-s2 .team-show { opacity: 0; }

.team-s3 .team-photo-bg .team-show { opacity: 0 !important; top: 0; right: 0; bottom: 0; left: 0; }

.team-s5 .team-show { border-radius: 0; }

.team-s5:hover .team-show { opacity: 0; }

.team-social { margin: 0 -5px; }

.team-photo .team-social { position: absolute; bottom: -17px; right: 17px; z-index: 21; text-align: right; }

.team-social li { padding: 0 4px; display: inline-block; }

.team-social li a { font-size: 13px; text-align: center; display: inline-block; line-height: 34px; height: 34px; width: 34px; border-radius: 50%; color: #fff !important; background: #2bc9b4; box-shadow: 0px 2px 15px 0px rgba(43, 201, 180, 0.5); }

.team-social li a:hover { color: #fff !important; background: #866bfe; box-shadow: 0px 2px 15px 0px rgba(134, 107, 254, 0.5); }

.team-social-s2 { margin: 0 -10px; }

.team-social-s2 li { padding: 0 10px; }

.team-social-s2 li a { width: auto; background-color: transparent; box-shadow: none; color: #415076 !important; }

.tc-light .team-social-s2 li a { color: #fff !important; }

.team-social-s2 li a:hover { color: #866bfe !important; background-color: transparent; box-shadow: none; }

.team-s2 .team-social-s2 { padding-top: 15px; }

.team-s3 .team-social-s2 { padding-top: 10px; }

.team-social-vr li { display: block; text-align: center; }

.team-social-vr li a { width: 30px !important; }

.team-s5 .team-social { position: absolute; top: 64px; right: 20px; }

.team-s5 .team-social li a { color: #866bfe; }

.team-name { color: #415076; padding-top: 28px; font-weight: 600; }

.team-name.title { margin-bottom: .5rem !important; }

.team-s2 .team-name.title-sm { font-size: .95em !important; }

.team-s2 .team-name { padding-top: 18px; }

.team-s3 .team-name { color: #866bfe; font-size: 1.125rem !important; font-weight: 400; margin-bottom: 1px !important; }

.team-s3-alt .team-name { color: #5b53a2; }

.tc-light .team-s3-alt .team-name { color: #fff; }

.team-s4 .team-name { font-weight: 400; padding-top: 21px; font-size: 1.33rem; margin-bottom: 0 !important; }

.team-s5 .team-name { padding-left: 45px; padding-right: 40px; }

.team-s6 .team-name { text-transform: uppercase; font-weight: 400; }

.team-position { font-size: 12px; font-weight: 400; display: inline-block; }

.team-s3 .team-position { font-weight: 300; color: #536697; }

.team-s3-alt .team-position { color: #866bfe; }

.tc-light .team-s3-alt .team-position { color: #866bfe; }

.team-s4 .team-position { padding-bottom: 10px; }

.team-s5 .team-position { padding-left: 45px; padding-right: 40px; color: #5b53a2; }

.team-s6 .team-position { position: relative; color: #866bfe; padding-bottom: 13px; margin-bottom: 5px; line-height: 1.4; }

.team-s6 .team-position:before { position: absolute; height: 3px; width: 190px; left: 0; bottom: 0; content: ''; background: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); z-index: 5; }

.tc-light .team-position { color: #af9cff; }

.team-featured-photo { position: relative; border-radius: 5px; overflow: hidden; }

.team-featured-info { position: absolute; left: 0; bottom: 0; font-size: 18px; padding: 15px 20px; }

.team-featured-info span { font-size: 16px; font-weight: 300; display: block; padding-top: 5px; }

.team-featured-cont p:last-child { margin-bottom: 0; }

.team-featured-sub { text-transform: uppercase; color: #fff; font-size: 14px; letter-spacing: 0.15em; }

.team-popup { position: relative; max-width: 100%; margin: 20px auto; padding: 15px; background: #fff; border-radius: 6px; }

.team-popup-info p:last-child { margin-bottom: 0; }

.team-list { margin-bottom: -30px; }

.team-s4 .team-desc { font-size: .87rem; margin-bottom: 8px; }

.team-s4 .team-desc p { font-size: inherit; }

@media (min-width: 576px) { .team { margin-bottom: 50px; }
  .team-s2 { margin-bottom: 30px; text-align: left; }
  .team-s2 .word-split { justify-content: flex-start; }
  .team-s4 { padding: 30px 15px; }
  .team-s2 .team-photo { margin-left: 0; }
  .team-s2 .team-name { padding-top: 25px; }
  .team-featured-info { padding: 25px 30px; }
  .team-popup { padding: 40px; }
  .team-list { margin-bottom: -50px; } }

@media (min-width: 768px) { .team-even { margin-top: 60px; }
  .team-s4 { margin-bottom: 50px; } }

@media (min-width: 992px) { .team-featured-cont { padding-left: 20px; }
  .team-featured-title { padding: 8px 0 15px; }
  .team-name { padding-top: 34px; margin-bottom: 8px !important; }
  .team-s2 .team-name, .team-s3-alt .team-name { padding-top: 20px; }
  .team-s5 .team-name { padding-top: 24px; }
  .team-position { font-size: 0.88rem; }
  .team-position-sm { font-size: 13px; }
  .team-social { bottom: -20px; right: 20px; margin: 0 -5px; }
  .team-social li { padding: 0 4px; }
  .team-social li a { font-size: 16px; line-height: 40px; height: 40px; width: 40px; }
  .team-social-s2 { margin: 0 -8px; }
  .team-social-s2 li { padding: 0 8px; }
  .team-social-s2 li a { font-size: 14px; height: auto; width: auto; }
  .team-popup { margin: 10px auto; width: 950px; } }

@media (min-width: 992px) and (max-width: 1199px) { .team-s5 .team-social { right: 9px; } }

@media (min-width: 1600px) { .team-photo-s2 { max-width: 100%; } }

/** 04.11 Partners */
.partner-list { display: flex; flex-wrap: wrap; justify-content: center; margin-bottom: -15px; }

.partner-list-left { justify-content: left; }

.partner-list-lg { margin-bottom: -20px; }

.partner-logo { padding: 15px 10px; }

[class*="col-"] > .partner-logo { padding: 0; }

.partner-logo img { max-height: 30px; max-width: 100%; width: auto !important; margin-left: auto; margin-right: 0; }

.has-carousel .partner-logo img { margin-right: auto; }

.partner-list-lg .partner-logo { padding: 20px 25px; }

.partner-list-lg .partner-logo img { max-height: 50px; }

.partner-logo-s2, .partner-logo-s3 { width: 50%; max-width: 50%; text-align: center; padding: 0 10px; }

.partner-logo-s2 img, .partner-logo-s3 img { max-height: 50px; }

.partner-list-left .partner-logo-s2, .partner-list-left .partner-logo-s3 { text-align: left; }

.partner-logo-s3 img { max-height: 60px; }

.partner-logo-lg { width: 50%; margin-bottom: 30px; }

.partner-box { padding: 15px 0px; margin-bottom: 30px; }

@media (min-width: 576px) { .partner-logo-s2, .partner-logo-s3 { margin-bottom: 30px; max-width: 16.6%; width: 16.6%; }
  .partner-logo-lg { width: 33%; }
  .partner-box { padding: 15px 66px; box-shadow: 0px 5px 25px rgba(110, 25, 194, 0.1); } }

@media (min-width: 992px) { .partner-box .partner-list { justify-content: space-between; }
  .partner-list-lg { margin-bottom: -20px; }
  .partner-list-lg .partner-logo { padding: 20px 25px; }
  .partner-list-lg .partner-logo img { max-height: 50px; }
  [class*="col-"] > .partner-logo { padding: 15px 0; } }

/** 04.12 Contact */
.contact-wrap { padding: 20px; }

.contact-list { margin-top: -7.5px; margin-bottom: -7.5px; position: relative; z-index: 2; }

.contact-list li { display: flex; padding-top: 7.5px; padding-bottom: 7.5px; font-size: 0.94rem; }

.contact-list-s1 { display: flex; justify-content: center; }

.contact-list-s1 li { width: 100%; flex-wrap: wrap; justify-content: center; }

.contact-icon { height: 38px; width: 38px; font-size: 16px; line-height: 38px; text-align: center; color: #fff; background: #2bc9b4; box-shadow: 0px 2px 15px 0px rgba(43, 201, 180, 0.5); border-radius: 50%; margin-right: 15px; flex-shrink: 0; }

.tc-light .contact-icon { color: #3c1c94; background: #fff; box-shadow: none; }

.bg-secondary .contact-icon { color: #2bc9b4; }

.contact-list-s1 .contact-icon { margin-right: 0; color: #fff; box-shadow: 0px 2px 15px 0px rgba(60, 28, 148, 0.5); background: #fff;     display: flex;
  justify-content: center;
  align-items: center;}

.tc-light .contact-list-s1 .contact-icon { color: #3c1c94; background: #fff; }

.contact-icon-s2 { color: #2bc9b4; background: transparent; box-shadow: inset 0 0 0 2px #2bc9b4; }

.tc-light .contact-icon-s2 { color: #fff; background-color: transparent; box-shadow: inset 0 0 0 2px #fff; }

.contact-icon-s3 { position: relative; color: #866bfe; background: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); box-shadow: none; }

.tc-light .contact-icon-s3 { color: #fff; background: linear-gradient(to right, #866bfe 0%, #2bc9b4 100%); }

.contact-icon-s3:after { position: absolute; top: 2px; right: 2px; bottom: 2px; left: 2px; content: ''; background: #fff; border-radius: 50%; z-index: 0; }

.bg-light .contact-icon-s3:after { background: #f7f8ff; }

.bg-light-alt .contact-icon-s3:after { background: #eaeff6; }

.bg-theme .contact-icon-s3:after { background: #3c1c94; }

.bg-theme-alt .contact-icon-s3:after { background: #6e19c2; }

.bg-theme-dark .contact-icon-s3:after { background: #180747; }

.bg-theme-dark-alt .contact-icon-s3:after { background: #2e186a; }

.bg-theme-accent .contact-icon-s3:after { background: #866bfe; }

.bg-theme-accent-alt .contact-icon-s3:after { background: #2bc9b4; }

.contact-icon-s3:before { position: relative; z-index: 1; }

.contact-text { padding-top: 7px; }

.contact-list-s1 .contact-text { width: 100%; }

.contact-social { margin-top: auto; padding-top: 20px; }

.contact-social h6 { margin-bottom: 1rem; }

.subscribe-wrap { padding: 20px; }

@media (min-width: 576px) { .contact-wrap { padding: 50px; }
  .contact-wrap-lg { padding: 95px; }
  .subscribe-wrap { padding: 65px; } }

@media (min-width: 768px) { .contact-list-s1 li { width: 33.33%; flex-wrap: wrap; }
  .contact-list-s1 .contact-text { width: auto; }
  .contact-list-s1 .contact-icon { margin-right: 15px; } }

@media (max-width: 480px) { .contact-list-s2 { flex-wrap: wrap; } }

@media (max-width: 767px) { .contact-list-s2 li { flex-wrap: wrap; text-align: center; }
  .contact-list-s2 li .contact-icon { margin-right: 0; }
  .contact-list-s2 li .contact-text { width: 100%; } }

@media (max-width: 991px) { .contact-list-s2 { display: flex; justify-content: center; }
  .contact-list-s2 li { width: 100%; justify-content: center; } }

@media (min-width: 992px) and (max-width: 1199px) { .contact-wrap, .subscribe-wrap { padding: 35px; } }

.social-links { display: flex; margin: -3px; }

.social-links li { padding: 3px; }

.social-links li a { display: inline-block; border-radius: 50%; border: 1px solid #fff; height: 36px; width: 36px; line-height: 36px; text-align: center; color: #415076; }

.social-links li a:hover { color: #866bfe; background: #fff; }

.tc-light .social-links li a { color: #fff; }

.tc-light .social-links li a:hover { color: #866bfe; }

.bg-secondary .social-links li a:hover { color: #2bc9b4 !important; }

.social-links-s2 li a { border: none; }

.social-links-s2 li a:hover { background-color: #fff; color: #866bfe !important; }

.tc-light .social-links-s2 li a:hover { color: #866bfe !important; }

@media (min-width: 992px) { .section-contact .ovm-h-60 { height: 50%; } }

/** 04.13 WGS */
.wgs { margin-bottom: 30px; }

.wgs-title { text-transform: uppercase; color: #5b53a2; font-size: 1rem; font-weight: 600; letter-spacing: 0.075em; }

.wgs-title-s2, .wgs-title-s3 { font-size: 1.125rem; letter-spacing: normal; }

.wgs-title-s2 { margin-bottom: 18px; font-weight: 600; }

.wgs-title-s3 { font-weight: 700; margin-bottom: 22px; padding-top: 10px; }

.wgs-title-s4 { text-transform: capitalize; letter-spacing: normal; font-size: 0.95rem; }

.tc-light .wgs-title { color: #2bc9b4; }

.wgs-logo { padding-bottom: 22px; display: inline-block; }

.wgs-body p { font-size: 0.93em; line-height: 1.71; margin-bottom: 0.75rem; }

.wgs-body p:last-child { margin-bottom: 0; }

.wgs-list li { font-size: .9em; padding-bottom: 14px; letter-spacing: 0.01em; }

.wgs-list li:last-child { padding-bottom: 0; }

.wgs-links li { position: relative; padding: 5px 0; line-height: 1; }

.wgs-links li:last-child { padding-bottom: 0px; }

.wgs-links li a { font-size: 0.9em; line-height: 20px; display: block; color: #5b53a2; }

.wgs-menu-s2 .wgs-links li a { font-size: 0.85em; opacity: 0.7; color: #5b53a2; font-weight: 400; }

.wgs-menu-s2 .wgs-links li a:hover { opacity: 1; color: #5b53a2; }

.wgs-links li a:hover { color: #866bfe; }

.tc-light .wgs-links li a { color: #fff !important; }

.tc-light .wgs-links li a:hover { color: #866bfe !important; }

.wgs-links-s2 li { padding: 7px 0; }

.wgs-links-s2 li a { font-size: 1em; }

.wgs-links-s3 li { padding: 9px 0; }

.wgs-links-s4 li { padding: 7px 0; }

.wgs-links-s5 li { margin-bottom: 17px; }

.wgs-links-s5 li:first-child { margin-top: -5px; }

.wgs-links-s5 li a { font-size: 1rem; font-weight: 400; }

.wgs-links-uline li a { display: inline-block; }

.wgs-links-uline li a:hover { box-shadow: 0 1px 0 0 currentColor; }

.wgs-links-category li { padding-left: 20px; }

.wgs-links-category li a { padding: 10px 0; }

.wgs-links-category li:before { position: absolute; left: 0; top: 50%; margin-top: -1px; height: 2px; width: 8px; content: ''; background: #866bfe; }

.wgs-links-tags { padding: 12px 0; margin: -7px -5px; }

.wgs-links-tags li { display: inline-block; padding: 7px 5px; }

.wgs-links-tags li a { padding: 7px 16px; border: 1px solid #dbe4f7; border-radius: 3px; }

.wgs-links-tags li a:hover { color: #fff; border-color: #866bfe; background: #866bfe; }

.wgs-links-3clumn li { width: 32%; display: inline-block; }

.wgs-archive .wgs-body { padding: 12px 0; }

.wgs-cta .wgs-body { padding: 30px 30px 35px; }

.wgs-address { font-size: .9rem; line-height: 2; }

.wgs-address li:not(:last-child) { margin-bottom: 10px; }

.wgs-regular { font-weight: 400; }

@media (min-width: 992px) { .wgs-subscribe-form-s1 { margin-left: -50px; padding-right: 50px; } }

.copyright-text { color: #415076; }

.tc-light .copyright-text { color: #af9cff; }

.copyright-text p { color: inherit; }

.copyright-text a { color: #415076; }

.copyright-text a:hover { color: #866bfe; }

.tc-light .copyright-text a { color: #af9cff; }

.tc-light .copyright-text a:hover { color: #866bfe; }

.copyright-text-s1 { font-size: 14px; }

.copyright-text-s1 p { font-size: inherit; }

.copyright-text-s2 { text-transform: uppercase; font-size: 11px; letter-spacing: 0.1em; }

.copyright-text-s3 { font-size: 13px; color: #5b53a2; }

.tc-light .copyright-text-s3 { color: #af9cff; }

.copyright-text-s3 p { font-size: 13px; color: inherit; }

.copyright-text-s3 p a { color: inherit !important; }

.copyright-text-s3 p a:hover { box-shadow: 0 1px 0 0 currentColor; }

.copyright-text-s3 a { font-weight: 500; }

.copyright-text-s3 span { color: #415076; padding-bottom: 3px; }

.tc-light .copyright-text-s3 span { color: #fff;     font-size: 14px;}

.copyright-text-s4 p { font-size: 0.9rem !important; }

.copyright-text li a, .copyright-text p { font-size: 14px; color: #fff; 
max-width: 476px;
  text-align: center;
  padding: 20px;
}

.copyright-text li { width: 100%; }

.footer-nav { margin: 0 -15px; }

.footer-nav li { display: inline-block; padding: 0 15px; }

.footer-nav li a { color: #415076; font-size: 12px; text-transform: uppercase; letter-spacing: 0.1em; }

.footer-nav li a:hover { color: #866bfe !important; }

.tc-light .footer-nav li a { color: #fff !important; }

.tc-light .footer-nav li a:hover { color: #866bfe !important; }

@media (min-width: 576px) { .copyright-text-s2 { font-size: 12px; }
  .copyright-text li { width: auto; } }

.wgs-nl-title { margin-bottom: 32px; }

/** 04.14 Others */
.rank-list { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; }

.rank-item { background-image: url(../images/hexagon.png); height: 88px; width: 94px; padding: 16px 20px 0; }

.rank-item img { max-height: 16px; }

.rank-count { font-size: 1rem; color: #6e19c2; font-weight: 700; padding-top: 4px; line-height: 1.3; }

.rank-count span { font-size: .7rem; color: #fff; }

@media (min-width: 400px) { .rank-item { height: 104px; width: 108px; padding-top: 24px; } }

@media (min-width: 768px) { .rank-item { height: 136px; width: 146px; padding: 32px 30px 0; }
  .rank-item img { max-height: 24px; }
  .rank-count { padding-top: 10px; font-size: 1.4rem; }
  .rank-count span { font-size: .9rem; } }

@media (min-width: 992px) { .rank-item { height: 160px; width: 164px; padding-top: 38px; } }

.rating-info { font-size: 2.23em; line-height: 1; padding: 16px 0; font-weight: 700; background: #eaeff6; border-radius: 6px 6px 0 0; color: #415076; }

.rating-info span { font-size: .5em; font-weight: 400; color: #fff; margin-left: 6px; }

.tc-light .rating-info span { color: #eaeff6; }

.rating-info span:before { content: '/'; }

.tc-light .rating-info { color: #fff; background: #8d33e5; }

.rating-title { background: #866bfe; color: #fff; padding: 5px 0; font-size: .93em; line-height: 20px; border-radius: 0 0 6px 6px; }

.tc-light .rating-title { background: #180747; }

.bonus { border-radius: 0 0 8px 8px; border: 1px solid rgba(134, 107, 254, 0.2); border-top: none; display: flex; }

.bonus-info { position: relative; width: 50%; padding: 12px 16px 16px; }

.bonus-info:not(:last-child) { border-right: 1px solid rgba(134, 107, 254, 0.2); }

.bonus-percent { font-size: 20px; }

.bonus-percent small { font-size: 14px; }

.bonus-date { font-size: 12px; color: #fff; }

.bonus-badge { position: absolute; top: 5px; right: 5px; text-transform: uppercase; border-radius: 11px; line-height: 15px; font-size: 8px; font-weight: 500; padding: 0 8px; color: #fff; background: #2bc9b4; }

@media (min-width: 420px) { .bonus-info { padding: 12px 30px 16px; }
  .bonus-percent { font-size: 28px; }
  .bonus-percent small { font-size: 14px; }
  .bonus-date { font-size: 12px; color: #fff; }
  .bonus-badge { top: 50%; right: 0; transform: translateY(-50%) rotate(-90deg); line-height: 22px; font-size: 11px; padding: 0 10px; } }

.chart-data li { display: flex; font-size: 1rem; line-height: 1.4; font-weight: 400; padding: 7px 0; }

.chart-data li span { display: block; }

.chart-data-s1 { display: flex; flex-wrap: wrap; }

.chart-data-s1 li { display: flex; align-items: center; width: auto; transition-delay: .8s; width: 100%; }

.chart-data-s1 .chart-p { min-width: 55px; height: 55px; line-height: 50px; padding: 0; color: #fff; transform: rotate(45deg); border-radius: 10px; margin: 30px; order: -1; font-size: 16px; font-weight: 600; line-height: 55px; }

.chart-data-s1 .chart-p span { transform: rotate(-45deg); display: block; }

.chart-data-s1 .chart-l { text-align: left; }

.chart-data-s2 { display: flex; flex-wrap: wrap; margin: -10px; }

.chart-data-s2 li { padding: 10px; }

.chart-data-item { position: relative; padding: 15px 25px; }

.chart-data-item:before, .chart-data-item:after { position: absolute; content: ''; top: 0; height: 100%; width: 45px; border: 2px solid #dbe4f7; transition: all .4s; }

.tc-light .chart-data-item:before, .tc-light .chart-data-item:after { border-color: rgba(219, 228, 247, 0.5); }

.active > .chart-data-item:before, .active > .chart-data-item:after { border-color: #866bfe; }

.chart-data-item:before { left: 0; border-radius: 6px 0 0 6px; border-right: none; }

.chart-data-item:after { right: 0; border-radius: 0 6px 6px 0; border-left: none; }

.chart-label { font-size: 14px; font-weight: 700; color: #5b53a2; transition: all .4s; }

.tc-light .chart-label { color: #fff; }

.active > div > .chart-label { color: #866bfe; }

.chart-info { display: block; }

.chart-percent { font-size: 22px; font-weight: 400; color: #5b53a2; }

.tc-light .chart-percent { color: #fff; }

.chart-sublabel { font-size: 13px; color: #415076; margin-left: 8px; }

.tc-light .chart-sublabel { color: #fff; }

.chart-p { margin-left: auto; font-size: 1.125rem; font-weight: 500; color: #3c1c94; }

.chart-c { position: relative; top: 3px; height: 14px; width: 14px; border-radius: 3px; margin-right: 12px; flex-shrink: 0; display: block; }

.chart-canvas { min-height: 300px; max-width: 100%; margin-left: auto; margin-right: auto; }

.chart-canvas-s1 { min-height: 215px; }

@media (min-width: 480px) { .chart-data-s1 li { width: 50%; } }

@media (min-width: 576px) { .chart-label, .chart-percent { font-size: 18px; }
  .chart-c { top: 6px; }
  .chart-canvas { min-height: 360px; }
  .chart-canvas-s1 { min-height: 215px; } }

@media (min-width: 768px) { .chart-data-s1 li { width: 33.33%; } }

@media (min-width: 992px) { .chart-data-s1 li { position: absolute; width: auto; transition: all .4s; opacity: 0; }
  .chart-data-s1 li:nth-child(1) { top: -60px; left: 50%; transform: translateX(-50%) translateY(-30px) !important; flex-wrap: wrap; transition-delay: .6s; }
  .chart-data-s1 li:nth-child(1) .chart-l { order: -1 !important; width: 100%; }
  .chart-data-s1 li:nth-child(1) .chart-p { margin: 20px auto; transform: rotate(0); }
  .chart-data-s1 li:nth-child(1) .chart-p span { transform: rotate(0); }
  .animate.show .chart-data-s1 li:nth-child(1) { transform: translateX(-50%) translateY(0) !important; }
  .chart-data-s1 li:nth-child(odd) { left: 50%; transform: translateX(30px) translateY(0); }
  .chart-data-s1 li:nth-child(odd) .chart-l { order: 1; }
  .chart-data-s1 li:nth-child(even) { right: 50%; transform: translateX(-30px) translateY(0); }
  .chart-data-s1 li:nth-child(even) .chart-l { order: -1; text-align: right; }
  .chart-data-s1 li:nth-child(even) .chart-p { left: auto; right: 29px; top: 33px; }
  .chart-data-s1 li:nth-child(2) { top: 65px; margin-right: 65px; transition-delay: .8s; }
  .chart-data-s1 li:nth-child(3) { top: 65px; margin-left: 65px; transition-delay: .8s; }
  .chart-data-s1 li:nth-child(4) { top: 173px; margin-right: 172px; transition-delay: 1s; }
  .chart-data-s1 li:nth-child(5) { top: 173px; margin-left: 172px; transition-delay: 1s; }
  .chart-data-s1 li:nth-child(6) { top: 281px; margin-right: 278px; transition-delay: 1.2s; }
  .chart-data-s1 li:nth-child(7) { top: 281px; margin-left: 278px; transition-delay: 1.2s; }
  .animate.show .chart-data-s1 li { opacity: 1; transform: translateX(0) translateY(0); }
  .chart-data-s1 .chart-p { order: 0; }
  .chart-data-s1 .chart-l { text-align: center; font-weight: 400; }
  .chart-canvas { min-height: 300px; }
  .chart-canvas-s1 { min-height: 215px; } }

.ath-container { width: 440px; max-width: 100%; margin: 30px auto; }

.ath-header { padding: 0 0 30px; }

.ath-heading { text-align: center; font-weight: 300; }

.ath-heading small { display: block; padding-bottom: 10px; }

.ath-body { background: #fff; border-radius: 6px; padding: 18px 25px 25px; }

.ath-note { padding: 25px 0 0; }

@media (min-width: 576px) { .ath-body { padding: 32px 40px 40px; } }

.sap-text { position: relative; text-align: center; padding: 20px 0; }

.sap-text:before { position: absolute; top: 50%; left: 0; width: 100%; height: 1px; content: ''; background: #dbe4f7; z-index: 3; }

.sap-text span { position: relative; background: #fff; display: inline-block; padding: 0 15px; z-index: 5; font-size: .9rem; text-transform: uppercase; }

.bg-theme > .sap-text span { background: #3c1c94; }

.bg-theme-alt > .sap-text span { background: #6e19c2; }

.blog { background: #fff; border-radius: 10px; box-shadow: 0 5px 45px 0 rgba(91, 83, 162, 0.08); margin-bottom: 30px; }

.tc-light .blog { background: #0d0e25; }

.blog-photo { padding: 30px 30px 0; }

.blog-photo > img { border-radius: 6px 6px 0 0; }

.blog-s2 .blog-photo { padding: 0; }

.blog-text { padding: 25px 30px 30px; }

.blog-text p { font-size: 0.88rem; }

.blog-s2 .blog-title { margin-bottom: 8px; }

.blog-content-inline { padding: 18px 30px 14px; display: flex; align-items: center; flex-wrap: wrap; justify-content: space-between; }

.blog-content .blog-text { padding: 9px 0; }

.blog-meta { margin-bottom: .25rem; margin: 0 -7px 4px; }

.blog-meta li { position: relative; display: inline-block; font-size: .88rem; padding: 0 7px; }

.blog-meta li:not(:last-child):after { content: '|'; position: absolute; right: -3px; top: -1px; color: #415076; }

.tc-light .blog-meta li:not(:last-child):after { color: #ffffff91; }

.blog-meta li a { color: #6e80af; }

.blog-meta li a:hover { color: #866bfe; }

.blog-tags { padding: 12px 0; margin: -7px -5px; }

.blog-tags li { display: inline-block; padding: 7px 5px; }

.blog-tags li a { padding: 7px 16px; border: 1px solid #dbe4f7; border-radius: 3px; color: #5b53a2; font-size: .88rem; }

.blog-tags li a:hover { color: #fff; border-color: #866bfe; background: #866bfe; }

.blog-featured { margin-bottom: 30px; }

.blog-comment { padding-top: 25px; }

.blog-action { padding: 9px 0; }

.comment-wrap { background: #f7f8ff; border-radius: 6px; overflow: hidden; margin-bottom: 30px; }

.comment-item { padding: 20px; }

.comment-item:not(:last-child) { border-bottom: 1px solid #fff; }

.comment-item + .comment-item { padding-left: 40px; }

.comment-item + .comment-item + .comment-item { padding-left: 60px; }

.comment-photo { width: 50px; flex-shrink: 0; margin-bottom: 10px; margin-right: 20px; }

.comment-content { padding-top: 6px; }

.comment-info .title { margin-bottom: 0; }

.comment-time { font-size: .75rem; }

.comment-text { margin-top: 8px; }

.comment-text p { font-size: .88rem; }

@media (min-width: 576px) { .comment-wrap { margin-bottom: 30px; }
  .comment-item { display: flex; padding: 30px; }
  .comment-item + .comment-item { padding-left: 60px; }
  .comment-item + .comment-item + .comment-item { padding-left: 90px; }
  .comment-photo { margin-bottom: 0; } }

.pagination { margin-left: auto; margin-right: auto; display: inline-flex; justify-content: center; box-shadow: 0 5px 45px 0 rgba(91, 83, 162, 0.08); }

.pagination li a { display: inline-block; height: 50px; min-width: 56px; text-align: center; line-height: 20px; padding: 15px 5px; background: #fff; color: rgba(65, 80, 118, 0.7); }

.pagination li a .icon { line-height: 20px; padding: 0 5px; }

.pagination li a.active, .pagination li a:hover { background: #866bfe; color: #fff; }

.pagination li a.active .icon, .pagination li a:hover .icon { color: #fff; }

.pagination li:first-child a { border-radius: 6px 0 0 6px; }

.pagination li:last-child a { border-radius: 0 6px 6px 0; }

.download-option { text-align: center; position: relative; padding: 10px; margin: 15px 0; }

.download-option .icon { display: block; padding-bottom: 35px; font-size: 44px; color: #415076; }

@media (min-width: 992px) { .download-option:not(:last-child):after { position: absolute; content: ''; right: 0; top: 50%; transform: translateY(-50%); height: 100%; width: 1px; background: rgba(162, 179, 205, 0.3); } }

.doc { text-align: center; position: relative; }

.doc:hover { z-index: 9; }

.doc-s2 { display: flex; margin-left: 20px; margin-bottom: 20px; border-radius: 6px; text-align: left; }

.doc-photo { max-width: 125px; margin-left: auto; margin-right: auto; transition: all .2s linear; }

.doc-photo img { border-radius: 4px; }

.doc:hover .doc-photo { transform: translateY(-10px); }

.doc:hover .doc-photo.no-hover { transform: translateY(0); }

.doc-s2 .doc-photo { max-width: 100px; margin-top: 20px; margin-left: -20px; margin-bottom: -20px; margin-right: 15px; }

.doc-shape { padding-top: 60px; position: relative; }

.doc-shape:before { position: absolute; top: 0; left: 50%; margin-left: -5px; transform: translateX(-50%); transform-origin: 50% 50%; content: ''; background: url(../images/polygon-a-dark.png) 50% 0/contain no-repeat; width: 215px; height: 205px; z-index: -1; transition: all .2s linear; }

.tc-light .doc-shape:before { background-image: url(../images/polygon-a-light.png); }

.doc-shape-a:before { transform: translateX(-50%) rotate(0deg); }

.doc:hover .doc-shape-a:before { transform: translateX(-50%) translateY(10px) rotate(0deg) scale(1.1); }

.doc-shape-b:before { transform: translateX(-50%) rotate(90deg); }

.doc:hover .doc-shape-b:before { transform: translateX(-50%) translateY(10px) rotate(90deg) scale(1.1); }

.doc-shape-c:before { transform: translateX(-50%) rotate(-90deg); }

.doc:hover .doc-shape-c:before { transform: translateX(-50%) translateY(10px) rotate(-90deg) scale(1.1); }

.doc-shape-d:before { transform: translateX(-50%) rotate(135deg); }

.doc:hover .doc-shape-d:before { transform: translateX(-50%) translateY(10px) rotate(135deg) scale(1.1); }

.doc-title { margin-top: 15px; padding-bottom: 8px; }

.doc-title small { display: block; }

.tc-light .doc-title small { color: #fff; }

.doc-s2 .doc-title { margin-top: 25px; font-weight: 600; }

.doc-download { font-size: 18px; line-height: 19px; padding: 8px 15px; min-width: 60px; border-radius: 4px; color: #fff; background: #866bfe; display: inline-block; }

.doc-download:hover { color: #fff !important; background: #2bc9b4; }

.doc-lang { text-transform: uppercase; font-size: 11px; margin-top: 5px; }

.tc-light a.doc-download { color: #fff !important; }

.tc-light a.doc-download:hover { color: #866bfe !important; background: #fff; }

@media (min-width: 1200px) { .doc-shape:before { width: 255px; height: 245px; }
  .doc-photo { max-width: 175px; } }

.particles-container { position: absolute !important; top: 0; left: 0; right: 0; bottom: 0; z-index: 0 !important; }

.particles-container.is-fixed { position: fixed !important; z-index: -1 !important; }

.product-box { position: relative; display: block; background: #3c1c94; border-radius: 4px; padding: 25px 35px 0; max-width: 360px; margin-left: auto; margin-right: auto; transition: all .4s; z-index: 6; }

.tc-light .product-box { background: #fff; }

.product-box:hover { transform: translateY(-8px); }

.product-name { color: #fff; font-weight: 500; text-align: center; padding-bottom: 5px; margin-bottom: 18px; }

.tc-light .product-name { color: #2bc9b4; }

.product-image { border: 2px solid #866bfe; border-bottom: 0; border-radius: 4px 4px 0 0; }

.tc-light .product-image { border-color: #2bc9b4; }

.qr-code { background: #fff; border-radius: 4px; width: 40px; height: 40px; text-align: center; box-shadow: 0px 5px 15px rgba(110, 25, 194, 0.14); padding: 0px !important; cursor: pointer; position: relative; margin: 0px 10px; display: none; line-height: 38.5px; }

.qr-code > img { width: 22px; }

@media (min-width: 576px) { .qr-code { display: block; }
  .qr-code:hover .qr-list { opacity: 1; visibility: visible; }
  .qr-list { position: absolute; width: 264px; display: flex; background: #fff; padding: 18px; justify-content: space-between; bottom: calc(100% + 15px); right: 0; box-shadow: 0px 5px 30px rgba(110, 25, 194, 0.3); border-radius: 5px; opacity: 0; transition: 0.3s; visibility: hidden; }
  .has-rtl .qr-list { right: auto; left: 0; }
  .qr-list:after { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); content: ""; width: 2px; height: 30px; background: #725bd6; }
  .qr-list:before { position: absolute; content: ""; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 14px solid #fff; right: 10px; z-index: 1; bottom: -13px; }
  .has-rtl .qr-list:before { right: auto; left: 10px; }
  .qr-list img { padding: 0 6px; } }

.bg-dark-opacity.card { height: auto; }

.testimonial { display: block; box-shadow: 0px 5px 40px rgba(110, 25, 194, 0.08); }

.tesm-image { min-width: 210px; max-width: 210px; margin: 0 auto; box-shadow: 0px 5px 10px rgba(110, 25, 194, 0.08); }

.tesm-image img { border-radius: 5px; }

.tesm-content { padding-left: 0; padding-right: 0; text-align: center; padding-top: 30px; position: relative; }

.tesm-content:after { font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f10e"; font-size: 46px; color: rgba(110, 25, 194, 0.07); position: absolute; bottom: 0; right: 0; line-height: normal; }

.has-rtl .tesm-content:after { right: auto; left: 0; transform: rotateY(-180deg); }

.tesm-content .title-sm { font-size: 1.1rem; font-weight: 500; color: #415076; opacity: 0.6; line-height: 30px; }

.tesm-content span { display: block; font-size: 0.9rem; font-style: italic; font-weight: 300; line-height: normal; }

.testimonial-carousel .owl-nav { top: 50%; opacity: 1; position: absolute; transform: translateY(-50%); padding-top: 0; margin: 0; width: calc(100% + 60px); left: -30px; }

.testimonial-carousel .owl-nav button { border-width: 0 !important; margin: 0; color: #415076; opacity: 0.5; transition: 0.3s; }

.has-rtl .testimonial-carousel .owl-nav button:after { transform: rotateY(-180deg); }

.testimonial-carousel .owl-nav button:hover { opacity: 1; }

.testimonial-carousel .owl-nav button:after { font-family: "Font Awesome 5 Free"; font-weight: 700; }

.testimonial-carousel .owl-prev:after { content: '\f060'; }

.testimonial-carousel .owl-next { position: absolute; right: 0; }

.has-rtl .testimonial-carousel .owl-next { right: auto; left: 0; }

.testimonial-carousel .owl-next:after { content: '\f061'; }

@media (min-width: 576px) { .testimonial { display: flex; flex-direction: row; align-items: center; }
  .tesm-content { padding-left: 40px; padding-right: 15px; text-align: left; padding-top: 0; }
  .has-rtl .tesm-content { text-align: right; padding-left: 15px; padding-right: 40px; }
  .tesm-image { margin: -45px 0; }
  .testimonial-carousel .owl-stage-outer { padding: 30px 0; } }

@media (min-width: 768px) { .testimonial-carousel .owl-nav { width: calc(100% + 80px); left: -40px; } }

@media (min-width: 992px) { .mlm-30 { margin-left: -30px; }
  .testimonial-carousel .owl-nav { width: calc(100% + 160px); left: -80px; } }

/** 04.14 Others */
.payment-holder { position: relative; }

.payment-holder-list { max-width: 1200px; margin: auto; display: flex; flex-flow: wrap; justify-content: space-between; }

.payment-holder-thumb { width: 58px; height: 58px; box-shadow: 0px 0px 0px 3px #725bd6; position: relative; border-radius: 50%; margin-bottom: 20px; }

.payment-holder-thumb-s2 { box-shadow: none; border-radius: 0; height: auto; width: auto; }

.payment-holder-thumb img { width: 100%; border-radius: 50%; }

.payment-holder-info p { font-size: 11px; font-weight: 500; }

.payment-holder-single { display: flex; margin-bottom: 30px; align-items: center; width: 100%; align-items: center; flex-wrap: wrap; flex-direction: column-reverse; }

.payment-info-card { padding: 14px 20px; background: #fff; box-shadow: 0px 5px 20px rgba(114, 91, 214, 0.25); border-radius: 4px; width: 100%; text-align: center; }

.title-pcard { font-size: 1rem; margin-bottom: 0 !important; font-weight: 400; }

@media (min-width: 576px) { .payment-holder-single { width: 48%; } }

@media (min-width: 992px) { .payment-holder-info { margin-bottom: 14px; position: relative; }
  .payment-holder-info:after { position: absolute; content: ""; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 14px solid #fff; top: 99%; z-index: 1; }
  .payment-holder-thumb { border-radius: 50%; }
  .payment-holder-thumb img { border-radius: 50%; }
  .payment-holder-single { display: block; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: auto; height: auto; flex-direction: column; }
  .payment-holder-single:nth-child(1) { margin-left: -23%; margin-top: -10%; }
  .payment-holder-single:nth-child(2) { margin-left: -14%; margin-top: 3%; }
  .payment-holder-single:nth-child(3) { margin-left: 10%; margin-top: 0; }
  .payment-holder-single:nth-child(4) { margin-left: 31%; margin-top: -9%; }
  .payment-holder-thumb:after, .payment-holder-thumb:before { position: absolute; content: ""; left: 50%; transform: translateX(-50%); }
  .payment-holder-thumb:after { width: 0; height: 0; border-left: 20px solid transparent; border-right: 20px solid transparent; border-top: 40px solid #fff; bottom: -30px; z-index: -1; filter: drop-shadow(0px 6px 0px #725bd6); }
  .payment-holder-thumb:before { width: 30px; height: 15px; border-radius: 50%; background: rgba(114, 91, 214, 0.4); bottom: -41px; z-index: -1; }
  .payment-holder-thumb-s2 { border-radius: 0px; }
  .payment-holder-thumb-s2:before, .payment-holder-thumb-s2:after { display: none; }
  .payment-holder-thumb-s2 img { border-radius: 0; }
  .payment-info-card { width: auto; text-align: left; }
  .has-rtl .payment-info-card { text-align: right; } }

/** 04.14 datatable */
.table-wrap { width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; }

.dataTables_filter label { width: 100%; height: 50px; border: 2px solid #d1e0f6; background: transparent; border-radius: 5px; padding: 13px 20px 10px; display: flex; align-items: center; margin-bottom: 30px; }

.dataTables_filter input { border: none; width: 97% !important; }

.dataTables_filter input:focus { outline: none; }

.dataTable { width: 100%; }

.data-table-area { position: relative; }

.data-item { box-shadow: 0px 3px 15px rgba(46, 24, 106, 0.06); background: #fff; }

.data-col { padding: 10px 20px; font-size: 14px; font-weight: 400; color: #fff; }

.data-col-three { border-left: 1px solid; border-bottom: 1px solid; border-right: 1px solid; }

.data-col-four { border-bottom: 1px solid; }

.data-col-five { border-left: 1px solid; }

.data-col-six { border-right: 1px solid; }

.data-col:before, .data-col:after { display: none !important; }

.data-col-inner { padding: 20px; }

.data-item:not(:last-child) .data-col-inner { border-bottom: 5px solid #eaeff6; }

.data-col-inner.alphabetical { min-width: 186px; }

.dt-short { position: absolute; right: 0; top: 0; z-index: 9; }

.dt-short label { width: 86px; margin-bottom: 0; }

.has-rtl .dt-short { right: auto; left: 0; }

@media (max-width: 409px) { .dt-short { position: static !important; margin-bottom: 20px; }
  .dataTables_filter label { margin-bottom: 0; } }

/** 05. Layouts */
/** 05.01 Header */
.header-main { padding: 12px 0; transition: all .4s; }

.is-transparent .header-main, .has-fixed .header-main { width: 100%; z-index: 49; }

.is-transparent .header-main { position: absolute !important; }

.has-fixed .header-main { position: fixed !important; box-shadow: 0 0 20px 0 rgba(91, 83, 162, 0.08); }

.has-fixed:not(.is-dark) .header-main {     background: #000; }

.has-fixed.is-dark .header-main { background: #0d0427; }

/* .has-fixed.is-shrink .header-main { padding: 8px 0; } */
.has-fixed.is-shrink .header-main {
  padding: 10px 0 10px;
}

.header-container:not(.container) { width: 100%; padding: 0 15px; }

.header-wrap { position: relative; width: 100%; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }

.header-logo { margin: 6px 0; flex-shrink: 0; }

.header-logo-ls { display: flex; align-items: center; }

.header-navbar { position: fixed; left: -280px; top: 0; width: 260px; height: 100vh; }

.header-navbar.menu-mobile { transition: all 0.4s; }

.header-navbar::before, .header-navbar-overlay { position: absolute; height: 100%; width: 100%; content: ''; top: 0; }

.header-navbar::before { background: #fff; left: 50%; transform: translateX(-50%); transition: all .4s; }

.is-dark .header-navbar::before { background: #7b1cd9; }

.header-navbar-overlay { background: rgba(11, 12, 16, 0.7); left: 100%; width: 0; z-index: -1; opacity: 0; visibility: hidden; transition: opacity .3s; transition-delay: .2s; }

.header-navbar.menu-shown { left: 0 !important; z-index: 10; }

.header-navbar.menu-shown .header-navbar-overlay { width: 100vw; opacity: 1; visibility: visible; }

.header-navbar-classic { position: absolute; top: 99%; right: 0; left: 0; width: 100%; height: auto; background: transparent; margin-top: 12px; z-index: auto; flex-grow: 1; transition: all 0.4s; padding: 0 20px; }

.has-fixed .header-navbar-classic { margin-top: -6px; padding: 0; }

.header-navbar-classic:before { border-radius: 4px; }

.has-fixed .header-navbar-classic:before { width: 100vw; border-radius: 0; }

.header-navbar-s1 { flex-grow: 1; }

.header-menu { justify-content: flex-end; padding: 30px 25px; }

.header-navbar-classic .header-menu { padding: 0; }

.header-banner { min-height: 72px; }

.overlay-menu-shown { overflow: hidden; }

.overlay-menu-shown .has-fixed > .header-main { z-index: 16; }

@media (min-width: 375px) { .header-navbar:not(.header-navbar-classic) { left: -310px; width: 290px; } }

@media (min-width: 576px) { .header-navbar:not(.header-navbar-classic) { left: -360px; width: 340px; } }

.logo-dark, .is-transparent:not(.has-fixed) .logo-light, .is-dark .logo-light { display: block; }

.logo-light, .is-transparent:not(.has-fixed) .logo-dark, .is-dark .logo-dark { display: none; }

.logo img { height: 36px; transition: all .4s; }

.logo-md img { height: 44px; }

.logo-lg img { height: 52px; }

@media (min-width: 992px) { .header-navbar:not(.header-navbar-classic) { height: auto; width: auto; left: 0; }
  .header-main { padding: 20px 0; }
  .header-nav-toggle { display: none; }
  .header-container:not(.container) { padding: 0 25px; }
  .header-container-s1:not(.container) { padding: 0 25px; }
  .header-banner { min-height: 98px; }
  .logo img { height: 50px;
    width: 142px;
    object-fit: contain; }
  .logo-md img { height: 50px; }
  .logo-lg img { height: 60px; } }

@media (min-width: 1200px) { .header-container:not(.container) { padding: 0 25px; }
  .header-container-s1:not(.container) { padding: 0 35px; } }

.header-menu { max-height: 100vh; overflow: hidden; overflow-y: auto; height: 100%; }

.header-navbar-classic .header-menu { display: none; max-height: 65vh; }

.header-navbar-classic .header-menu.menu-shown { display: block; }

.header-nav-toggle { height: 44px; }

.language-switcher .toggle-tigger, .header-menu .btn-outline:not(:hover) { color: #415076; }

.is-light .language-switcher .toggle-tigger, .language-switcher .toggle-tigger .is-transparent:not(.is-dark), .is-light .header-menu .btn-outline:not(:hover), .header-menu .btn-outline:not(:hover) .is-transparent:not(.is-dark) { color: #415076; }

.tc-light .language-switcher .toggle-tigger, .tc-light .btn-outline:not(:hover), .is-dark .language-switcher .toggle-tigger, .is-dark .btn-outline:not(:hover) { color: #fff; }

@media (min-width: 992px) { .tc-light .header-menu .language-switcher .toggle-tigger, .tc-light .header-menu .btn-outline:not(:hover), .is-transparent .header-menu .language-switcher .toggle-tigger, .is-transparent .header-menu .btn-outline:not(:hover), .is-transparent:not(.has-fixed):not(.is-light) .header-menu .language-switcher .toggle-tigger, .is-transparent:not(.has-fixed):not(.is-light) .header-menu .btn-outline:not(:hover), .is-dark .header-menu .language-switcher .toggle-tigger, .is-dark .header-menu .btn-outline:not(:hover) { color: #fff; }
  .is-split:not(.has-fixed):not(.is-light) .header-menu .btn-outline:not(:hover) { color: #fff; }
  .has-fixed:not(.is-dark) .header-menu .language-switcher .toggle-tigger:not(.btn), .has-fixed:not(.is-dark) .header-menu .btn-outline:not(:hover), .is-light .header-menu .language-switcher .toggle-tigger:not(.btn), .is-light .header-menu .btn-outline:not(:hover) { color: #fff; }
  .is-transparent:not(.has-fixed):not(.is-light) .header-menu .btn-outline:not(:hover):not(:focus):not(.no-change) { border-color: rgba(255, 255, 255, 0.4); }
  .is-transparent:not(.has-fixed):not(.is-light) .header-menu .btn:not(:hover):not(:focus):not(.no-change):not(.btn-outline) { border-color: #fff; background: #fff; color: #415076 !important; }
  .is-transparent:not(.has-fixed):not(.is-light) .header-menu .btn-outline:not([class*="btn-grad"]):not(.no-change):hover, .is-transparent:not(.has-fixed):not(.is-light) .header-menu .btn-outline:not([class*="btn-grad"]):not(.no-change):focus { color: #866bfe !important; border-color: #fff !important; background-color: #fff !important; } }

@media (max-width: 991px) { .header-menu .language-switcher-s1, .header-menu .language-switcher-s4 { width: 100%; }
  .header-menu .language-switcher .toggle-tigger:not(.btn) { padding-left: 0; }
  .has-rtl .header-menu .language-switcher .toggle-tigger:not(.btn) { padding-left: 15px; padding-right: 0; }
  .header-menu .btn { line-height: 24px; padding: 6px 14px; min-width: 100px; }
  .header-menu .btn-transparent { padding: 6px 0; } }

/** 05.02 Menu */
.menu { padding: 12px 0; }

.menu-btns { margin-bottom: 26px; margin-left: -10px; margin-right: -10px; margin-top: -10px; display: flex; flex-wrap: wrap; }

.menu-btns > li { padding: 10px; padding: 10px; }

.menu-item { position: relative; border-bottom: 1px solid rgba(219, 228, 247, 0.75); }

.is-dark .menu-item { border-color: rgba(219, 228, 247, 0.1); }

.menu-item:last-child { border-bottom: 0; }

.menu-item > ul, .menu-item .menu-sub { display: none; }

.menu-item a { font-family: "Roboto", sans-serif; font-size: 13px; line-height: 15.6px; color: #fff; padding: 12px 0; display: block; position: relative; }

.header-menu-s2 .menu-item a { font-size: 12px; }

.menu-item a .badge { margin-left: 5px; position: relative; top: -1px; }

.menu-item a .badge-dot { top: 0; }

.menu-item a .active, .menu-item a:hover { color: #fff;}

.is-dark .menu-item a .active, .is-dark .menu-item a:hover { color: #866bfe; }

.menu-s2 > .menu-item > a { font-size: 11px; text-transform: uppercase; font-weight: 500; }

.menu-sub { padding-left: 12px; }

.menu-sub .menu-item:first-child { border-top: 1px solid rgba(219, 228, 247, 0.75); }

.is-dark .menu-sub .menu-item:first-child { border-color: rgba(219, 228, 247, 0.1); }

.menu-sub .menu-sub { margin-top: 0; margin-bottom: 15px; }

.menu-sub .menu-sub .menu-item { border-bottom: none; }

.menu-sub .menu-sub .menu-item:first-child { border-top: none; }

.menu-sub .menu-sub .menu-item a { padding-top: 6px; padding-bottom: 6px; }

.menu-sub a { font-family: "Roboto", sans-serif; font-size: 12px; color: #415076; }

.menu-mega a { font-family: "Roboto", sans-serif; font-size: 12px; color: #415076; }

.is-dark .menu-item a { color: #fff; }

.menu-item > a.active { color: #866bfe; }

.has-sub > a { position: relative; }

.has-sub > a:after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); content: '\e64b'; font-family: 'themify'; font-size: 8px; font-weight: 700; transition: transform .4s; }

.has-sub.open-nav > a:after { transform: translateY(-50%) rotate(-180deg); }

.navbar-toggle { width: 44px; height: 44px; text-align: center; border-radius: 50%; padding: 5px; display: inline-flex; align-items: center; justify-content: center; transition: all 0.3s; margin-right: -10px; }

.navbar-toggle.navbar-active { color: #866bfe; position: relative; z-index: 49; }

.navbar-toggle:hover, .navbar-toggle.navbar-active { background: rgba(247, 248, 255, 0.2); }

.is-transparent:not(.has-fixed) .navbar-toggle { color: #fff; }

.is-transparent:not(.has-fixed) .navbar-toggle:hover, .is-transparent:not(.has-fixed) .navbar-toggle.navbar-active { background: rgba(247, 248, 255, 0.2); }

@media (min-width: 360px) { .navbar-toggle { margin-right: 0; } }

.toggle-line { position: relative; width: 32px; height: 32px; display: block; color: #415076; }

.toggle-line:hover { color: #415076; }

.is-transparent:not(.has-fixed):not(.is-light) .toggle-line { color: #fff; }

.is-dark.has-fixed .toggle-line { color: #fff; }

.toggle-line::after, .toggle-line::before, .toggle-line > span { content: ""; position: absolute; background: currentColor; transition: all 0.2s; width: 24px; height: 2px; left: 4px; border-radius: 3px; }

.toggle-line::before { top: 8px; }

.toggle-line::after { bottom: 8px; }

.toggle-line > span { top: 50%; margin-top: -1px; width: 20px; }

.navbar-active .toggle-line { color: #fff; }

.navbar-active .toggle-line::before, .navbar-active .toggle-line::after { width: 28px; left: 2px; }

.navbar-active .toggle-line::before { transform: rotate(45deg); top: 15px; }

.navbar-active .toggle-line::after { transform: rotate(-45deg); bottom: 15px; }

.navbar-active .toggle-line > span { opacity: 0; visibility: hidden; }

.secondary-navbar { box-shadow: 0px 5px 40px rgba(65, 80, 118, 0.1); padding: 15px 0; }

.secondary-menu-list { display: flex; align-items: center; justify-content: center; padding: 0; flex-wrap: wrap; }

.secondary-menu-item { width: 50%; }

.secondary-menu-link { padding: 5px 16px; display: inline-block; color: #415076; font-weight: 400; position: relative; }

.secondary-menu-link:after { position: absolute; content: ""; left: 50%; bottom: 0; width: 0; height: 3px; background: #2bc9b4; transform: translateX(-50%); transition: 0.3s; transform-origin: left; border-radius: 50%; }

.secondary-menu-link:hover { color: currentColor; }

.secondary-menu-link:hover:after { width: 3px; }

.secondary-menu-item.active .secondary-menu-link { color: currentColor; }

.secondary-menu-item.active .secondary-menu-link:after { width: 3px; }

@media (min-width: 425px) { .secondary-menu-item { width: auto; }
  .secondary-menu-link { padding: 20px 16px; width: auto; }
  .secondary-navbar { padding: 0; display: block; }
  .secondary-menu-list { flex-wrap: nowrap; }
  .secondary-menu-link:after { border-radius: 0; }
  .secondary-menu-link:hover { color: currentColor; }
  .secondary-menu-link:hover:after { width: 25px; }
  .secondary-menu-item.active .secondary-menu-link { color: currentColor; }
  .secondary-menu-item.active .secondary-menu-link:after { width: 25px; } }

@media (min-width: 576px) { .secondary-menu-item { width: auto; }
  .secondary-menu-link { padding: 20px 28px; width: auto; } }

@media (min-width: 992px) { .header-menu { display: flex !important; width: auto; align-items: center; position: static; background: transparent; padding: 0; margin: 0; border-radius: 0; overflow: visible; }
  .header-navbar { padding: 0 0 0 15px; margin-top: 0; position: relative; }
  .header-navbar:before { display: none; }
  .has-fixed .header-navbar { margin-top: 0; }
  .menu { display: flex; align-items: center; padding: 0; justify-content: center;}
  .menu-item { border-bottom: none; }
  .menu-item > a { font-weight: 400; padding: 20px 10px; }
  .menu-drop .menu-item > a { font-weight: 500; }
  .menu-item:hover { z-index: 9999; text-transform: uppercase;
    font-size: 12px;}
  .menu-sub { position: absolute; min-width: 215px; max-width: 215px; background: #fff; padding: 15px 10px; top: 100%; left: 0; display: block !important; z-index: 99; opacity: 0; visibility: hidden; transition: all .4s; border-radius: 4px; transform: translateX(0) translateY(-8px); box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.08); }
  .menu-sub a { width: 100%; display: block; padding: 10px 10px; }
  .menu-sub .menu-item:first-child { border-top: none; }
  .menu-sub .menu-item:hover > a { color: #866bfe; }
  .menu-item:hover > .menu-sub { opacity: 1; visibility: visible; transform: translateX(0) translateY(0); }
  .menu-sub .menu-sub { left: calc(100% - 25px); top: 15px; margin: 0; }
  .menu-sub .menu-sub .menu-item a { padding-top: 8px; padding-bottom: 8px; }
  .is-dark .menu-sub { background-color: #801de2; }
  .menu-mega { position: absolute; left: 50%; background: #fff; padding: 15px 10px; top: 100%; display: block !important; z-index: 99; opacity: 0; visibility: hidden; transition: all .4s; border-radius: 4px; box-shadow: 0 3px 12px 0 rgba(91, 83, 162, 0.08); transform: translateX(-50%) translateY(-8px); }
  .menu-item:hover > .menu-mega { opacity: 1; visibility: visible; transform: translateX(-50%) translateY(0); }
  .menu-mega .menu-item a { padding-top: 8px; padding-bottom: 8px; }
  .menu-mega-innr { display: flex; }
  .menu-mega-innr > div, .menu-mega-innr ul { flex-grow: 1; }
  .menu-mega-2clmn .menu-mega-innr > div, .menu-mega-2clmn .menu-mega-innr ul { width: 50%; }
  .menu-mega-3clmn .menu-mega-innr > div, .menu-mega-3clmn .menu-mega-innr ul { width: auto; }
  .menu-mega-2clmn { min-width: 370px; max-width: 370px; }
  .menu-mega-3clmn { min-width: 520px; max-width: 520px; }
  .is-dark .menu-mega { background-color: #801de2; }
  .menu-btns { display: flex; margin-bottom: -10px; margin-left: 10px; }
  .menu-btns-s3 { margin-left: 0; }
  .has-sub > a { padding-right: 24px !important; position: relative; }
  .has-sub > a:after { right: 8px; }
  .menu-sub .has-sub > a { padding-right: 35px; }
  .menu-sub .has-sub > a:after { transform: translateY(-50%) rotate(-90deg); right: 15px; }
  .has-sub.menu-item:last-child .menu-sub:not(.menu-mega) .menu-item > a { padding-right: 20px !important; padding-left: 15px; }
  .has-sub.menu-item:last-child .menu-sub:not(.menu-mega) .menu-item > a:after { transform: translateY(-50%) rotate(90deg); left: 15px; right: auto; }
  .has-sub.menu-item:last-child .menu-mega { left: auto; right: 0; transform: translateX(0) translateY(-8px); }
  .has-sub.menu-item:last-child:hover .menu-mega { transform: translateX(0) translateY(0); }
  .is-transparent:not(.has-fixed):not(.is-light) .menu > .menu-item > a { color: #fff; }
  .is-transparent:not(.has-fixed):not(.is-light) .menu > .menu-item:hover > a { color: #866bfe; }
  .secondary-navbar { padding: 0; display: block; }
  .secondary-menu-link { padding: 20px 38px; } }

@media (min-width: 992px) and (max-width: 1199px) { .logo img { height: 32px; }
  .header-navbar-s1 .menu > .menu-item > a { padding: 20px 4px; font-size: 12px; font-weight: 500; }
  .menu-btns > li { padding: 6px; }
  .menu-btns .btn { font-size: 13px; line-height: 20px; padding: 10px 15px; min-width: 0; }
  .menu-btns .btn.btn-grad { padding: 12px 15px; }
  .menu-btns .btn-md { font-size: 11px; line-height: 19px; padding: 8px 16px; }
  .menu-btns .btn-md.btn-grad { padding: 10px 16px; } }

@media (min-width: 1200px) { .menu > .menu-item > a { padding: 20px 15px; text-transform:uppercase;}
  .header-navbar-s1 .menu > .menu-item > a { padding: 20px 8.5px; font-size: 12px; font-weight: 500;  }
  .menu-s2 > .menu-item > a { padding-left: 7.5px; padding-right: 7.5px; }
  .menu-item.has-sub > a { padding-right: 23px !important; }
  .menu-item.has-sub > a:after { right: 8px; }
  .menu-sub a { padding: 10px 15px;  text-transform: uppercase;
    font-size: 13px;}
  .secondary-menu-link { padding: 20px 45px; } }

@media (min-width: 1600px) { .header-navbar-s1 .menu > .menu-item > a { font-size: 14px; }
  .header-menu-s2 .menu > .menu-item > a { padding: 20px 30px; } }

/** 05.03 Sections */
.nk-body { min-width: 320px; }

.nk-pages-centered { display: flex; flex-direction: column; justify-content: center; min-height: 100vh; padding: 0 15px; }

@media (min-width: 1600px) { .body-boxed .nk-wrap { max-width: 1400px; } }

.container-md { max-width: 960px; }

.container-sm { max-width: 720px; }

.container-xs { max-width: 520px; }

.container-z { z-index: 5; position: relative; }

.section { position: relative; overflow-x: hidden; width: 100%; z-index: 2; }

.section-sm { padding-top: 40px !important; padding-bottom: 40px !important; }

.section-connect { overflow: visible; }

.section-connect:before { position: absolute; top: 0; left: 50%; content: ""; height: 80px; width: 2px; transform: translate(-50%, -50%); background-color: #2b55f5;}

.ov-v + .section { z-index: inherit; }

.section-t-s-fixed, .section-s-fixed { padding-top: 60px !important; }

.section-b-s-fixed, .section-s-fixed { padding-bottom: 60px !important; }

.section-t-m-fixed, .section-m-fixed { padding-top: 90px !important; }

.section-b-m-fixed, .section-m-fixed { padding-bottom: 90px !important; }

.section-t-l-fixed, .section-l-fixed { padding-top: 120px !important; }

.section-b-l-fixed, .section-l-fixed { padding-bottom: 120px !important; }

.section-t-x-fixed, .section-x-fixed { padding-top: 180px !important; }

.section-b-x-fixed, .section-x-fixed { padding-bottom: 180px !important; }

.section-t, .section { padding-top: 60px; }

.section-b, .section { padding-bottom: 60px; }

.section-t-xl { padding-top: 150px !important; }

@media (min-width: 576px) { .section-connect:before { height: 100px; }
  .section-t, .section { padding-top: 60px; }
  .section-b, .section { padding-bottom: 60px; }
  .section-t-s, .section-s { padding-top: 60px !important; }
  .section-b-s, .section-s { padding-bottom: 60px !important; } }

@media (min-width: 768px) { .section-connect:before { height: 120px; }
  .section-t, .section { padding-top: 90px; }
  .section-b, .section { padding-bottom: 90px; }
  .section-t-m, .section-m { padding-top: 90px !important; }
  .section-b-m, .section-m { padding-bottom: 90px !important; } }

@media (min-width: 992px) { .section-connect:before { height: 160px; }
  .section-t, .section { padding-top: 120px; }
  .section-b, .section { padding-bottom: 120px; }
  .section-t-l, .section-l { padding-top: 120px !important; }
  .section-b-l, .section-l { padding-bottom: 120px !important; }
  .section-t-xl { padding-top: 180px !important; } }

@media (min-width: 1600px) { .section-t, .section { padding-top: 180px; }
  .section-b, .section { padding-bottom: 180px; }
  .section-t-x, .section-x { padding-top: 180px !important; }
  .section-b-x, .section-x { padding-bottom: 180px !important; } }

@media (max-width: 575px) { .section-mxw-t-0 { padding-top: 0; }
  .section-mxw-b-0 { padding-top: 0; } }

@media (max-width: 767px) { .section-sm-mxw-t-0 { padding-top: 0; }
  .section-sm-mxw-b-0 { padding-top: 0; } }

@media (max-width: 991px) { .section-md-mxw-t-0 { padding-top: 0; }
  .section-md-mxw-b-0 { padding-bottom: 0 !important; } }

@media (max-width: 1199px) { .section-lg-mxw-t-0 { padding-top: 0; }
  .section-lg-mxw-b-0 { padding-bottom: 0; } }

[class*="section"].pt-0, [class*="section"].pdt-0 { padding-top: 0 !important; }

[class*="section"].pb-0, [class*="section"].pdb-0 { padding-bottom: 0 !important; }

.split { height: 100%; }

.split-left { border-radius: 6px 6px 0 0; }

.split-right { border-radius: 0 0 6px 6px; }

@media (min-width: 576px) { .split-sm-left { border-radius: 6px 0 0 6px; }
  .split-sm-right { border-radius: 0 6px 6px 0; } }

@media (min-width: 768px) { .split-md-left { border-radius: 6px 0 0 6px; }
  .split-md-right { border-radius: 0 6px 6px 0; } }

@media (min-width: 992px) { .split-lg { border-radius: 6px; }
  .split-lg-left { border-radius: 6px 0 0 6px; }
  .split-lg-right { border-radius: 0 6px 6px 0; } }

@media (min-width: 1200px) { .split-xl-left { border-radius: 6px 0 0 6px; }
  .split-xl-right { border-radius: 0 6px 6px 0; } }

.nk-pages-angled { margin-top: -450px; }

@media (max-width: 767px) { .container { padding-right: 20px; padding-left: 20px; } }

/** 05.04 Footer */
.nk-footer-angled { margin-top: -450px; }

.footer-links { margin: 0 -12px; }

.footer-links > li { display: inline-block; padding: 0 12px; }

.footer-links > li > a { font-size: 13px; color: #415076; }

.footer-links > li > a:hover { color: #866bfe; }

.tc-light .footer-links > li > a { color: #fff !important; }

.tc-light .footer-links > li > a:hover { color: #866bfe !important; }

/** 06. Override */
.mfp-bg, .mfp-wrap, .mfp-wrap[class*="bg-"], .mfp-bg[class*="bg-"] { position: fixed !important; z-index: 99999999 !important; top: 0 !important; }

.mfp-wrap { overflow: hidden auto !important; }

.mfp-opened { overflow: hidden; }

.mfp-wrap, .mfp-wrap[class*="bg-"] { background: transparent !important; }

/** 07. Preview Purpose */
.icon-preview { text-align: center; padding: 30px; border-radius: 4px; border: 1px solid #dfeaf8; height: 100%; }

.icon-wrap [class*="ikon-"] { font-size: 40px; }

.icon-class { padding-top: 15px; }

.nk-preview { position: relative; padding-bottom: 60px; }

.nk-preview:first-child { padding-top: 60px; }

.nk-preview-content { padding-top: 10px; }

.nk-preview-content > .tab-pane { padding-top: 0; box-shadow: 0 5px 35px -5px rgba(91, 83, 162, 0.12); }

.nk-preview .nk-block-head .nav { margin-bottom: 25px; margin-left: 0; margin-right: 0; }

.nk-preview .nk-block-head .nav a { font-size: .8rem; padding: 10px 5px 5px; }

.nk-preview .nk-block-head .title { color: #415076; font-size: 13px; letter-spacing: 1px; text-transform: uppercase; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; }

.nk-preview .nk-block-head .title .badge { margin-left: 5px; }

.has-rtl .nk-preview .nk-block-head .title .badge { margin-left: 0; margin-right: 5px; }

.nk-preview .nk-block-head .title small { font-size: 12px; text-transform: none; letter-spacing: 0; margin-top: 0.45rem; color: #828998; display: block; }

@media (min-width: 576px) { .nk-preview { padding-bottom: 60px; }
  .nk-preview:first-child { padding-top: 60px; } }

@media (min-width: 768px) { .nk-preview { padding-bottom: 90px; }
  .nk-preview:first-child { padding-top: 90px; } }

@media (min-width: 992px) { .nk-preview { padding-bottom: 120px; }
  .nk-preview:first-child { padding-top: 120px; } }

@media (min-width: 1600px) { .nk-preview { padding-bottom: 180px; }
  .nk-preview:first-child { padding-top: 180px; } }

@media (min-width: 1280px) { .nk-preview { padding-left: 48px; padding-right: 48px; }
  .nk-preview-content > .tab-pane { box-shadow: 0 5px 35px -5px rgba(91, 83, 162, 0.12); } }

@media (min-width: 1200px) and (min-height: 900px) { .nk-preview .banner-fs, .nk-preview .nk-pages-centered { min-height: calc(100vh - 200px); } }

/* END @iO */
/* END @iO */
